import { ConfirmationStatusV3DTO, DataSensitivityDTO } from '@ketch-com/figurehead'

export const chooseSensitivityByHierachy = (sensitivities: DataSensitivityDTO[]) => {
  if (sensitivities.length < 2) return sensitivities
  const hasConfirmation = sensitivities.find(
    sensitivity => sensitivity.confirmationStatus === ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
  )
  if (hasConfirmation) {
    return [hasConfirmation]
  }
  const hasSensitive = sensitivities.find(sensitivity => sensitivity.code === 'sensitive')
  if (hasSensitive) {
    return [hasSensitive]
  }
  const hasPersonal = sensitivities.find(sensitivity => sensitivity.code === 'personal')
  if (hasPersonal) {
    return [hasPersonal]
  }
  return [sensitivities[0]]
}
