import { Box, Link, SxProps, Theme, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export type ConsentModeTcfNoticeProps = {
  sx?: SxProps<Theme>
}

/**
 * Renders a google certification notice if the org is google test org and the tcf protocols are disabled.
 * This is not for regular customer orgs.
 */
export const ConsentModeTcfNotice: React.FC<ConsentModeTcfNoticeProps> = ({ sx }) => {
  const navigate = useNavigate()

  return (
    <Box sx={sx}>
      <Typography variant="label">
        Ketch recommends using our Google-ready banner{' '}
        <Link
          component="button"
          onClick={() =>
            navigate(RoutesManager.deployment.experiencesV2.view.root.getURL({ id: '2sRqe7RdrDVIU7stKlluQe' }))
          }
        >
          template
        </Link>{' '}
        when enabling consent mode without TCF. Click{' '}
        <Link
          href="https://docs.ketch.com/ketch/docs/building-google-compliant-banner"
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </Link>{' '}
        to learn more.
      </Typography>
    </Box>
  )
}
