import { useState, Dispatch, SetStateAction } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { RoutesManager } from 'utils/routing/routesManager'
import { useParams, useNavigate } from 'react-router-dom'
import { getURLParams } from 'utils/routing/routes/utils'
import { Table } from 'components/ui-layouts/table/Table'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { InfiniteScrollLoadingIndicator } from 'components/ui-layouts/table/components/infiniteScrollLoadingIndicator'
import { ReactComponent as SchemasIcon } from 'assets/icons/schemas.svg'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { AssetSummaryDTO, LabelDTO, GetDSRConfigCountResponseBodyDTO } from '@ketch-com/figurehead'
import { Waypoint } from 'react-waypoint'
import { Badge } from 'components/ui-kit/badge/Badge'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { getLabelDTOUniqueIdentifier } from 'pages/assetManager/assets/view/components/AssetDetailViewRelatedAssets/components/DataCategoriesBulkAssignModal/utils/getLabelDTOUniqueIdentifier'
import { Chip } from 'components/ui-kit/chip/Chip'
import { FormMode } from 'interfaces/formModes/formMode'
import { ButtonActions } from 'components/ui-kit/buttonActions/ButtonActions'
import { Button } from '@ketch-com/deck'
import { useDebounce } from 'react-use'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { ReactComponent as SkippedIcon } from 'assets/icons/no.svg'
import { ReactComponent as ConfiguredIcon } from 'assets/icons/green_check.svg'
import pluralize from 'pluralize'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/search.svg'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { getAssetType } from 'pages/assetManager/utils'
import { SQLPreviewModal } from 'pages/assetManager/scenario/components'
import { MaybeNull } from 'interfaces'
import { theme } from '@ketch-com/deck'

const PREFIX = 'DatasetConfigTable'

const classes = {
  chip: `${PREFIX}-chip`,
}

const Root = styled(Box)(({ theme: { spacing, palette } }) => ({
  [`& .${classes.chip}`]: {
    backgroundColor: palette.marine.main,
  },
}))

type Props = {
  assets: AssetSummaryDTO[]
  isLoading: boolean
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  fetchNextPage: () => {}
  setSearchQuery: Dispatch<SetStateAction<string>>
  skipDataset: any
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
}

/**
 * @deprecated
 */
export const NoDirectIdentityLookup: React.FC<Props> = ({
  assets,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  setSearchQuery,
  skipDataset,
  dsrConfigCount,
}) => {
  const [searchString, setSearchString] = useState<string | null>(null)
  const [selectedDatasetForSqlPreview, setSelectedDatasetForSqlPreview] = useState<MaybeNull<AssetSummaryDTO>>(null)
  const navigate = useNavigate()
  const { scenarioId, resourceTypeCode: databaseResourceTypeCode } = useParams<getURLParams>()
  const rightCode = dsrConfigCount?.dsrScenario?.[scenarioId!]?.canonicalRight?.code || ''
  const connectionResourceTypes = assets?.[0]?.asset?.connection?.resourceTypes || []
  const attributeResourceType = connectionResourceTypes?.[connectionResourceTypes.length - 1] || {}
  const attributeResourceTypeCode = attributeResourceType?.code || ''
  const resourceTypeCode = assets?.[0]?.asset?.resource?.resourceType?.code || ''
  const providerCode = assets?.[0]?.asset?.connection?.providerCode || ''
  const databaseType = getAssetType(providerCode, 1)
  const datasetType = getAssetType(providerCode, 2)
  const attributeType = getAssetType(providerCode, 3)

  useDebounce(() => setSearchQuery(searchString ?? ''), 500, [searchString])

  if (!isLoading && !assets?.length && searchString === null)
    return (
      <EmptyState
        variant="page"
        bgVariant="grey"
        icon={<SchemasIcon />}
        title={`No Direct Lookup ${pluralize.plural(datasetType)}`}
        description={`There are no ${pluralize.plural(
          datasetType,
        )} containing personal data and does not support a direct lookup in this ${databaseType.toLowerCase()}.`}
      />
    )

  return (
    <Root>
      <Box>
        <Box marginBottom={2}>
          <SearchInput
            disabled={isLoading}
            size="small"
            onChange={nextSearchString => setSearchString(nextSearchString)}
            onClear={() => setSearchString('')}
            value={searchString ?? ''}
          />
        </Box>

        {isLoading ? <Spinner /> : null}

        {!isLoading && !assets?.length && searchString ? (
          <EmptyState
            variant="page"
            bgVariant="white"
            icon={<EmptyStateIcon />}
            title={`No matches for ${searchString ? `"${searchString}"` : 'your search'}`}
            description="Please try another search."
          />
        ) : null}

        {!isLoading && assets?.length ? (
          <>
            <Table
              isSmallHeaderText
              isCapitalizedHeaderText
              items={assets}
              pending={isLoading}
              rowIdKey={assetSummary => assetSummary?.asset?.resource?.id || ''}
              cellSettings={{
                name: {
                  width: 500,
                  label: 'Name',
                  cellRenderer: assetSummary => {
                    const technology = assetSummary.asset?.connection?.technology || ''
                    const dataSetType = (
                      <Box display="flex" alignItems="center" gap={0.5} mt={0.5}>
                        <img src={getAssetCloudImgUrl(technology)} alt={technology} width={15} height={15} />
                        <Typography fontSize={11} fontWeight={500} textTransform="capitalize">
                          {datasetType}
                        </Typography>
                      </Box>
                    )
                    return (
                      <NameAndCodeCellRenderer
                        name={
                          <TextCellRenderer
                            onClick={() => {
                              navigate(
                                RoutesManager.assetManager.assets.detail.root.getURL({
                                  code: assetSummary?.asset?.resource?.id || '',
                                  resourceTypeCode: assetSummary?.asset?.resource?.resourceType?.code || '',
                                }),
                              )
                            }}
                            value={assetSummary?.asset?.resource?.name}
                          />
                        }
                        code={dataSetType}
                      />
                    )
                  },
                },
                personalDataType: {
                  label: 'Data Sensitivity',
                  cellRenderer: assetSummary => {
                    const hasPersonalDataTypes = (assetSummary?.asset?.dataCategory?.length || 0) > 0
                    return hasPersonalDataTypes ? (
                      <Box display="flex" alignItems="center" gap={1}>
                        {assetSummary?.asset?.dataCategory?.map((dataCategory: LabelDTO) => {
                          return <Badge key={getLabelDTOUniqueIdentifier(dataCategory)}>{dataCategory?.value}</Badge>
                        })}
                      </Box>
                    ) : (
                      <EmptyValueRenderer color="darkDuskFaded" />
                    )
                  },
                },
                attributes: {
                  label: `${pluralize(attributeType, assets?.length || 0)}`,
                  cellRenderer: (assetSummary, index) => {
                    const count = assetSummary?.hierarchyCounts?.[attributeResourceTypeCode] || 0
                    return (
                      <>
                        <Chip className={classes.chip}>
                          <Typography fontSize={11} fontWeight={500}>
                            {count}
                          </Typography>
                        </Chip>
                        {(assets?.length || 0) - 5 === index ? (
                          <Waypoint
                            onEnter={() => {
                              if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                            }}
                          />
                        ) : null}
                      </>
                    )
                  },
                },
                actions: {
                  width: 300,
                  cellRenderer: assetSummary => {
                    const datasetId = assetSummary.asset?.resource?.id
                    const dsrConfig = assetSummary.asset?.dsrConfiguration
                    const selectedDsrConfig = dsrConfig?.[scenarioId!] || {}
                    const { isConfigured, isSkipped } = selectedDsrConfig || {}
                    let component = null

                    if (selectedDsrConfig && !isConfigured && isSkipped) {
                      component = (
                        <>
                          <SkippedIcon />
                          <Typography variant="body">Skipped</Typography>
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(
                                RoutesManager.assetManager.assets.detail.dsrConfigIdentity.getURL({
                                  scenarioId,
                                  resourceTypeCode,
                                  id: datasetId,
                                  formMode: FormMode.CREATE,
                                  assetType: databaseResourceTypeCode,
                                }),
                              )
                            }}
                          >
                            Configure Identity
                          </Button>
                        </>
                      )
                    }

                    if (selectedDsrConfig && isConfigured && isSkipped) {
                      component = (
                        <>
                          <SkippedIcon />
                          <Typography variant="body">Skipped</Typography>
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(
                                RoutesManager.assetManager.assets.detail.dsrConfigIdentity.getURL({
                                  scenarioId,
                                  resourceTypeCode,
                                  id: datasetId,
                                  formMode: FormMode.EDIT,
                                  assetType: databaseResourceTypeCode,
                                }),
                              )
                            }}
                          >
                            Configure Identity
                          </Button>
                        </>
                      )
                    }

                    if (selectedDsrConfig && isConfigured && !isSkipped) {
                      component = (
                        <>
                          <ConfiguredIcon />
                          <Typography color={theme.palette.greenHaze.main} fontSize={14} fontWeight={600}>
                            Configured
                          </Typography>
                          <Button
                            color="tertiary"
                            onClick={() => {
                              setSelectedDatasetForSqlPreview(assetSummary)
                            }}
                          >
                            SQL
                          </Button>
                          <ButtonActions
                            variant="secondary"
                            items={[
                              {
                                content: 'Configure Identity',
                                onClick: () => {
                                  navigate(
                                    RoutesManager.assetManager.assets.detail.dsrConfigIdentity.getURL({
                                      scenarioId,
                                      id: datasetId,
                                      formMode: FormMode.EDIT,
                                      resourceTypeCode,
                                      assetType: databaseResourceTypeCode,
                                    }),
                                  )
                                },
                              },
                              {
                                content: 'Skip',
                                onClick: async () => {
                                  await skipDataset({
                                    params: {
                                      scenarioId,
                                      assetId: datasetId,
                                      payload: {
                                        isSkipped: true,
                                      },
                                    },
                                  })
                                },
                              },
                            ]}
                          >
                            Edit
                          </ButtonActions>
                        </>
                      )
                    }

                    if (!Object.keys(selectedDsrConfig).length) {
                      component = (
                        <ButtonActions
                          variant="secondary"
                          items={[
                            {
                              content: 'Configure Identity',
                              onClick: () => {
                                navigate(
                                  RoutesManager.assetManager.assets.detail.dsrConfigIdentity.getURL({
                                    scenarioId,
                                    resourceTypeCode,
                                    id: datasetId,
                                    formMode: FormMode.CREATE,
                                    assetType: databaseResourceTypeCode,
                                  }),
                                )
                              },
                            },
                            {
                              content: 'Skip',
                              onClick: async () => {
                                await skipDataset({
                                  params: {
                                    scenarioId,
                                    assetId: datasetId,
                                    payload: {
                                      isSkipped: true,
                                    },
                                  },
                                })
                              },
                            },
                          ]}
                        >
                          Configure
                        </ButtonActions>
                      )
                    }

                    return (
                      <Box width={260} display="flex" justifyContent="right" alignItems="center" gap={1}>
                        {component}
                      </Box>
                    )
                  },
                },
              }}
            />

            <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
          </>
        ) : null}
        {selectedDatasetForSqlPreview ? (
          <SQLPreviewModal
            scenarioId={scenarioId!}
            assetSummary={selectedDatasetForSqlPreview}
            rightCode={rightCode}
            onCancel={() => {
              setSelectedDatasetForSqlPreview(null)
            }}
          />
        ) : null}
      </Box>
    </Root>
  )
}
