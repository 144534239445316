import React, { useCallback, useMemo } from 'react'
import { AutocompleteChangeReason, Box, Typography } from '@mui/material'
import { ChipsAutocomplete, ChipsAutocompleteOptionType, DropListButton, TextInput } from '@ketch-com/deck'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { MaybeNull } from 'interfaces'
import { useFormikContext } from 'formik'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'
import { renderSelectedMultipleIdentityChips } from 'pages/utils/renderSelectedMultipleIdentityChips'
import { CustomSqlFormData } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSQLTypes'

type SelectedIdentitySpace = MaybeNull<IdentitySpaceDTO> | IdentitySpaceDTO[]

interface CustomSqlIdentitySpaceSelectProps {
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  selectedIdentitySpace: SelectedIdentitySpace
  setSelectedIdentitySpace: React.Dispatch<React.SetStateAction<MaybeNull<IdentitySpaceDTO> | IdentitySpaceDTO[]>>
  identitySpaces?: IdentitySpaceDTO[]
  isLoadingIdentitySpaces: boolean
}

export const CustomSqlIdentitySpaceSelect: React.FC<CustomSqlIdentitySpaceSelectProps> = ({
  setInputValue,
  selectedIdentitySpace,
  setSelectedIdentitySpace,
  identitySpaces = [],
  isLoadingIdentitySpaces,
}) => {
  const { values, setFieldValue, setFieldTouched, touched, errors, isSubmitting } =
    useFormikContext<CustomSqlFormData>()
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isMultipleIdentitiesFlagEnabled = !isFeatureFlagEnabled(
    FEATURE_FLAGS.IMPORT_CONTEXT,
    FEATURE_FLAGS_VALUES.IMPORT_CONTEXT_SINGLE_IDENTITY,
  )

  const identitySpaceCodeFieldName = isMultipleIdentitiesFlagEnabled ? 'identitySpaceCodes' : 'identitySpaceCode'

  const handleSelectChange = useCallback(
    (
      e: React.SyntheticEvent<Element, Event>,
      identity: ChipsAutocompleteOptionType | ChipsAutocompleteOptionType[] | null,
      reason: AutocompleteChangeReason,
    ) => {
      if (reason === 'clear') {
        setFieldValue(identitySpaceCodeFieldName, isMultipleIdentitiesFlagEnabled ? [] : '')
        setSelectedIdentitySpace(isMultipleIdentitiesFlagEnabled ? [] : null)
      } else {
        if (isMultipleIdentitiesFlagEnabled && Array.isArray(identity)) {
          const identityCodes = identity?.map(ident => ident?.value).filter(code => !!code)
          setFieldValue(identitySpaceCodeFieldName, identityCodes)
          const selectedIdentitySpaces = identity?.map(ident =>
            identitySpaces.find(space => ident.value === space.code),
          )
          setSelectedIdentitySpace(selectedIdentitySpaces as IdentitySpaceDTO[])
        } else {
          setFieldValue(identitySpaceCodeFieldName, (identity as ChipsAutocompleteOptionType)?.value)
          const selectedIdentitySpace = identitySpaces.find(
            space => (identity as ChipsAutocompleteOptionType)?.value === space.code,
          )

          if (selectedIdentitySpace) setSelectedIdentitySpace(selectedIdentitySpace)
        }
      }
    },
    [
      identitySpaceCodeFieldName,
      identitySpaces,
      isMultipleIdentitiesFlagEnabled,
      setFieldValue,
      setSelectedIdentitySpace,
    ],
  )

  const handleDeselectValue = useCallback(
    (chipData: ChipsAutocompleteOptionType) => {
      const filteredIdentitySpaceCodes = values.identitySpaceCodes.filter(identity => identity !== chipData.value)
      const filteredSelectedIdentitySpace = (selectedIdentitySpace as IdentitySpaceDTO[])?.filter(
        space => space.code !== chipData.value,
      )
      setFieldValue('identitySpaceCodes', filteredIdentitySpaceCodes)
      setSelectedIdentitySpace(filteredSelectedIdentitySpace)
    },
    [selectedIdentitySpace, setFieldValue, setSelectedIdentitySpace, values.identitySpaceCodes],
  )

  const options = useMemo(
    () => identitySpaces?.map(identity => ({ value: identity.code, label: identity.name })) || [],
    [identitySpaces],
  )
  const chipValues = useMemo(() => {
    if (Array.isArray(selectedIdentitySpace)) {
      return selectedIdentitySpace?.map(space => ({ value: space.code, label: space.name }))
    }
    return []
  }, [selectedIdentitySpace])

  const renderChips = useMemo(() => renderSelectedMultipleIdentityChips(handleDeselectValue), [handleDeselectValue])

  if (isMultipleIdentitiesFlagEnabled) {
    return (
      <ChipsAutocomplete
        chipSize="small"
        fullWidth
        inRowChips
        options={options}
        filterSelectedOptions={false}
        label="Identifiers"
        loading={isLoadingIdentitySpaces}
        getOptionLabel={option => option?.label || ''}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        onChange={handleSelectChange}
        renderChipItem={renderChips}
        disabled={isSubmitting || isLoadingIdentitySpaces}
        handleChipClick={handleDeselectValue}
        placeholder={
          chipValues.length ? `${chipValues.length} Selected Identifiers` : 'Start typing an Identifier name'
        }
        value={chipValues}
      />
    )
  }

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Typography variant="label">Identifier</Typography>
      <DropListButton
        sx={{ width: 320 }}
        loading={isLoadingIdentitySpaces}
        value={selectedIdentitySpace as MaybeNull<IdentitySpaceDTO>}
        filterOptions={x => x}
        clearOnBlur
        noOptionsText="No Identities"
        getOptionDisabled={option => option?.code === values?.identitySpaceCode}
        onChange={(e, identity, reason) => {
          if (reason === 'clear') {
            setFieldValue(identitySpaceCodeFieldName, '')
            setSelectedIdentitySpace(null)
          } else {
            setFieldValue(identitySpaceCodeFieldName, identity?.code)
            setSelectedIdentitySpace(identity)
          }
        }}
        onInputChange={(e, inputVal) => {
          setInputValue(inputVal)
        }}
        options={identitySpaces}
        renderInput={params => (
          <TextInput
            {...params}
            error={touched?.identitySpaceCode && Boolean(errors?.identitySpaceCode)}
            errorMessage={errors?.identitySpaceCode}
            onBlur={() => {
              setFieldTouched(identitySpaceCodeFieldName, true)
            }}
            InputProps={{
              ...params.InputProps,
            }}
            placeholder="Select Identity"
            variant="outlined"
          />
        )}
        renderOption={(props, identity) => {
          return (
            <Box component="li" {...props} key={identity?.code} display="flex" flexDirection="column" gap={0.5} py={2}>
              <Typography alignSelf="flex-start" variant="body">
                {identity?.name}
              </Typography>
              <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                {identity?.code}
              </Typography>
            </Box>
          )
        }}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.code === value?.code}
      />
      <Typography variant="smallBody" color="darkDuskFaded.main">
        {`Code: ${values?.identitySpaceCode || 'None'}`}
      </Typography>
    </Box>
  )
}
