import { useMemo } from 'react'

type Option = string | Record<string, any>

export const useFilteredOptions = <T extends Option>(
  options: T[],
  inputValue?: string | null,
  filterProperty = 'name',
) => {
  return useMemo(() => {
    if (!inputValue) {
      return options
    }
    const lowerCaseInput = inputValue.toLowerCase()
    return options.filter(option => {
      if (typeof option === 'string') {
        return option.toLowerCase().includes(lowerCaseInput)
      } else if (typeof option === 'object' && option[filterProperty]) {
        const value = String(option[filterProperty]).toLowerCase()
        return value.includes(lowerCaseInput)
      }
      return false
    })
  }, [options, inputValue, filterProperty])
}
