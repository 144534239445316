import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { Formik } from 'formik'

import { formatCodeFromName } from 'utils/formatters'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'

import { useIdentityVerificationFormUtils } from './hooks'
import { VerificationOption } from './components'
import { emailNotificationErrorKeys, SETTINGS_TAB_VALUES, verificationOptionsErrorKeys } from './constants'
import { FormInput } from 'components/form/FormInput'
import { Typography, Box } from '@mui/material'
import { Chip, Icon, Tab, TabGroup } from '@ketch-com/deck'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormEmailsInput } from 'components/form/FormEmailsInput/FormEmailsInput'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

const PREFIX = 'IdentityVerificationForm'

const classes = {
  tabs: `${PREFIX}-tabs`,
  addIcon: `${PREFIX}-addIcon`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`& .${classes.tabs}`]: {
    borderBottom: `1px solid ${palette.iron.main}`,
    marginBottom: spacing(1.5),
    width: '100%',
  },

  [`& .${classes.addIcon}`]: {
    fill: palette.royalBlue.main,
    fontSize: 20,
  },
}))

interface IIdentityVerificationFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const IdentityVerificationForm: React.FC<IIdentityVerificationFormProps> = ({
  step,
  steps,
  handleClose,
  handleRemove,
  handleSave,
}) => {
  const {
    activeTab,
    appendBodyVariable,
    authenticators,
    initialValues,
    isReady,
    onSubmit,
    setActiveTab,
    updateBodyCursorPos,
    validationSchema,
    /* TODO:BAC - hiding LexusNexis option from UI per the following slack comment: https://ketch-com.slack.com/archives/C0497EF7PDL/p1688580579429359?thread_ts=1688558086.867019&cid=C0497EF7PDL. This will be replaced by another auth provider in the future */
    // lexisNexisAppInstances,
  } = useIdentityVerificationFormUtils({ step, steps, handleSave })

  return (
    <SidebarConfigsWrapper isLoading={!isReady} handleClose={handleClose}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} validateOnMount onSubmit={onSubmit}>
        {form => {
          const { isValid, submitForm, setFieldValue, values, errors, touched } = form
          const errorKeys = Object.keys(errors)
          const touchedKeys = Object.keys(touched)

          const verificationOptionsErrorCount = errorKeys.filter(key =>
            verificationOptionsErrorKeys.includes(key),
          ).length
          const isVerificationOptionsTouched = touchedKeys.some(key => verificationOptionsErrorKeys.includes(key))

          const emailNotificationErrorCount = errorKeys.filter(key => emailNotificationErrorKeys.includes(key)).length
          const isEmailNotificationInputsTouched = touchedKeys.some(key => emailNotificationErrorKeys.includes(key))

          return (
            <Fragment>
              <SidebarConfigsHeader
                steps={steps}
                isValid={isValid}
                step={step}
                handleSave={() => submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
                copyable
              />

              <SidebarConfigsBody sx={{ px: 3 }}>
                <StyledBox>
                  <Box
                    sx={{
                      mb: 3,
                    }}
                  >
                    <FormInput
                      required
                      formPropertyName="description"
                      fullWidth
                      label="Description Label"
                      placeholder="Enter description"
                      onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                      shouldUpdateDebounced
                    />
                  </Box>

                  <Box
                    sx={{
                      mb: 5,
                    }}
                  >
                    <FormInput
                      formPropertyName="code"
                      fullWidth
                      label="Code"
                      placeholder="Enter code"
                      required
                      shouldUpdateDebounced
                    />
                  </Box>

                  <Box mb={3}>
                    <Typography variant="h3">Settings</Typography>
                  </Box>

                  <Box mb={3}>
                    <TabGroup
                      value={activeTab}
                      onChange={(_: React.SyntheticEvent, newValue: SETTINGS_TAB_VALUES) => setActiveTab(newValue)}
                      aria-label="tabs"
                      showBottomDivider
                    >
                      <Tab
                        value={SETTINGS_TAB_VALUES.EMAIL_NOTIFICATION}
                        label={
                          !!emailNotificationErrorCount && isEmailNotificationInputsTouched ? (
                            <Box sx={{ color: 'chileanFire.main' }}>Email Notification</Box>
                          ) : (
                            'Email Notification'
                          )
                        }
                        key={SETTINGS_TAB_VALUES.EMAIL_NOTIFICATION}
                      />
                      <Tab
                        value={SETTINGS_TAB_VALUES.VERIFICATION_OPTIONS}
                        label={
                          !!verificationOptionsErrorCount && isVerificationOptionsTouched ? (
                            <Box sx={{ color: 'chileanFire.main' }}>Verification Options</Box>
                          ) : (
                            'Verification Options'
                          )
                        }
                        key={SETTINGS_TAB_VALUES.VERIFICATION_OPTIONS}
                      />
                    </TabGroup>
                  </Box>

                  {activeTab === SETTINGS_TAB_VALUES.EMAIL_NOTIFICATION ? (
                    <>
                      <FormEmailsInput sx={{ mb: 2 }} disabled fullWidth formPropertyName="to" label="To" required />
                      <FormEmailsInput sx={{ mb: 2 }} fullWidth formPropertyName="cc" label="Cc" required />
                      <Box mb={3}>
                        <FormInput
                          formPropertyName="subject"
                          fullWidth
                          label="Subject"
                          placeholder="Enter subject text"
                          required
                          shouldUpdateDebounced
                        />
                      </Box>
                      <Box mb={3}>
                        <FormInput
                          onBlur={updateBodyCursorPos}
                          formPropertyName="body"
                          fullWidth
                          label="Body"
                          placeholder="Enter body text"
                          required
                          rows={10}
                          multiline
                          shouldUpdateDebounced
                        />
                      </Box>

                      <Typography variant="label">Data Subject Variables</Typography>

                      <Box display="flex" flexWrap="wrap" alignItems="center" gap={1} mt={1} mb={3}>
                        <Chip
                          clickable
                          label="First Name"
                          deleteIcon={<Icon name="OPlus" />}
                          onClick={() => appendBodyVariable(form, '{{first_name}}')}
                          onDelete={() => appendBodyVariable(form, '{{first_name}}')}
                        />

                        <Chip
                          clickable
                          label="Last Name"
                          deleteIcon={<Icon name="OPlus" />}
                          onClick={() => appendBodyVariable(form, '{{last_name}}')}
                          onDelete={() => appendBodyVariable(form, '{{last_name}}')}
                        />

                        <Chip
                          clickable
                          label="Request ID"
                          deleteIcon={<Icon name="OPlus" />}
                          onClick={() => appendBodyVariable(form, '{{request_id}}')}
                          onDelete={() => appendBodyVariable(form, '{{request_id}}')}
                        />
                        <Chip
                          clickable
                          label="Request Timeout"
                          deleteIcon={<Icon name="OPlus" />}
                          onClick={() => appendBodyVariable(form, '{{expires_at}}')}
                          onDelete={() => appendBodyVariable(form, '{{expires_at}}')}
                        />
                      </Box>

                      <FormInput
                        label="Identity Verification Timeout Days"
                        required
                        fullWidth
                        formPropertyName="identityVerificationTimeoutDays"
                        placeholder="Example: 5"
                        shouldUpdateDebounced
                      />
                    </>
                  ) : (
                    <>
                      <VerificationOption
                        title="Standard"
                        tooltipContent={
                          values?.isStandard ? (
                            <Box display="flex" flexDirection="column" gap={2} px={0.5} py={1} sx={{ maxWidth: 247 }}>
                              <Typography variant="smallLabel" color="white.main">
                                Standard flow is used by default
                              </Typography>
                              <Typography variant="smallBody" color="white.main">
                                To disable Standard option please select any Custom options below.
                              </Typography>
                              <Typography variant="smallBody" color="white.main">
                                Standard flow is not possible to combine together with other Custom verification
                                options.
                              </Typography>
                            </Box>
                          ) : null
                        }
                        value={!!values?.isStandard}
                        sx={{
                          mb: 4,
                          borderBottom: ({ palette }) =>
                            !!values?.isCustomIDV ? 'none' : `1px solid ${palette.iron.main}`,
                          borderRadius: ({ spacing }) => (!!values?.isCustomIDV ? spacing(1.375) : 0),
                        }}
                        onChange={e => {
                          if (values.isStandard && !values.isAuthentication && !values.isLexisNexis) return

                          setFieldValue('isStandard', e.target.checked)
                          if (e.target.checked) {
                            setFieldValue('isAuthentication', false)
                            setFieldValue('isLexisNexis', false)
                            setFieldValue('isIdMe', false)

                            setFieldValue('authenticatorID', '')
                            setFieldValue('authenticatorButtonText', '')
                            setFieldValue('authenticatorType', '')

                            setFieldValue('lexisNexisButtonText', '')
                            setFieldValue('lexisNexisAppInstanceID', '')
                          }
                        }}
                      />

                      <Typography variant="label" color="darkDuskFaded.main">
                        CUSTOM
                      </Typography>

                      <VerificationOption
                        title="Authenticator"
                        value={!!values?.isAuthentication}
                        onChange={e => {
                          setFieldValue('isAuthentication', !!e.target.checked)
                          if (!e.target.checked) {
                            setFieldValue('authenticatorButtonText', '')
                            setFieldValue('authenticatorID', '')
                            setFieldValue('authenticatorType', '')

                            setFieldValue('lexisNexisButtonText', '')
                          }
                          if (!!e.target.checked) setFieldValue('isStandard', false)

                          if (!e.target.checked && !values?.isLexisNexis) {
                            setFieldValue('isStandard', true)
                          }
                        }}
                        sx={{
                          mb: 1.5,
                          borderBottom: ({ palette }) =>
                            !!values?.isAuthentication ? 'none' : `1px solid ${palette.iron.main}`,
                          borderRadius: ({ spacing }) => (!!values?.isAuthentication ? spacing(1.375) : 0),
                        }}
                        requisiteForm={
                          <Box display="flex" flexDirection="column" pt={1.5} px={2.25}>
                            {values.isLexisNexis ? (
                              <Box mb={3}>
                                <FormInput
                                  required={!!values.isLexisNexis && !!values?.isAuthentication}
                                  formPropertyName="authenticatorButtonText"
                                  fullWidth
                                  label="CTA Button Text"
                                  placeholder="Enter CTA Button Text"
                                  shouldUpdateDebounced
                                />
                              </Box>
                            ) : null}

                            <FormDroplistButton
                              required
                              disableClearable
                              label="Authenticator Connectors"
                              fullWidth={true}
                              formPropertyName="authenticatorID"
                              placeholder="Select Connection..."
                              onItemClick={v => setFieldValue('authenticatorType', v.type)}
                              sx={{
                                mb: 3,
                              }}
                              valueKey="id"
                              items={authenticators.map(authenticator => ({
                                id: authenticator.id,
                                name: authenticator.name,
                                type: authenticator.type,
                              }))}
                            />
                          </Box>
                        }
                      />

                      {isRelease(Release.IdMeVerification) && (
                        <VerificationOption
                          title="ID.ME"
                          value={!!values?.isIdMe}
                          onChange={e => {
                            setFieldValue('isIdMe', !!e.target.checked)
                            if (!e.target.checked) {
                              setFieldValue('authenticatorButtonText', '')
                              setFieldValue('authenticatorID', '')
                              setFieldValue('authenticatorType', '')
                              setFieldValue('lexisNexisButtonText', '')
                              setFieldValue('lexisNexisAppInstanceID', '')
                            }
                            if (!!e.target.checked) setFieldValue('isStandard', false)

                            if (!e.target.checked && !values?.isAuthentication && !values?.isLexisNexis) {
                              setFieldValue('isStandard', true)
                            }
                          }}
                          sx={{
                            mb: 1.5,
                            borderBottom: ({ palette }) =>
                              !!values?.isIdMe ? 'none' : `1px solid ${palette.iron.main}`,
                            borderRadius: ({ spacing }) => (!!values?.isIdMe ? spacing(1.375) : 0),
                          }}
                        />
                      )}
                    </>
                  )}
                </StyledBox>
              </SidebarConfigsBody>
            </Fragment>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
