import { useUserMe } from 'api/users/queries/useUserMe'
import { protocolId } from 'pages/consentAndRights/consent/privacyProtocols/constants'

/**
 * Returns true if the org is a google test org and the protocol is consent mode. Only for google tester org.
 * @param protocol
 * @returns
 */
export const useShouldShowGoogleTesterNotice = (protocol: string) => {
  const user = useUserMe()
  const orgCode = user.data?.organization.code
  const isGoogleTestOrg = orgCode === 'cmp_partner_program_org' || orgCode === 'ketch_apps'
  const isConsentMode = protocol === protocolId.google
  return isGoogleTestOrg && isConsentMode
}
