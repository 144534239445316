import { useCallback, useState } from 'react'
import { FilterContextProps } from '../context/AssetsTabFilterContext'

export const useAssetTableControlledPagination = (setPaginationModel?: FilterContextProps['setPaginationModel']) => {
  const [page, setPage] = useState(1)

  const handlePaginationChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      if (setPaginationModel) {
        setPaginationModel(prevValue => ({ ...prevValue, page: value - 1 }))
      }
      setPage(value)
    },
    [setPaginationModel],
  )

  return {
    page,
    setPage,
    handlePaginationChange,
  }
}
