import { Dispatch, SetStateAction } from 'react'
import { Box } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
import { RightType } from 'interfaces/events'
import { Rights } from 'pages/assetManager/scenario/list/components/Rights'
import { GetDSRConfigCountResponseBodyDTO, ScenarioDTO, AssetSummaryDTO } from '@ketch-com/figurehead'
import { theme } from '@ketch-com/deck'
import { getAssetType } from 'pages/assetManager/utils'
import pluralize from 'pluralize'

type Props = {
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
  setRightType: Dispatch<SetStateAction<RightType>>
  assetSummary: AssetSummaryDTO
  rightType: RightType
}

const segmentsTabOptions = [
  {
    id: RightType.RIGHT_TYPE_UNSPECIFIED,
    name: 'All',
  },
  {
    id: RightType.RIGHT_TYPE_CANONICAL,
    name: 'Canonical',
  },
  {
    id: RightType.RIGHT_TYPE_CUSTOM,
    name: 'Custom',
  },
]

/**
 * @deprecated
 */
export const DatabaseScenario: React.FC<Props> = ({ dsrConfigCount, setRightType, assetSummary, rightType }) => {
  const providerCode = assetSummary?.asset?.connection?.providerCode || ''
  const databaseType = getAssetType(providerCode, 1)
  const datasetType = getAssetType(providerCode, 2)
  const { totalAssets = 0, personalAssets = 0, dsrScenario = {} } = dsrConfigCount
  const canonicalEvents: ScenarioDTO[] = []
  const customEvents: ScenarioDTO[] = []

  for (const [, scenario] of Object.entries(dsrScenario)) {
    if (scenario.rightType === RightType.RIGHT_TYPE_CANONICAL) canonicalEvents.push(scenario)
    if (scenario.rightType === RightType.RIGHT_TYPE_CUSTOM) customEvents.push(scenario)
  }

  return (
    <Box p={1.5} pt={0}>
      <Box mb={4}>
        <Text size={20} weight={700} display="block">
          DSR Configuration
        </Text>
        <Text size={14} color="grey">
          See how data assets are configured to support data subjects rights requests.
        </Text>
      </Box>
      <Box mb={4} display="flex" gap={4}>
        <Box>
          <Text display="block" color="grey" size={12}>
            Total {pluralize(datasetType, totalAssets)} in {pluralize(databaseType, totalAssets)}
          </Text>
          <Text size={20} weight={700}>
            {totalAssets}
          </Text>
        </Box>
        <Box>
          <Text display="block" color="grey" size={12}>
            {pluralize(datasetType, personalAssets)} With Personal Data
          </Text>
          <Text size={20} weight={700}>
            {personalAssets}
          </Text>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="left"
        alignItems="center"
        gap={4}
        mb={4}
        pb={2}
        borderBottom={`1px solid ${theme.palette.iron.main}`}
      >
        <SegmentTabs
          tabs={segmentsTabOptions}
          value={rightType}
          onChange={rightType => {
            setRightType(rightType)
          }}
        />
      </Box>
      {canonicalEvents.length ? (
        <Box>
          <Text color="grey" size={20} weight={700}>
            Canonical Events
          </Text>
          <Rights
            eventBasedScenario={canonicalEvents}
            dsrConfigCount={dsrConfigCount}
            isCanonical={true}
            assetSummary={assetSummary}
          />
        </Box>
      ) : null}
      {customEvents.length ? (
        <Box>
          <Text color="grey" size={20} weight={700}>
            Custom Events
          </Text>
          <Rights eventBasedScenario={customEvents} dsrConfigCount={dsrConfigCount} assetSummary={assetSummary} />
        </Box>
      ) : null}
    </Box>
  )
}
