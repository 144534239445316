import { Box, ClickAwayListener, Typography, styled } from '@mui/material'
import {
  BadgeDovetail,
  BadgeDovetailVariant,
} from 'pages/dataSystems/Classification/components/BadgeDovetail/BadgeDovetail'
import { ReactComponent as AutomatedIcon } from 'assets/icons/automated.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon-white.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { CategorySourceV3DTO, ConfidenceLevelV3DTO, ConfirmationStatusV3DTO } from '@ketch-com/figurehead'
import { TooltipV2 } from 'components/ui-kit/tooltip/TooltipV2'
import { useState } from 'react'
import { theme } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'

const Body = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '.25rem',
}))

const TooltipContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '.5rem',
  width: '250px',
  padding: '5px',
}))

const ButtonsContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: spacing(1),
  margin: '0 0 10px 0',
}))

const ConfirmButton = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50%',
  padding: '5px 10px',
  borderRadius: '5px',
  cursor: 'pointer',
  backgroundColor: '#5774FF',
  gap: spacing(1),
}))

const DismissButton = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50%',
  padding: '5px 10px',
  borderRadius: '5px',
  cursor: 'pointer',
  gap: spacing(1),
}))

const DescriptionContainer = styled(Box)(({ theme: { spacing } }) => ({
  color: theme.palette.lighterGrey.main,
}))

type Props = {
  name?: string
  description?: string
  confirmationStatus?: ConfirmationStatusV3DTO
  confidence?: ConfidenceLevelV3DTO
  variant?: BadgeDovetailVariant
  categorySource?: CategorySourceV3DTO
  handleConfirm?: () => void
  handleDismiss?: () => void
}

export const AutomatedBadge: React.FC<Props> = ({
  variant,
  name,
  description,
  confirmationStatus,
  categorySource,
  confidence,

  handleConfirm,
  handleDismiss,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleIsOpen = () => {
    setIsOpen(prevValue => !prevValue)
  }

  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteLabel = getIsPermitted([PERMISSIONS.LABEL_WRITE])

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Box>
        <TooltipV2
          title={
            <TooltipContainer>
              {confirmationStatus !== ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3 ? (
                <>
                  {isPermittedToWriteLabel && (
                    <ButtonsContainer>
                      {handleConfirm && (
                        <ConfirmButton
                          onClick={() => {
                            handleConfirm()
                            setIsOpen(false)
                          }}
                        >
                          <CheckIcon width={15} height={15} color={theme.palette.white.main} />
                          Confirm
                        </ConfirmButton>
                      )}
                      {handleDismiss && (
                        <DismissButton
                          onClick={() => {
                            handleDismiss()
                            setIsOpen(false)
                          }}
                        >
                          <TrashIcon color={theme.palette.white.main} />
                          Dismiss
                        </DismissButton>
                      )}
                    </ButtonsContainer>
                  )}
                </>
              ) : (
                <Typography variant="label" color={theme.palette.white.main}>
                  Confirmed
                </Typography>
              )}

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '.25rem' }}>
                <AutomatedIcon width={15} height={15} color={theme.palette.darkGrey.main} />
                <Typography variant="label" color={theme.palette.darkGrey.main}>
                  Auto:
                </Typography>
                {confidence === ConfidenceLevelV3DTO.MediumConfidenceLevelV3 ? (
                  <Typography variant="label" color={theme.palette.cheese.main}>
                    Fairly Confident
                  </Typography>
                ) : (
                  <Typography variant="label" color={theme.palette.greenHaze.main}>
                    Confident
                  </Typography>
                )}
              </Box>

              {!!description && <DescriptionContainer>{description}</DescriptionContainer>}

              {!!categorySource?.categorySourceCode && (
                <Box>
                  <Typography variant="label" color={theme.palette.darkGrey.main}>
                    Source:
                  </Typography>{' '}
                  <Typography variant="label" color={theme.palette.white.main}>
                    {categorySource?.categorySourceCode}
                  </Typography>
                  <br />
                  {categorySource?.categorySourceDescription}
                </Box>
              )}
            </TooltipContainer>
          }
          arrow={false}
          open={isOpen}
          onClick={handleIsOpen}
          onMouseOver={() =>
            confirmationStatus === ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3 ? setIsOpen(true) : null
          }
          onMouseLeave={() =>
            confirmationStatus === ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3 ? setIsOpen(false) : null
          }
        >
          <Box
            style={{
              cursor: handleConfirm || handleDismiss ? 'pointer' : 'auto',
            }}
          >
            <BadgeDovetail variant={variant}>
              <Body>
                <AutomatedIcon
                  color={
                    confirmationStatus === ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3
                      ? theme.palette.fadedDarkGrey.main
                      : confidence === ConfidenceLevelV3DTO.MediumConfidenceLevelV3
                      ? theme.palette.chileanFire.main
                      : theme.palette.greenHaze.main
                  }
                />
                <Typography variant="subtitle2">{name}</Typography>
              </Body>
            </BadgeDovetail>
          </Box>
        </TooltipV2>
      </Box>
    </ClickAwayListener>
  )
}
