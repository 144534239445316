import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AssetSummaryDTO, GetDSRConfigCountResponseBodyDTO, ScenarioDTO } from '@ketch-com/figurehead'
import Typography from '@mui/material/Typography'
import { ScenarioAssetConfiguration } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/ScenarioAssetConfiguration'
import { ScenarioStats } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/ScenarioStats'
import React from 'react'
import { ScenarioNavButton } from './ScenarioNavButton'

const PREFIX = 'ScenarioStats'

const classes = {
  rightsConfiguration: `${PREFIX}-rights-configuration`,
}

const Root = styled(Box)(({ theme: { spacing, palette } }) => ({
  [`& .${classes.rightsConfiguration}`]: {
    backgroundColor: palette.superLightGrey.main,
    margin: spacing(1, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `2px ${spacing(1.5)}`,
    lineHeight: 1,
    borderRadius: spacing(1.5),
  },
}))

type Props = {
  scenario: ScenarioDTO
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
  isDatasetScenario: boolean
  assetSummary: AssetSummaryDTO
}

export const DataSystemOrchestrationTabScenario: React.FC<Props> = ({
  scenario,
  dsrConfigCount,
  assetSummary,
  isDatasetScenario,
}) => {
  return (
    <Root>
      <Box className={classes.rightsConfiguration}>
        <Typography component="div" width="50%">
          {scenario.name}
        </Typography>
        {isDatasetScenario ? (
          <ScenarioAssetConfiguration scenario={scenario} assetSummary={assetSummary} />
        ) : (
          <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
            <ScenarioStats
              scenario={scenario}
              dsrConfigCount={dsrConfigCount}
              assetSummary={assetSummary}
              hideNavButton
            />
            <ScenarioNavButton scenario={scenario} />
          </Box>
        )}
      </Box>
    </Root>
  )
}
