import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { ConfigurationProgress } from 'pages/assetManager/scenario/components'
import { ReactComponent as ScenarioDetailsIcon } from 'assets/icons/square_button_next.svg'
import { AssetSummaryDTO, GetDSRConfigCountResponseBodyDTO, ScenarioDTO } from '@ketch-com/figurehead'
import { ReactComponent as ConfiguredIcon } from 'assets/icons/green_checked_circle.svg'
import { getAssetType } from 'pages/assetManager/utils'
import pluralize from 'pluralize'
import { LookUpTab } from 'pages/assetManager/scenario/details/constants'
import React from 'react'
import { SelectedAsset } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/RightsOrchestrationTab'
import Typography from '@mui/material/Typography'

type Props = {
  scenario: ScenarioDTO
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
  assetSummary: AssetSummaryDTO
  hideNavButton?: boolean
}

export const ScenarioStats: React.FC<Props> = ({ scenario, dsrConfigCount, assetSummary = {}, hideNavButton }) => {
  const providerCode = assetSummary?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)
  const selectedAsset = React.useContext(SelectedAsset)
  const assetId = selectedAsset.resource?.id!
  const { dsrConfigurationCount = {}, personalAssets = 0 } = dsrConfigCount
  const { customizedAssets = 0, skippedAssets = 0, totalActions = 0 } = dsrConfigurationCount[scenario.id || '']
  const allDatasetsConfigured = totalActions && personalAssets && totalActions === personalAssets
  const url = RoutesManager.systems.dsrInvocation.scenarioDetails.getURL({
    assetId,
    scenarioId: scenario.id!,
    tab: LookUpTab.DIRECT,
    resourceTypeCode: selectedAsset.resource?.resourceType!,
  })
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" flexBasis={292}>
      <Box display="flex" alignItems="center" justifyContent="flex-start" gap={1.5}>
        {allDatasetsConfigured ? (
          <Box width={24} height={24} display="flex" alignItems="center" justifyContent="center">
            <ConfiguredIcon />
          </Box>
        ) : (
          <ConfigurationProgress small progress={Math.round((totalActions / personalAssets) * 100)} />
        )}
        <Box display="flex" flexDirection="column" justifyContent="center" paddingY={1}>
          {allDatasetsConfigured ? (
            <Typography variant={'label'} color="greenHaze">
              Configured
            </Typography>
          ) : (
            <Typography variant={'label'}>
              {totalActions}/<Typography color="grey">{personalAssets}</Typography>{' '}
              {pluralize(datasetType, personalAssets)} Configured
            </Typography>
          )}
          <Typography variant="smallLabel" color="grey">
            {skippedAssets} skipped, {customizedAssets} customized
          </Typography>
        </Box>
      </Box>
      {!hideNavButton && (
        <Link to={url}>
          <IconButton
            sx={{ padding: 0 }}
            color="secondary"
            aria-label="Configure Dataset"
            disableFocusRipple
            disableRipple
          >
            <ScenarioDetailsIcon />
          </IconButton>
        </Link>
      )}
    </Box>
  )
}
