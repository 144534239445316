import { GridRowId } from '@mui/x-data-grid-premium'
import { useState } from 'react'
import { DataSystemAssetsTableProps } from '../DataSystemAssetsTable'
import { BulkEditModalType } from '../types'

export const useBulkEdit = () => {
  const [rowSelectionModel, setRowSelectionModel] = useState<DataSystemAssetsTableProps['rowSelectionModel']>([])
  const [resourceType, setResourceType] = useState<string | undefined>()
  const [showBulkEditModal, setShowBulkEditModal] = useState<BulkEditModalType | undefined>()
  const handleSelectionChange = (ids: GridRowId[], resourceType: string) => {
    setRowSelectionModel(ids)
    setResourceType(resourceType)
  }
  return {
    rowSelectionModel,
    setRowSelectionModel,
    resourceType,
    showBulkEditModal,
    setShowBulkEditModal,
    handleSelectionChange,
  }
}
