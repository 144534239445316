import { Icon, MegaFilter as DeckMegaFilter, Button, MegaFilterOptionType } from '@ketch-com/deck'
import React, { useContext, useMemo } from 'react'
import Box from '@mui/material/Box'
import { MegaFilterProps, MegaFilterType } from './interfaces'

import { MegaFilterCheckboxList } from './components/MegaFilterCheckboxList'
import { MegaFilterChipList } from './components/MegaFilterChipList'
import { CustomMegaFilterContext, withMegaFilterContext } from './context/MegaFilterContext'
import { mergeObjectsByKey } from './utils/utils'

/**
 *
 * This component uses the DECK MegaFilter component, but is wrapped in a React context
 * which contains some utility functions for the state management of the selected items
 *
 * This MegaFilter does not maintain the state of the selected items by itself, but instead the
 * selected data is passed down as the "value" prop
 *
 * The context has methods for using URL queryParams as state for the selected values. Basically, the selected values of the
 * filter are stored as query params in the URL (only the value of the filters, not every attribute). We use the query params,
 * together with the filters received as props to display the components inside the MegaFilter
 *
 */
const MegaFilterWithContext: React.FC<MegaFilterProps> = ({
  filters,
  isLoading = false,
  totalItemsCount,
  buttonProps,
}) => {
  const { clearAllSearchParams, removeQueryParamFromList, filterCount, filterSelectedData } =
    useContext(CustomMegaFilterContext)

  // sections, passed as prop to DECK MegaFilter comp
  const megaFilterSections = useMemo(
    () => filters.map(filter => ({ label: filter.label, value: filter.key })),
    [filters],
  )

  // represents the selected state in the DECK MegaFilter,
  const selectedValues = mergeObjectsByKey(filterSelectedData, filters)

  // rendering chips or checkbox components, depending on the data type
  const renderedItems = filters.map(filter => {
    if (filter.type === MegaFilterType.CheckList) {
      return <MegaFilterCheckboxList key={filter.key} data={filter} isLoading={isLoading} />
    } else if (filter.type === MegaFilterType.ChipList) {
      return <MegaFilterChipList key={filter.key} data={filter} isLoading={isLoading} />
    }
    return null
  })

  const filterButtonText = `Filter ${filterCount ? `(${filterCount})` : ''}`
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <DeckMegaFilter
        sx={{
          '& .MuiButtonBase-root': {
            '& .DeckBtnWrapChild': {
              width: 'max-content',
            },
          },
          '& .MegaFilter-selectedOptions': {
            '& .MuiButtonBase-root': {
              alignSelf: 'center',
            },
          },
        }}
        buttonTitle={filterButtonText}
        sections={megaFilterSections}
        totalFilteredItemsNumber={totalItemsCount}
        buttonProps={{
          color: 'secondary',
          endIcon: <Icon name="OFilter" sx={{ marginLeft: 0.5 }} />,
          pending: isLoading,
          ...buttonProps,
        }}
        value={selectedValues}
        renderActionItems={<ActionButton clearAllSearchParams={clearAllSearchParams} />}
        onDeselectChipHandler={(option: MegaFilterOptionType) => {
          removeQueryParamFromList({ key: option.category, value: option.value })
        }}
      >
        {renderedItems}
      </DeckMegaFilter>
    </Box>
  )
}

const ActionButton = ({ clearAllSearchParams }: { clearAllSearchParams: () => void }) => {
  return (
    <>
      <Button
        color="tertiary"
        size="medium"
        onClick={() => {
          clearAllSearchParams()
        }}
      >
        Clear all
      </Button>
    </>
  )
}

export const MegaFilter = withMegaFilterContext(MegaFilterWithContext)
