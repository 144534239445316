import { Icon, theme } from '@ketch-com/deck'
import { Box } from '@mui/material'

export const BulkEditModalDirectionIconBlock: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      paddingX={2}
      sx={{
        flexGrow: 1,
      }}
    >
      <Box display="flex" flexDirection="column" gap={4}>
        <Icon name="OArrowCLeft" fill={theme.palette.Text.Secondary} />
        <Icon name="OArrowCRight" fill={theme.palette.Text.Secondary} />
      </Box>
    </Box>
  )
}
