import { createContext } from 'react'
import { useCustomSqlUtils } from 'pages/dataSystems/AssetMigration/ScenarioDetails/useCustomSqlUtils'

export const CustomSqlContext = createContext({} as ReturnType<typeof useCustomSqlUtils>)

type Props = {
  children?: React.ReactNode
}

export const CustomSqlContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useCustomSqlUtils()

  return <CustomSqlContext.Provider value={{ ...hookValues }}>{children}</CustomSqlContext.Provider>
}

export const withCustomSqlContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <CustomSqlContextProvider>
        <Component {...props} />
      </CustomSqlContextProvider>
    )
  }
}
