import React, { useEffect, useState } from 'react'
import { CanonicalResourceTypeDTO, CreatePatchDSRConfigsRequestBodyDTO } from '@ketch-com/figurehead'
import { useNavigate, useParams } from 'react-router-dom'
import { useAssetsHierarchiesDynamic } from 'api/assets/queries/useAssetsHierarchiesDynamic'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { useRelatedDataSets } from 'api/assets/queries/useRelatedDataSets'
import { FormMode } from 'interfaces/formModes/formMode'
import { RelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/interfaces'
import { useScenario } from 'api/assets/queries/useScenario'
import { useAssetsDsrConfigDynamic } from 'api/assets/queries/useAssetsDsrConfigDynamic'
import { getRelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/utils'
import { MaybeNull } from 'interfaces'
import { useAssetsSummaryDynamic } from 'api/assets/queries/useAssetsSummaryDynamic'
import { RoutesManager } from 'utils/routing/routesManager'
import { useUpsertDsrConfiguration } from 'api/assets/mutations/useUpsertDsrConfiguration'
import { SelectedAsset } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/RightsOrchestrationTab'
import { DsrConfigParams } from 'utils/routing/routesV2/systems'
import { DSRConfigEdit } from 'pages/dataSystems/AssetMigration/ScenarioDetails/DSRConfigEdit'
import { DSRConfigCreate } from 'pages/dataSystems/AssetMigration/ScenarioDetails/DSRConfigCreate'
import { LookUpTab } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSQLTypes'

export const DSRConfigUpsertContainer = () => {
  const selectedAsset = React.useContext(SelectedAsset)
  const [relatedPathsAndAssets, setRelatedPathsAndAssets] = useState<MaybeNull<RelatedPathsAndAssets>>(null)
  const { assetId, scenarioId, formMode } = useParams<DsrConfigParams>()
  const navigate = useNavigate()
  const isEditMode = formMode === FormMode.EDIT

  const { data: relatedDataSets, isLoading: isRelatedDataSetsLoading } = useRelatedDataSets({
    params: {
      assetId: assetId!,
      isPersonal: true,
      lookupType: 0,
      depth: 5,
    },
  })

  const { data: identitySpaces, isLoading: isIdentitySpacesLoading } = useIdentitySpaces()

  const { data: scenarioData, isLoading: isScenarioLoading } = useScenario({
    params: {
      scenarioId,
    },
  })

  const { assetsDsrConfig, isLoading: isAssetsDsrConfigLoading } = useAssetsDsrConfigDynamic({
    assetIds: [[assetId], Object.keys(relatedPathsAndAssets || {})].flat(),
    scenarioId: scenarioId!,
    enabled: Boolean(relatedPathsAndAssets),
  })

  const { assetsHierarchies, isLoading: isAssetsHierarchiesLoading } = useAssetsHierarchiesDynamic({
    limit: 200,
    assetIds: [[assetId], Object.keys(relatedPathsAndAssets || {})].flat(),
    canonicalRightCode: scenarioData.scenario?.canonicalRight?.code || '',
    enabled: Boolean(relatedPathsAndAssets) && !!scenarioData.scenario?.canonicalRight?.code,
  })

  const { assetsSummary, isLoading: isAssetsSummaryLoading } = useAssetsSummaryDynamic({
    assetIds: [[assetId], Object.keys(relatedPathsAndAssets || {})].flat(),
    enabled: Boolean(relatedPathsAndAssets),
  })
  /**
   * We are building this for databases (CanonicalResourceTypeDTO.CANONICAL_RESOURCE_TYPE_DATABASE)
   */
  const dbUrl = RoutesManager.systems.dsrInvocation.scenarioDetails.getURL({
    assetId: assetsSummary[0]?.asset?.resource?.hierarchies?.CANONICAL_RESOURCE_TYPE_DATABASE.id as string,
    scenarioId: scenarioId as string,
    tab: LookUpTab.DIRECT,
    resourceTypeCode: selectedAsset.resource?.resourceType as string,
  })

  const { mutate: upsertDsr } = useUpsertDsrConfiguration({
    onSuccess: () => {
      showToast({ content: 'Saved configuration', type: 'success' })
      navigate(dbUrl)
    },
    onError: () => {
      showToast({ content: 'Unable to save configuration.', type: 'error' })
    },
  })

  const onSubmit = (formData: {
    createData: CreatePatchDSRConfigsRequestBodyDTO
    patchData: CreatePatchDSRConfigsRequestBodyDTO
  }) => {
    const dsrConfigs = []

    if (formData?.createData?.datasetDSRConfiguration?.length) dsrConfigs.push(formData.createData)
    if (formData?.patchData?.datasetDSRConfiguration?.length) dsrConfigs.push(formData.patchData)

    if (dsrConfigs.length) {
      dsrConfigs?.forEach?.(data => {
        const isCreate = data?.datasetDSRConfiguration?.some(config => !config.id)
        upsertDsr({
          params: {
            data,
            type: isCreate ? 'create' : 'patch',
          },
        })
      })
    }
  }

  const isReady =
    !isAssetsSummaryLoading &&
    !isAssetsHierarchiesLoading &&
    !isIdentitySpacesLoading &&
    !isRelatedDataSetsLoading &&
    !isScenarioLoading &&
    !isAssetsDsrConfigLoading &&
    Boolean(relatedPathsAndAssets)

  useEffect(() => {
    if (!isRelatedDataSetsLoading) {
      const relatedPathsAndAssets = getRelatedPathsAndAssets({ relatedDataSets, primaryDatasetId: assetId! })
      setRelatedPathsAndAssets(relatedPathsAndAssets)
    }
  }, [relatedDataSets, isRelatedDataSetsLoading, assetId])

  return isEditMode ? (
    <DSRConfigEdit
      isReady={isReady}
      identitySpaces={identitySpaces}
      assetsHierarchies={assetsHierarchies}
      assetsSummary={assetsSummary}
      relatedPathsAndAssets={relatedPathsAndAssets || {}}
      scenarioData={scenarioData}
      assetsDsrConfig={assetsDsrConfig}
      onSubmit={onSubmit}
    />
  ) : (
    <DSRConfigCreate
      isReady={isReady}
      identitySpaces={identitySpaces}
      assetsHierarchies={assetsHierarchies}
      assetsSummary={assetsSummary}
      relatedPathsAndAssets={relatedPathsAndAssets || {}}
      scenarioData={scenarioData}
      assetsDsrConfig={assetsDsrConfig}
      onSubmit={onSubmit}
    />
  )
}
