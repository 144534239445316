import * as Yup from 'yup'
import { SCRIPT_TYPE } from 'pages/assetManager/scenario/details/constants'

/**
 * @deprecated
 * @param multipleIdentitiesFlagEnabled
 */
export const getCustomSqlValidationSchema = (multipleIdentitiesFlagEnabled: boolean) => {
  if (multipleIdentitiesFlagEnabled) {
    return Yup.object().shape({
      name: Yup.string().required('Required'),
      identitySpaceCodes: Yup.array().of(
        Yup.string().test('', 'Must select an identity', function (value) {
          return typeof value === 'string' && value.trim().length > 0
        }),
      ),
      sqlQuery: Yup.string()
        .test('', 'Required', (value, ctx: any) => {
          const [{ value: formValue }] = ctx?.from
          if (formValue.scriptType === SCRIPT_TYPE.SINGLE) return Boolean(value)
          return true
        })
        .test('', 'Must include each identity', (value, ctx: any) => {
          const [{ value: formValue }] = ctx?.from
          if (formValue.scriptType === SCRIPT_TYPE.SINGLE) {
            return formValue.identitySpaceCodes.every((code: string) => value?.indexOf(`{{${code}}}`) !== -1)
          }
          return true
        }),
      sqlQueries: Yup.array().of(
        Yup.string()
          .test('', 'Required', (value, ctx: any) => {
            const [{ value: formValue }] = ctx?.from
            if (formValue.scriptType === SCRIPT_TYPE.MULTIPLE) return Boolean(value)
            return true
          })
          .test('', 'Must include identity', (value, ctx: any) => {
            const [{ value: formValue }] = ctx?.from
            if (formValue.scriptType === SCRIPT_TYPE.MULTIPLE) {
              return formValue.identitySpaceCodes.every((code: string) => value?.indexOf(`{{${code}}}`) !== -1)
            }
            return true
          }),
      ),
    })
  }

  return Yup.object().shape({
    name: Yup.string().required('Required'),
    identitySpaceCode: Yup.string()
      .required('Required')
      .test('', 'Must select an identity', function (value) {
        return typeof value === 'string' && value.trim().length > 0
      }),
    sqlQuery: Yup.string()
      .test('', 'Required', (value, ctx: any) => {
        const [{ value: formValue }] = ctx?.from
        if (formValue.scriptType === SCRIPT_TYPE.SINGLE) return Boolean(value)
        return true
      })
      .test('', 'Must include each identity', (value, ctx: any) => {
        const [{ value: formValue }] = ctx?.from
        if (formValue.scriptType === SCRIPT_TYPE.SINGLE) {
          return value?.indexOf(`{{${formValue?.identitySpaceCode}}}`) !== -1
        }

        return true
      }),
    sqlQueries: Yup.array().of(
      Yup.string()
        .test('', 'Required', (value, ctx: any) => {
          const [{ value: formValue }] = ctx?.from
          if (formValue.scriptType === SCRIPT_TYPE.MULTIPLE) return Boolean(value)
          return true
        })
        .test('', 'Must include identity', (value, ctx: any) => {
          const [{ value: formValue }] = ctx?.from
          if (formValue.scriptType === SCRIPT_TYPE.MULTIPLE) {
            return value?.indexOf(`{{${formValue?.identitySpaceCode}}}`) !== -1
          }
          return true
        }),
    ),
  })
}
