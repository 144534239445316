import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { isIdentityVerificationParamsTypeGuard } from 'pages/orchestration/workflows/edit/interfaces/typeGuards'
import { compact } from 'lodash'

const initialEmailBody = `Dear {{first_name}} {{last_name}},

Thank you for submitting your request. As required by law, we have sent you an email verification request to confirm your identity.

You must respond within {{expires_at}} or your request will expire.

Your Request ID is {{request_id}}, please keep this for your records.`

interface IParams {
  step: ICanvasStep
}

interface IGetIdentityVerificationInitialValues {
  body: string
  cc: Array<string>
  code: string
  description: string
  subject: string
  to: Array<string>
  identityVerificationTimeoutDays: string

  isCustomIDV?: boolean

  isAuthentication?: boolean
  authenticatorButtonText?: string
  authenticatorID?: string
  authenticatorType?: string

  isLexisNexis?: boolean
  lexisNexisButtonText?: string
  lexisNexisAppInstanceID?: string

  isStandard?: boolean
  isIdMe?: boolean
}

export const getIdentityVerificationFormInitialValues = (params: IParams): IGetIdentityVerificationInitialValues => {
  const { step } = params
  const emailSubject = 'Identity Verification'
  const emailBody = initialEmailBody

  if (isIdentityVerificationParamsTypeGuard(step.activity?.params)) {
    const authenticatorButtonText = step.activity?.params?.authenticatorButtonText || ''
    const authenticatorID = step.activity?.params?.authenticatorID || ''
    const authenticatorType = step.activity?.params?.authenticatorType || ''
    const body = step.activity?.params?.body || emailBody
    const cc = step.activity?.params?.cc || []
    const code = step.code || ''
    const description = step.description || ''
    const identityVerificationTimeoutDays = step.activity?.params?.identityVerificationTimeoutDays || ''
    const isCustomIDV = step.activity?.params?.isCustomIDV || false
    const lexisNexisAppInstanceID = step.activity?.params?.lexisNexisAppInstanceID || ''
    const lexisNexisButtonText = step.activity?.params?.lexisNexisButtonText || ''
    const subject = step.activity?.params?.subject || emailSubject
    /* these props are not part of BE contract, but help FE render form state */
    const isAuthentication = !!step.activity?.params?.authenticatorID || false // not in BE contract
    const isLexisNexis = !!step.activity?.params?.lexisNexisAppInstanceID || false // not in BE contract
    const isStandard = step.activity?.params?.isStandard === false ? false : true // not in BE contract
    const isIdMe = step.activity?.params?.isIdMe // not in BE contract

    return {
      authenticatorButtonText,
      authenticatorID,
      authenticatorType,
      body,
      cc: compact(cc),
      code,
      description,
      identityVerificationTimeoutDays,
      isAuthentication,
      isLexisNexis,
      isCustomIDV,
      lexisNexisAppInstanceID,
      lexisNexisButtonText,
      isStandard,
      isIdMe,
      subject,
      to: ['(Data Subject)'],
    }
  }

  return {
    authenticatorButtonText: '',
    authenticatorID: '',
    body: emailBody,
    cc: [],
    code: '',
    description: '',
    identityVerificationTimeoutDays: '',
    isAuthentication: false,
    isLexisNexis: false,
    isCustomIDV: false,
    isStandard: true,
    lexisNexisAppInstanceID: '',
    authenticatorType: '',
    lexisNexisButtonText: '',
    subject: emailSubject,
    to: ['(Data Subject)'],
  }
}
