import { DatabaseScenarioContainer } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/DatabaseScenario/DatabaseScenarioContainer'
import { DatasetScenarioContainer } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/DatasetScenario/DatasetScenarioContainer'
import React from 'react'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { SelectedAsset } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/RightsOrchestrationTab'
import { CanonicalResourceTypeDTO } from '@ketch-com/figurehead'
import { Logger } from 'utils/Logger'

export const ScenarioList = () => {
  const selectedAsset = React.useContext(SelectedAsset)
  if (selectedAsset.resource && !selectedAsset.resource.canonicalResourceType) {
    Logger.error('Asset API missing canonicalResourceType', selectedAsset.resource.id)
  }
  const isDatabaseResource =
    selectedAsset.resource?.canonicalResourceType ===
    CanonicalResourceTypeDTO.CanonicalResourceTypeDatabaseCanonicalResourceType

  return (
    <Box p={1.5} pt={0}>
      <Box mb={4}>
        <Typography variant="h3">DSR Configuration</Typography>
        <Typography variant="footnote">
          See how data assets are configured to support data subjects rights requests.
        </Typography>
      </Box>
      {isDatabaseResource ? <DatabaseScenarioContainer /> : <DatasetScenarioContainer />}
    </Box>
  )
}
