import { Chip } from '@ketch-com/deck'
import { Typography, Box, styled } from '@mui/material'
import { useIdentitySpaces } from 'api/identitySpaces/queries/useIdentitySpaces'
import { ExperiencePreviewVariant } from 'pages/consentAndRights/experiences-v2/view/components/experiencePreview/constants'
import { ExperiencePreview } from 'pages/consentAndRights/experiences-v2/view/components/experiencePreview/ExperiencePreview'
import { ConsentContextData } from 'pages/insights/auditLogs/hooks/useAuditLogConsentContextMockData'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { useQueryParams } from 'utils/hooks'

type ConsentContextDrawerContentProps = {
  consentTime: string
  mockData?: ConsentContextData
}

const DrawerContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

/**
 * Component for the consent context drawer content. Used only in the virgin Australia demo.
 * @param consentTime - Consent time
 * @param mockData - Mock data
 * @returns Consent context drawer content component
 */
export const ConsentContextDrawerContent: React.FC<ConsentContextDrawerContentProps> = ({ consentTime, mockData }) => {
  const { queries } = useQueryParams<{
    identifierSearch?: string
    identitySpaceCode?: string
    permitDateTime?: string
  }>()
  const { data: identitySpaces } = useIdentitySpaces()

  const identitySpaceName = identitySpaces?.find(space => space.code === queries.identitySpaceCode)?.name

  return (
    <DrawerContentBox>
      <Box display="flex" flexDirection="row" gap={1}>
        <Typography variant="h2" mb={1}>
          Historical Consent Details
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '8px', mb: '12px' }}>
        <Chip
          label={
            <>
              <Typography variant="label">{identitySpaceName}: </Typography>
              <Typography variant="body">{queries.identifierSearch}</Typography>
            </>
          }
          sx={{ width: 'fit-content' }}
        />
        <Chip
          label={
            consentTime === 'latest' ? (
              <Typography variant="label">Latest</Typography>
            ) : (
              <>
                <Typography variant="label">Date: </Typography>
                <Typography variant="body">
                  {formatDateTimeFromUnix(new Date(consentTime || '').getTime() / 1000, 'MMM DD yyyy, h:mm A')}
                </Typography>
              </>
            )
          }
          sx={{ width: 'fit-content' }}
        />
      </Box>
      <Typography mb={2}>Below are the configurations as of the time of this consent being generated.</Typography>
      <ExperiencePreview
        variant={ExperiencePreviewVariant.DirectConfig}
        previewConfig={mockData?.previewConfig}
        headerData={mockData?.headerData}
      />
    </DrawerContentBox>
  )
}
