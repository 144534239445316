import { PopUp, theme } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { showToast } from 'components/modals/AlertComponent'
import { AssigneeOrTeamSelector } from 'components/AssigneeSelector/AssigneeOrTeamSelector'
import { Logger } from 'utils/Logger'
import { BulkEditModalBaseProps } from '../../types'
import { useUpdateInstalledDataSystemAssets } from 'api/dataSystems/mutations/useUpdateInstalledDataSystemAssets'
import { useDataSystemsContext } from 'pages/dataSystems/DataSystem/context/DataSystemContext'
import pluralize from 'pluralize'
import { BulkEditMidalFooteerActions } from './components/BulkEditModalFooterActions'
import { BulkEditModalWarningCopy } from './components/BulkEditModalWarningCopy'

export const BulkEditDataOwnerModal: React.FC<BulkEditModalBaseProps> = ({
  assetIds,
  onClose,
  resourceType,
  refetchInstalledDatasystemAssets,
}) => {
  const { appDescriptor } = useDataSystemsContext()
  const [selectedOwnerUserId, setSelectedOwnerUserId] = useState<string | undefined>()
  const [confirmEdit, setConfirmEdit] = useState<boolean>(false)

  const { mutate: updateInstalledDataSystemAssets } = useUpdateInstalledDataSystemAssets({
    onSuccess: () => {
      refetchInstalledDatasystemAssets()
      showToast({
        content: 'Successfully updated data owner',
        type: 'success',
      })
      onClose()
    },
    onError: error => {
      Logger.error('Failed to update data owner', error)
      showToast({
        content: 'Failed to update data owner',
        type: 'error',
      })
    },
  })

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const handleEdit = useCallback(() => {
    if (selectedOwnerUserId) {
      updateInstalledDataSystemAssets({
        params: {
          installedDataSystemId: appDescriptor?.id!,
          body: {
            assetIDs: assetIds as string[],
            dataOwner: selectedOwnerUserId,
            resourceType,
          },
        },
      })
    }
  }, [appDescriptor?.id, assetIds, resourceType, selectedOwnerUserId, updateInstalledDataSystemAssets])

  const customPopupTitle = confirmEdit ? (
    <Box>
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <Typography variant="h3">Edit Data Owner</Typography>
        <Typography variant="h3" color={theme.palette.Text.Secondary}>
          in {assetIds.length}
        </Typography>
        <Typography variant="h3">{pluralize('System', assetIds.length)}</Typography>
      </Box>
    </Box>
  ) : (
    <Typography variant="h3">Edit Data Owner</Typography>
  )

  return (
    <PopUp
      variant="modal"
      popUpWidth="560px"
      onClose={handleClose}
      popUpCustomTitle={customPopupTitle}
      popUpActionChildren={
        <BulkEditMidalFooteerActions
          handleClose={handleClose}
          confirmEdit={confirmEdit}
          setConfirmEdit={setConfirmEdit}
          selectedValue={selectedOwnerUserId}
          handleSave={handleEdit}
        />
      }
    >
      {confirmEdit ? (
        <Box paddingY={1} paddingBottom={1}>
          <AssigneeOrTeamSelector
            size="medium"
            label="System Owner"
            selectedAssigneeId={selectedOwnerUserId}
            onChange={setSelectedOwnerUserId}
            hasAssignToMeButton
          />
        </Box>
      ) : (
        <BulkEditModalWarningCopy editablePropertyName="data owners" />
      )}
    </PopUp>
  )
}
