import { useCustomSqlUtils } from 'pages/assetManager/scenario/details/components/CustomSql/hooks'

/**
 * @deprecated
 */
export type CustomSqlUtils = ReturnType<typeof useCustomSqlUtils>

/**
 * @deprecated
 */
export interface CustomSqlFormData {
  identitySpaceCode: string
  identitySpaceCodes: string[]
  name: string
  sqlQuery?: string
  sqlQueries?: string[]
  scriptType?: string
  id: string
}

/**
 * @deprecated
 */
export enum CustomSqlModalType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

/**
 * @deprecated
 */
export interface CustomSqlModalData {
  mode: CustomSqlModalType
  customSqlId?: string
}
