import { Box, Typography } from '@mui/material'
import pluralize from 'pluralize'
import { GetAssetHierarchiesResponseBodyDTO, GetScenarioResponseBodyDTO, AssetSummaryDTO } from '@ketch-com/figurehead'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { RelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/interfaces'
import { getAssetType } from 'pages/assetManager/utils'
import { Table } from 'components/ui-layouts/table/Table'
import {
  RelatedPathsRadio,
  RelatedDatasetName,
  RelatedDatasetIdentity,
  RelatedDatasetPaths,
} from 'pages/assetManager/dsrIdentityLookup/components/RelatedDataSets/components'

type Props = {
  assetsHierarchies: GetAssetHierarchiesResponseBodyDTO[]
  relatedPathsAndAssets: RelatedPathsAndAssets
  identitySpaces: IdentitySpaceDTO[]
  scenarioData?: GetScenarioResponseBodyDTO
  assetsSummary: AssetSummaryDTO[]
}

/**
 * @deprecated
 * @param relatedPathsAndAssets
 * @param assetsSummary
 * @param assetsHierarchies
 * @param identitySpaces
 * @constructor
 */
export const RelatedDatasets: React.FC<Props> = ({
  relatedPathsAndAssets,
  assetsSummary = [],
  assetsHierarchies,
  identitySpaces = [],
}) => {
  const providerCode = assetsSummary?.[0]?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)
  const relatedTablesCount = Object.keys(relatedPathsAndAssets).length

  // assetsSummary[0] is primary asset summary
  const { name: datasetName = '' } = assetsSummary[0]?.asset?.resource || {}
  const relatedPathsOfAsset = Object.values(relatedPathsAndAssets)

  return (
    <Box
      pt={6}
      pb={4}
      mt={2}
      mb={4}
      borderTop="1px solid rgba(0, 0, 0, .125)"
      borderBottom="1px solid rgba(0, 0, 0, .125)"
    >
      <Box display="flex" flexDirection="column" gap={0.5} mb={3}>
        <Typography variant="h4">Related {pluralize(datasetType, relatedTablesCount)}</Typography>

        <Typography variant="body">
          If assigning an Identifier to "{datasetName}" is not an option, please choose one of the related{' '}
          {pluralize(datasetType.toLocaleLowerCase(), relatedTablesCount)} to proceed.
        </Typography>
      </Box>
      {relatedPathsOfAsset.length ? (
        <Box>
          <Table
            items={relatedPathsOfAsset}
            cellSettings={{
              radio: {
                width: 40,
                cellRenderer: relatedPaths => (
                  <RelatedPathsRadio relatedPaths={relatedPaths} assetsSummary={assetsSummary} />
                ),
              },
              name: {
                label: 'Name',
                width: 250,
                cellRenderer: relatedPaths => (
                  <RelatedDatasetName relatedPaths={relatedPaths} assetsSummary={assetsSummary} />
                ),
              },
              relationDetails: {
                labelNodeRenderer: () => <Box ml={3.25}>Relation Details</Box>,
                cellRenderer: relatedPaths => <RelatedDatasetPaths relatedPaths={relatedPaths} />,
              },
              identitySpace: {
                label: 'Identifier Mapping',
                cellRenderer: relatedPaths => (
                  <RelatedDatasetIdentity
                    relatedPaths={relatedPaths}
                    assetsHierarchies={assetsHierarchies}
                    identitySpaces={identitySpaces}
                  />
                ),
              },
            }}
          />
        </Box>
      ) : (
        <Box bgcolor="lighterGrey.main" borderRadius={3} padding={1.5}>
          <Typography variant="label">{`No related ${pluralize(
            datasetType,
            relatedTablesCount,
          ).toLowerCase()} found.`}</Typography>
        </Box>
      )}
    </Box>
  )
}
