import { Modal } from 'components/ui-kit/modal/Modal'
import { Formik } from 'formik'
import { Autocomplete, Box, InputAdornment, TextField, styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { ManualBadge, AutomatedBadge } from '../Badges'
import { BadgeDovetailVariant } from 'pages/dataSystems/Classification/components/BadgeDovetail/BadgeDovetail'
import { useDataCategoriesInfinite } from 'api/dataSystemsClassifications/queries/useDataCategoriesInfinite'
import { useParams } from 'react-router-dom'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useUpdateAssignedCategories } from 'api/dataSystemsClassifications/mutations/useUpdateAssignedCategories'
import { AssignedClassificationV3DTO, ConfirmationStatusV3DTO, LabelMethodV3DTO } from '@ketch-com/figurehead'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { useQueryClient } from 'react-query'
import { useAssignedDataCategoriesInfinite } from 'api/dataSystemsClassifications/queries/useAssignedDataCategoriesInfinite'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'

const ModalBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}))

const ClearAll = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: 'auto',
  color: theme.palette.sphere.main,
  cursor: 'pointer',
}))

const BadgesContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '.5rem',
}))

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const AssignedCategoriesEditModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { code } = useParams<{ code: string }>()

  const { data: dataCategories } = useDataCategoriesInfinite({
    limit: 9000,
  })

  const dataCategoriesRemap: AssignedClassificationV3DTO[] = dataCategories.map(dataCategory => ({
    classification: dataCategory,
    classificationId: dataCategory.id,
    resourceId: code,
  }))

  const { data: dataAssignedCategories } = useAssignedDataCategoriesInfinite({
    installedDataSystemId: code,
    limit: 2000,
  })

  const queryClient = useQueryClient()

  const { mutateAsync: handleUpdateAssignedCategories } = useUpdateAssignedCategories({
    onSuccess: async () => {
      await queryClient.refetchQueries([ApiQueryKeys.assignedDataCategories, { installedDataSystemId: code }])

      showToast({ content: 'Assigned classification updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update assigned classification', type: 'error' })
    },
  })

  const onSubmit = (values: { assignedCategories: AssignedClassificationV3DTO[] }) => {
    handleUpdateAssignedCategories({
      params: {
        installedDataSystemId: code,
        formData: { assignedClassifications: values.assignedCategories },
      },
    })

    onClose()
  }

  const handleConfirmation = (assignedCategoryId: string | undefined, confirmationStatus: ConfirmationStatusV3DTO) => {
    const assignedCategoriesRemap = dataAssignedCategories?.assignedClassifications?.map(assignedCategory => {
      if (assignedCategory.id === assignedCategoryId) {
        return {
          ...assignedCategory,
          confirmationStatus,
        }
      } else {
        return assignedCategory
      }
    })

    handleUpdateAssignedCategories({
      params: {
        installedDataSystemId: code,
        formData: { assignedClassifications: assignedCategoriesRemap },
      },
    })
  }

  const initialValues = {
    assignedCategories: dataAssignedCategories?.assignedClassifications ?? [],
  }

  const assignedClassificaitons = dataAssignedCategories?.assignedClassifications?.filter(
    assignedClassification =>
      assignedClassification.confirmationStatus !== ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
  )

  return (
    <Formik enableReinitialize validateOnMount initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <Modal
            title="Classifications"
            subtitle={`Classifications Assigned ${assignedClassificaitons?.length}`}
            isOpen={isOpen}
            onCloseButtonClick={() => onClose()}
            onCancelBtnClick={() => onClose()}
            contentWidth={1000}
            submitBtnVariant="primary"
            submitBtnLabel="Save"
            onSubmitBtnClick={handleSubmit}
          >
            <ModalBody>
              <Autocomplete
                options={dataCategoriesRemap}
                filterSelectedOptions
                multiple
                value={values.assignedCategories}
                isOptionEqualToValue={(option, value: AssignedClassificationV3DTO) =>
                  option?.classification?.id === value?.classification?.id
                }
                getOptionLabel={option => option?.classification?.name ?? ''}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: <></>,
                    }}
                  />
                )}
                onChange={(e, newValue) => setFieldValue('assignedCategories', newValue)}
              />

              <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                {!!values.assignedCategories?.length ? (
                  <>
                    <ClearAll onClick={() => setFieldValue('assignedCategories', [])}>Clear All</ClearAll>
                    <BadgesContainer>
                      {values.assignedCategories.map((assignedCategory: AssignedClassificationV3DTO) => {
                        if (
                          assignedCategory.confirmationStatus === ConfirmationStatusV3DTO.DismissedConfirmationStatusV3
                        ) {
                          return null
                        }

                        return (
                          <Box key={assignedCategory.id}>
                            {assignedCategory?.method &&
                            assignedCategory?.method !== LabelMethodV3DTO.ManualLabelMethodV3 ? (
                              <AutomatedBadge
                                name={assignedCategory?.classification?.name}
                                description={assignedCategory?.classification?.description}
                                confirmationStatus={assignedCategory.confirmationStatus}
                                confidence={assignedCategory?.confidence}
                                variant={BadgeDovetailVariant.HEAD}
                                handleConfirm={() =>
                                  handleConfirmation(
                                    assignedCategory?.id,
                                    ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
                                  )
                                }
                                handleDismiss={() =>
                                  handleConfirmation(
                                    assignedCategory?.id,
                                    ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
                                  )
                                }
                              />
                            ) : (
                              <ManualBadge
                                name={assignedCategory?.classification?.name}
                                description={assignedCategory?.classification?.description}
                                variant={BadgeDovetailVariant.HEAD}
                                handleClose={() => {
                                  setFieldValue(
                                    'assignedCategories',
                                    values.assignedCategories.filter(
                                      assignedCategorya =>
                                        assignedCategorya?.classification?.code !==
                                        assignedCategory?.classification?.code,
                                    ),
                                  )
                                }}
                              />
                            )}
                          </Box>
                        )
                      })}
                    </BadgesContainer>
                  </>
                ) : (
                  <EmptyState
                    title="No Classifications"
                    description="Use the search above to add classifictaions."
                    icon={<div></div>}
                    variant="page"
                  />
                )}
              </Box>
            </ModalBody>
          </Modal>
        )
      }}
    </Formik>
  )
}
