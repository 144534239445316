import { Typography } from '@mui/material'

export interface BulkEditModalWarningCopyProps {
  editablePropertyName: string
}

export const BulkEditModalWarningCopy: React.FC<BulkEditModalWarningCopyProps> = ({ editablePropertyName }) => {
  return (
    <Typography>
      {`This edit will replace all currently assigned ${editablePropertyName} for the selected systems. Proceed carefully.`}
    </Typography>
  )
}
