import { Box } from '@mui/material'
import { FormCheckbox } from 'components/ui-kit/form/checkbox/FormCheckbox'
import { FormSection } from 'components/ui-layouts/formLayout/FormSection'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { ErrorMessage } from 'formik'
import { MaybeNull } from 'interfaces'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { Text } from 'components/ui-kit/typography/Text'
import { getAssetType } from 'pages/assetManager/utils'

type Props = {
  assetAttributesSummary: MaybeNull<AssetSummaryDTO[]>
}

export const UserLookup: React.FC<Props> = ({ assetAttributesSummary }) => {
  const providerCode = assetAttributesSummary?.[0]?.asset?.connection?.providerCode || ''
  const attributeType = getAssetType(providerCode, 3).toLowerCase()
  return (
    <FormSection title="User Lookup" subTitle={`Review how Ketch will identify a data type. Resolve if any issues.`}>
      <Box mb={3}>
        <Box fontSize={16} fontWeight={700} display="block">
          Direct Lookup
        </Box>
        <Text fontSize={12} fontWeight={400}>
          This asset contains a directly mapped Identifier.
        </Text>
      </Box>
      <ErrorMessage name="identitySpace">
        {msg => (
          <Text fontWeight={400} fontSize={12} color="chileanFire">
            {msg}
          </Text>
        )}
      </ErrorMessage>
      <Box>
        {assetAttributesSummary &&
          assetAttributesSummary.map((attributeSummary, index) => {
            const assetId = attributeSummary.asset?.resource?.id
            const assetName = attributeSummary.asset?.resource?.name || ''
            const identitySpaceName = attributeSummary.asset?.identitySpace?.name
            const technology = attributeSummary.asset?.connection?.technology || ''
            return (
              <Box key={assetId} display="flex" alignItems="center" gap={2} mt={3}>
                <FormCheckbox name={`identitySpace.['${assetId}'].checked`} />

                <Box display="flex" gap={3} alignItems="center">
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Box display="flex" alignItems="center" gap={0.5}>
                      <img src={getAssetCloudImgUrl(technology)} alt={technology} width={15} height={15} />
                      <Text size={11} weight={500} sx={{ textTransform: 'capitalize' }}>
                        {attributeType}
                      </Text>
                    </Box>
                    <Text size={14} weight={600}>
                      {assetName}
                    </Text>
                  </Box>
                  <Text size={16} weight={700} color="grey">
                    =
                  </Text>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Text size={12} weight={600} color="grey">
                      Identity
                    </Text>
                    <Text size={14} weight={600}>
                      {identitySpaceName}
                    </Text>
                  </Box>
                </Box>
              </Box>
            )
          })}
      </Box>
    </FormSection>
  )
}
