import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCustomSqlsInfinite } from 'api/assets/queries/useCustomSqlsInfinite'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useUpsertCustomSql } from 'api/assets/mutations/useUpsertCustomSql'
import { useDeleteCustomSql } from 'api/assets/mutations/useDeleteCustomSql'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { UrlParams } from 'pages/assetManager/scenario/interfaces'
import { MaybeNull } from 'interfaces/common'
import {
  CustomSqlModalData,
  CustomSqlModalType,
} from 'pages/assetManager/scenario/details/components/CustomSql/interfaces'
import { useIdentitySpacesPaginated } from 'api/identitySpaces/queries/useIdentitySpacePaginated'

/**
 * @deprecated
 */
export const useCustomSqlUtils = () => {
  const [customSqlModalData, setCustomSqlModalData] = useState<MaybeNull<CustomSqlModalData>>(null)
  const { id: databaseResourceId, scenarioId } = useParams<UrlParams>()
  const queryClient = useQueryClient()

  const {
    customSqls,
    isLoading: isCustomSqlsLoading,
    isFetchingNextPage: isFetchingNextCustomSql,
    hasNextPage: hasNextCustomSql,
    fetchNextPage: fetchNextCustomSql,
  } = useCustomSqlsInfinite({
    databaseResourceId,
    scenarioId,
  })

  const { data: identitySpacesList, isLoading: isLoadingIdentitySpaces } = useIdentitySpacesPaginated({
    params: {
      limit: 300,
    },
  })

  const { mutateAsync: upsertCustomSql } = useUpsertCustomSql({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.customSql, { databaseResourceId, scenarioId }])
      const content =
        customSqlModalData?.mode === CustomSqlModalType.CREATE ? 'Created Custom SQL' : 'Updated Custom SQL'
      showToast({ content, type: 'success' })
      setCustomSqlModalData(null)
    },
    onError: () => {
      const content =
        customSqlModalData?.mode === CustomSqlModalType.CREATE
          ? 'Failed to create Custom SQL'
          : 'Failed to update Custom SQL'
      showToast({ content, type: 'error' })
    },
  })

  const { mutateAsync: deleteCustomSql } = useDeleteCustomSql({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.customSql, { databaseResourceId, scenarioId }])
      showToast({ content: 'Deleted Custom SQL', type: 'success' })
      setCustomSqlModalData(null)
    },
    onError: () => {
      showToast({ content: 'Failed to delete Custom SQL', type: 'error' })
    },
  })

  const isReady = !isCustomSqlsLoading && !isLoadingIdentitySpaces

  return {
    isReady,
    customSqls,
    upsertCustomSql,
    customSqlModalData,
    setCustomSqlModalData,
    deleteCustomSql,
    isFetchingNextCustomSql,
    hasNextCustomSql,
    fetchNextCustomSql,
    identitySpacesList,
  }
}
