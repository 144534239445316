import React from 'react'
import { EditSamplingModal, EditSamplingModalProps } from './AssetsTabEditSamplingModal'
import { AssetsTabRowEditModal, AssetsTabRowEditModalProps } from './AssetsTabRowEditModal'
import { AssetsTableActionModalType, EditDataOwnerModalProps } from '../../types'
import { EditDataOwnerModal } from '../../components/AssetTableEditDataOwnerModal'
import { AssetsTabEditSensitivityModal, AssetsTabEditSensitivityModalProps } from './AssetsTabEditSensitivityModal'

export interface AssetsTableActionModalProps {
  modalType: AssetsTableActionModalType
  modalProps: Record<string, any>
}

export const AssetsTableActionModal: React.FC<AssetsTableActionModalProps> = ({ modalType, modalProps }) => {
  if (modalType === AssetsTableActionModalType.EditSampling) {
    return <EditSamplingModal {...(modalProps as EditSamplingModalProps)} />
  }
  if (modalType === AssetsTableActionModalType.editDataOwner) {
    return <EditDataOwnerModal {...(modalProps as EditDataOwnerModalProps)} />
  }
  if (modalType === AssetsTableActionModalType.editSensitivity) {
    return <AssetsTabEditSensitivityModal {...(modalProps as AssetsTabEditSensitivityModalProps)} />
  }
  if (
    modalType === AssetsTableActionModalType.editDataCategory ||
    modalType === AssetsTableActionModalType.editDataType
  ) {
    return <AssetsTabRowEditModal {...(modalProps as AssetsTabRowEditModalProps)} />
  }
  return null
}
