import { useCustomSqlUtils } from 'pages/dataSystems/AssetMigration/ScenarioDetails/useCustomSqlUtils'

export type CustomSqlUtils = ReturnType<typeof useCustomSqlUtils>

export interface CustomSqlFormData {
  identitySpaceCode: string
  identitySpaceCodes: string[]
  name: string
  sqlQuery?: string
  sqlQueries?: string[]
  scriptType?: string
  id: string
}

export enum CustomSqlModalType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface CustomSqlModalData {
  mode: CustomSqlModalType
  customSqlId?: string
}

export enum LookUpTab {
  DIRECT = 'direct_lookup',
  NO_DIRECT = 'no_direct_lookup',
  CUSTOM_SQL = 'custom_sql',
}

export const SCRIPT_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
} as const

export const SCRIPT_TYPE_VALUES = {
  single: { key: 'single', label: 'Single Script' },
  multiple: { key: 'multiple', label: 'Multiple Scripts' },
}
