import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { RoutesManager } from 'utils/routing/routesManager'
import { useParams } from 'react-router-dom'
import {
  GetAssetHierarchiesResponseBodyDTO,
  GetScenarioResponseBodyDTO,
  AssetSummaryDTO,
  PutAssetRequestBodyDTO,
} from '@ketch-com/figurehead'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { RelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/interfaces'
import { AssetInfoNav } from 'pages/assetManager/dsrConfig/upsert/components'
import { getAssetType } from 'pages/assetManager/utils'
import { theme } from '@ketch-com/deck'
import { ContentBoundaries } from 'components/ui-layouts/contentBoundaries/ContentBoundaries'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import {
  getDsrIdentityLookupInitialValues,
  getDsrIdentityLookupFormValidation,
} from 'pages/assetManager/dsrIdentityLookup/utils'
import { PrimaryDataset } from 'pages/assetManager/dsrIdentityLookup/components/PrimaryDataset'
import { RelatedDatasets } from 'pages/assetManager/dsrIdentityLookup/components/RelatedDataSets/RelatedDatasets'
import { Button } from '@ketch-com/deck'
import { useUpdateAssetV2 } from 'api/assets/mutations/useUpdateAssetV2'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { LookUpTab } from 'pages/assetManager/scenario/details/constants'
import { getURLParams } from 'utils/routing/routes/utils'
import { FormMode } from 'interfaces/formModes/formMode'
import { useDsrConfigInitiator } from 'pages/assetManager/hooks'

type Props = {
  assetsHierarchies: GetAssetHierarchiesResponseBodyDTO[]
  relatedPathsAndAssets: RelatedPathsAndAssets
  identitySpaces: IdentitySpaceDTO[]
  scenarioData: GetScenarioResponseBodyDTO
  assetsSummary: AssetSummaryDTO[]
}

/**
 * @deprecated
 */
export const DSRIdentityLookup: React.FC<Props> = ({
  assetsHierarchies,
  scenarioData,
  relatedPathsAndAssets,
  identitySpaces,
  assetsSummary,
}) => {
  const {
    scenarioId,
    id: primaryDatasetId,
    formMode = '' as FormMode,
    resourceTypeCode = '',
    assetType,
  } = useParams<getURLParams & { formMode: FormMode }>()
  const { initiatorIsDatabase } = useDsrConfigInitiator()
  const navigate = useNavigate()
  const providerCode = assetsSummary?.[0]?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)
  const assetHierarchies = assetsHierarchies[0] // assetsHierarchies[0] is primary dataset
  const assetHierarchiesLength = assetHierarchies?.hierarchySummaries?.length || 0

  // assetsSummary[0] is primary asset summary
  const { name: databaseName = '', id: databaseId = '' } =
    assetsSummary[0]?.asset?.resource?.hierarchies?.CANONICAL_RESOURCE_TYPE_DATABASE || {}
  const { name: datasetName = '', id: datasetId = '' } = assetsSummary[0]?.asset?.resource || {}
  const technology = assetsSummary?.[0]?.asset?.connection?.technology || ''

  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.assetManager.assets.root.getURL() },
    { title: 'Assets', link: RoutesManager.assetManager.assets.list.getURL() },
    {
      title: databaseName,
      link: RoutesManager.assetManager.assets.detail.root.getURL({ code: databaseId, resourceTypeCode: assetType }),
    },
    { title: `Configure: ${scenarioData?.scenario?.name}` },
  ]

  const { mutate: allocateIdentity } = useUpdateAssetV2({
    onError: () => {
      showToast({ content: 'Failed to allocate Identifier', type: 'error' })
    },
    onSuccess: () => {
      navigate(
        RoutesManager.assetManager.assets.detail.dsrConfigUpsert.getURL({
          scenarioId,
          resourceTypeCode,
          id: primaryDatasetId,
          formMode,
          assetType,
        }),
      )
    },
  })

  return (
    <>
      <Box width={1280} margin="0 auto" mb={2.5} pb={2.5} borderBottom={`1px solid ${theme.palette.iron.main}`}>
        <AssetInfoNav
          database={{ name: databaseName, id: databaseId }}
          technology={technology}
          dataset={{
            name: datasetName,
            id: datasetId,
            assetsSummary: assetsSummary,
            attributeCount: assetHierarchiesLength,
          }}
        />
      </Box>
      <NavigationBreadCrumbs type="light" items={breadcrumbs.filter(b => !!b?.title)} />
      <ContentBoundaries>
        <Box
          sx={{
            backgroundColor: theme.palette.white.main,
            borderRadius: ({ palette, spacing }) => spacing(1.5),
            padding: ({ palette, spacing }) => spacing(4),
          }}
        >
          <Typography variant="h3">User Lookup</Typography>

          <Box
            sx={{
              backgroundColor: theme.palette.bleachWhite.main,
              borderRadius: ({ palette, spacing }) => spacing(1.5),
              padding: ({ palette, spacing }) => spacing(1, 3),
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: ({ palette, spacing }) => spacing(2.5),
              margin: ({ palette, spacing }) => spacing(4, 0),
            }}
          >
            <InfoIcon />
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography fontSize={14} fontWeight={600} color={theme.palette.chileanFire.main}>
                Can't Lookup an Identity.
              </Typography>
              <Typography fontSize={14} fontWeight={400} color={theme.palette.chileanFire.main}>
                There's no way to identify the user in this {datasetType.toLowerCase()} because no identity is assigned.
              </Typography>
            </Box>
          </Box>
          <Formik
            initialValues={getDsrIdentityLookupInitialValues({ datasetId: primaryDatasetId! })}
            validationSchema={getDsrIdentityLookupFormValidation()}
            onSubmit={values => {
              const { primaryAttributeId, primaryAttributeIdentitySpaceCode } = values
              const matchedIdentitySpace = identitySpaces?.find(
                identitySpace => primaryAttributeIdentitySpaceCode === identitySpace.code,
              )
              const formData = {
                assetSummary: {
                  asset: { identitySpace: { code: matchedIdentitySpace?.code, name: matchedIdentitySpace?.name } },
                },
              } as PutAssetRequestBodyDTO

              const attributeResourceTypeCode =
                assetsHierarchies?.[0]?.hierarchySummaries?.filter(
                  ({ asset }) => asset?.resource?.id === primaryAttributeId,
                )?.[0]?.asset?.resource?.resourceType?.code || ''
              allocateIdentity({
                params: {
                  assetCode: primaryAttributeId || '',
                  resourceTypeCode: attributeResourceTypeCode,
                  formData,
                },
              })
            }}
          >
            {({ isSubmitting, submitForm, values }) => {
              const { isPrimaryDataset, relatedDatasetHasIdentitySpace, datasetId } = values
              return (
                <>
                  <PrimaryDataset
                    assetsHierarchies={assetsHierarchies}
                    identitySpaces={identitySpaces}
                    assetsSummary={assetsSummary}
                  />

                  <RelatedDatasets
                    relatedPathsAndAssets={relatedPathsAndAssets}
                    assetsSummary={assetsSummary}
                    assetsHierarchies={assetsHierarchies}
                    identitySpaces={identitySpaces}
                  />

                  <Box display="flex" gap={2}>
                    <Button
                      color="secondary"
                      size="large"
                      pending={isSubmitting}
                      onClick={() => {
                        if (initiatorIsDatabase) {
                          navigate(
                            RoutesManager.assetManager.assets.detail.scenarioDetailsTab.getURL({
                              resourceTypeCode: assetType,
                              id: databaseId,
                              scenarioId,
                              tab: LookUpTab.NO_DIRECT,
                            }),
                          )
                        } else {
                          navigate(
                            RoutesManager.assetManager.assets.detail.dsrConfiguration.getURL({
                              code: primaryDatasetId,
                              resourceTypeCode: assetType,
                            }),
                          )
                        }
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      size="large"
                      pending={isSubmitting}
                      onClick={() => {
                        if (!isPrimaryDataset) {
                          if (relatedDatasetHasIdentitySpace)
                            navigate(
                              RoutesManager.assetManager.assets.detail.dsrConfigUpsert.getURL({
                                scenarioId,
                                resourceTypeCode,
                                id: datasetId,
                                formMode,
                                assetType,
                              }),
                            )
                          if (!relatedDatasetHasIdentitySpace)
                            navigate(
                              RoutesManager.assetManager.assets.detail.dsrConfigIdentity.getURL({
                                scenarioId,
                                resourceTypeCode,
                                id: datasetId,
                                formMode,
                                assetType,
                              }),
                            )
                        } else {
                          submitForm()
                        }
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                </>
              )
            }}
          </Formik>
        </Box>
      </ContentBoundaries>
    </>
  )
}
