import React, { useContext, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { Box } from '@mui/material'
import { PopUp, Button } from '@ketch-com/deck'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { MaybeNull } from 'interfaces'
import { useDebounce } from 'react-use'
import { useIdentitySpacesPaginated } from 'api/identitySpaces/queries/useIdentitySpacePaginated'
import { useSplitCustomSqls } from 'api/assets/mutations/useSplitCustomSqls'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { CustomSqlIdentitySpaceSelect } from './CustomSqlIdentitySpaceSelect'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'
import { useFeatureFlag } from 'utils/hooks'
import { CustomSqlContext } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSqlContext'
import { hasMultipleSqlStatement } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSQLUtils'
import { CustomSqlFormData, SCRIPT_TYPE } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSQLTypes'
import { CustomSqlUpsertQueryInput } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSqlUpsertQueryInput'
import { FormInput } from 'components/ui-kit/form/input/FormInput'

export const CustomSqlUpsertForm: React.FC = () => {
  const { values, errors, setFieldValue, setFieldTouched, submitForm, isValid } = useFormikContext<CustomSqlFormData>()

  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isMultipleIdentitiesFlagEnabled = !isFeatureFlagEnabled(
    FEATURE_FLAGS.IMPORT_CONTEXT,
    FEATURE_FLAGS_VALUES.IMPORT_CONTEXT_SINGLE_IDENTITY,
  )
  const initialSelectedIdentitySpaceState = isMultipleIdentitiesFlagEnabled ? [] : null
  const identitySpaceCodeFieldName = isMultipleIdentitiesFlagEnabled ? 'identitySpaceCodes' : 'identitySpaceCode'

  const [inputValue, setInputValue] = useState('')
  const [identitySpaceQuery, setIdentitySpaceQuery] = useState('')
  const [selectedIdentitySpace, setSelectedIdentitySpace] = useState<MaybeNull<IdentitySpaceDTO | IdentitySpaceDTO[]>>(
    initialSelectedIdentitySpaceState,
  )
  const { setCustomSqlModalData } = useContext(CustomSqlContext)

  const { mutateAsync: splitCustomSqls } = useSplitCustomSqls({
    onSuccess: () => {
      showToast({ content: 'Successfully split SQL string into multiple statements', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to split SQL', type: 'error' })
    },
  })

  const { data: identitySpaces, isLoading: isLoadingIdentitySpaces } = useIdentitySpacesPaginated({
    params: {
      query: identitySpaceQuery,
      limit: 300,
    },
    onSuccess: ({ data }) => {
      const sel = data?.identitySpaces?.filter(identitySpace =>
        isMultipleIdentitiesFlagEnabled
          ? values?.identitySpaceCodes.some((valCode: string) => identitySpace?.code === valCode)
          : identitySpace?.code === values?.identitySpaceCode,
      )

      if (isMultipleIdentitiesFlagEnabled && values?.identitySpaceCodes.length && !sel) {
        setFieldValue('identitySpaceCodes', '')
        setInputValue('')
        setIdentitySpaceQuery('')
      } else if (values?.identitySpaceCodes && !sel) {
        setFieldValue('identitySpaceCode', '')
        setInputValue('')
        setIdentitySpaceQuery('')
      }
      if (isMultipleIdentitiesFlagEnabled) {
        if (Array.isArray(selectedIdentitySpace) && !selectedIdentitySpace?.length && sel) setSelectedIdentitySpace(sel)
      } else {
        if (!selectedIdentitySpace && sel) setSelectedIdentitySpace(sel)
      }
    },
  })
  useDebounce(
    () => {
      if (inputValue) {
        setIdentitySpaceQuery(inputValue)
      } else {
        setIdentitySpaceQuery('')
      }
    },
    500,
    [inputValue],
  )

  useEffect(() => {
    const isMultipleSqlStatement = hasMultipleSqlStatement(values.sqlQuery)

    const handleMultipleQueries = async () => {
      const { data } = await splitCustomSqls({
        params: {
          sqlScript: values?.sqlQuery || '',
        },
      })
      const queries = data?.sqlQueries?.filter(query => query)

      if (queries?.length) {
        setFieldValue('sqlQuery', '')
        setFieldValue('scriptType', SCRIPT_TYPE.MULTIPLE)
        setFieldValue('sqlQueries', queries)
      }
    }

    if (isMultipleSqlStatement && !errors?.sqlQuery) {
      handleMultipleQueries()
    }
  }, [values.sqlQuery, errors?.sqlQuery, setFieldValue, splitCustomSqls])

  useEffect(() => {
    if (values?.scriptType === SCRIPT_TYPE.MULTIPLE)
      values?.sqlQueries?.forEach((_: any, index: number) => {
        setFieldTouched(`sqlQueries[${index}]`, true)
      })

    if (values?.scriptType === SCRIPT_TYPE.SINGLE) setFieldTouched('sqlQuery', true)
  }, [values?.sqlQueries, values?.scriptType, values?.[identitySpaceCodeFieldName], setFieldTouched])

  return (
    <PopUp
      isLoading={isLoadingIdentitySpaces}
      variant="modal"
      title={values?.id ? 'Update Custom SQL' : 'Add Custom SQL'}
      popUpWidth="900px"
      showHeaderDivide
      showFooterDivider
      onClose={() => setCustomSqlModalData(null)}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={() => setCustomSqlModalData(null)}>
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              submitForm()
            }}
            disabled={!isValid}
          >
            {values?.id ? 'Update' : 'Add'}
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <FormInput required name="name" fullWidth label="Name" placeholder="Example: My Script for PII Data" />
        <CustomSqlIdentitySpaceSelect
          setInputValue={setInputValue}
          selectedIdentitySpace={selectedIdentitySpace}
          setSelectedIdentitySpace={setSelectedIdentitySpace}
          identitySpaces={identitySpaces}
          isLoadingIdentitySpaces={isLoadingIdentitySpaces}
        />
        <CustomSqlUpsertQueryInput />
      </Box>
    </PopUp>
  )
}
