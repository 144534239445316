import React from 'react'

import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  isPending: boolean
  onSubmit: () => void
  onCancel: () => void
}

export const ConfirmRetrySendEmailModal: React.FC<Props> = ({ isPending, onSubmit, onCancel }) => {
  return (
    <PopUp
      title="Warning"
      popUpWidth="550px"
      variant="dialog"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" size="large" onClick={onSubmit} pending={isPending}>
            Continue
          </Button>
        </>
      }
    >
      <Typography>
        By proceeding with this action you will be resending the email. Are you sure you would like to continue?
      </Typography>
    </PopUp>
  )
}
