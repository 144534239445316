import { PopUp, theme } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import { useUpdateInstalledDataSystemAssets } from 'api/dataSystems/mutations/useUpdateInstalledDataSystemAssets'
import { showToast } from 'components/modals/AlertComponent'
import { useDataSystemsContext } from 'pages/dataSystems/DataSystem/context/DataSystemContext'
import { useCallback, useState } from 'react'
import { BulkEditMidalFooteerActions } from './components/BulkEditModalFooterActions'
import { BulkEditModalWarningCopy } from './components/BulkEditModalWarningCopy'
import { Logger } from 'utils/Logger'
import { ConfirmationStatusV3DTO, DataTypeDTO } from '@ketch-com/figurehead'
import { BulkEditClassificationDropList } from './components/BulkEditModalClassificationDropLists'
import pluralize from 'pluralize'
import { BulkEditModalDirectionIconBlock } from './components/BulkEditModalDirectionIconBlock'
import { BulkEditModalBaseProps } from '../../types'

export const BulkEditDataTypesModal: React.FC<BulkEditModalBaseProps> = ({
  assetIds,
  resourceType,
  refetchInstalledDatasystemAssets,
  onClose,
}) => {
  const { appDescriptor, allDataTypes } = useDataSystemsContext()
  const [confirmEdit, setConfirmEdit] = useState<boolean>(false)
  const [unassignedTypes, setUnassignedTypes] = useState<DataTypeDTO[]>(allDataTypes?.dataTypes || [])
  const [assignedTypes, setAssignedTypes] = useState<DataTypeDTO[]>([])

  const { mutate: updateInstalledDataSystemAssets } = useUpdateInstalledDataSystemAssets({
    onSuccess: () => {
      refetchInstalledDatasystemAssets()
      showToast({
        content: 'Successfully updated data types',
        type: 'success',
      })
      onClose()
    },
    onError: error => {
      Logger.error('Failed to update data types', error)
      showToast({
        content: 'Failed to update data types',
        type: 'error',
      })
    },
  })

  const handleDataTypeAssignment = useCallback((dataType: DataTypeDTO, assigning?: boolean) => {
    if (assigning) {
      setAssignedTypes(prev => [...prev, dataType].sort((a, b) => a.name!.localeCompare(b.name!)))
      setUnassignedTypes(prev => prev.filter(c => c.id !== dataType.id))
    } else {
      setUnassignedTypes(prev => [...prev, dataType].sort((a, b) => a.name!.localeCompare(b.name!)))
      setAssignedTypes(prev => prev.filter(c => c.id !== dataType.id))
    }
  }, [])

  const handleSave = useCallback(() => {
    if (assignedTypes.length > 0) {
      const newTypes = assignedTypes.map(dataType => ({
        ...dataType,
        confirmationStatus: ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
      }))
      updateInstalledDataSystemAssets({
        params: {
          installedDataSystemId: appDescriptor?.id!,
          body: {
            assetIDs: assetIds as string[],
            dataTypes: newTypes,
            resourceType,
          },
        },
      })
    }
  }, [appDescriptor?.id, assetIds, assignedTypes, resourceType, updateInstalledDataSystemAssets])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const customPopupTitle = confirmEdit ? (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="h3">Edit Data Types</Typography>
        <Typography variant="h3" color={theme.palette.Text.Secondary}>
          in {assetIds.length}
        </Typography>
        <Typography variant="h3">{pluralize('System', assetIds.length)}</Typography>
      </Box>
      <Typography variant="body" color={theme.palette.Text.Secondary}>
        Search for labels to replace those currently assigned to the selected systems.
      </Typography>
    </Box>
  ) : (
    <Typography variant="h3">Edit Data Types</Typography>
  )

  return (
    <PopUp
      variant="modal"
      popUpWidth={confirmEdit ? '1024px' : '560px'}
      popUpCustomTitle={customPopupTitle}
      onClose={handleClose}
      sx={{
        minWidth: confirmEdit ? '1024px' : '560px',
        '& .MuiDialogContent-root': {
          marginX: '15px',
        },
      }}
      popUpActionChildren={
        <BulkEditMidalFooteerActions
          handleClose={handleClose}
          confirmEdit={confirmEdit}
          setConfirmEdit={setConfirmEdit}
          selectedValue={assignedTypes}
          handleSave={handleSave}
          useApplyCopy
        />
      }
    >
      {confirmEdit ? (
        <Box display="flex" gap={2}>
          <Box display={'flex'} flexDirection="column" gap={1}>
            <Typography variant="label">All Data Categories</Typography>
            <BulkEditClassificationDropList
              assigning
              placeholder="Search data categories and click to add"
              options={unassignedTypes}
              handleClassificationAssignment={handleDataTypeAssignment}
            />
          </Box>
          <BulkEditModalDirectionIconBlock />
          <Box display={'flex'} flexDirection="column" gap={1}>
            <Typography variant="label">Selected Data Categories</Typography>
            <BulkEditClassificationDropList
              placeholder="Search data categories and click to remove"
              options={assignedTypes}
              handleClassificationAssignment={handleDataTypeAssignment}
            />
          </Box>
        </Box>
      ) : (
        <BulkEditModalWarningCopy editablePropertyName="data types" />
      )}
    </PopUp>
  )
}
