import { DmlHookDTO, AssetPurposeDTO } from '@ketch-com/figurehead'
import { ClassificationRefreshScheduleDay } from '@ketch-com/windlass/dist/appliance/databases'
import { GridRowId } from '@mui/x-data-grid-premium'
import { ManageLabelModalLabel } from 'interfaces/labels/ManageLabelModalLabel'

export interface ClassificationScheduleFormValues {
  time?: string
  frequency?: string
  day?: ClassificationRefreshScheduleDay
  period?: string
  executionDuration?: string
  sampleSize?: string
}

export interface EditClassificationScheduleFormValues {
  schedule: ClassificationScheduleFormValues
  name: string
  description: string
  dataCategory: string
  labels: ManageLabelModalLabel[]
  inferredLabels: ManageLabelModalLabel[]
  dataOwner: string
  hooks: DmlHookDTO[]
  purposes: AssetPurposeDTO[]
  identitySpace?: string
}

export enum BulkEditModalType {
  dataSensitivity = 'dataSensitivity',
  dataCategories = 'dataCategories',
  dataTypes = 'dataTypes',
  dataOwner = 'dataOwner',
}

export interface BulkEditModalBaseProps {
  assetIds: GridRowId[]
  resourceType: string
  refetchInstalledDatasystemAssets: (option?: any) => Promise<any>
  onClose: () => void
}
