import React, { useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { Box } from '@mui/material'
import { VerifierUpsertModal } from 'pages/developers/verifiers/components'
import { FindVerifiersResponseBodyDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'
import { Pagination } from 'api/common/paginatedQuery'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { ContentGroup, DataGrid, EmptyState, TooltipButton } from '@ketch-com/deck'
import { useVerifiersListColumns } from './hooks/useVerifiersListUtils'
import { SearchTextInput } from 'components/searchTextInput/SearchTextInput'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'

type Props = {
  verifiersData: MaybeNull<FindVerifiersResponseBodyDTO>
  isLoadingVerifiers: boolean
  pagination: Pagination
  setSearchQueryValue: React.Dispatch<React.SetStateAction<string>>
  searchQueryValue: string
}

export const Verifiers: React.FC<Props> = ({
  verifiersData,
  isLoadingVerifiers,
  pagination,
  searchQueryValue,
  setSearchQueryValue,
}) => {
  const [searchString, setSearchString] = useState('')
  const [isVerifierUpsertModalOpen, setIsVerifierUpsertModalOpen] = useState(false)
  const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()
  const isPermittedToCreateVerifiers = isPermitted([PERMISSIONS.AUTHENTICATION_WRITE])
  const isEntitledToCreateVerifiers = isEntitled(ENTITLEMENTS.VERIFIED_IDENTITIES)
  const { verifiers: verifierList = [] } = verifiersData || {}

  useDebounce(
    () => {
      if (searchString) {
        setSearchQueryValue(searchString)
      } else {
        setSearchQueryValue('')
      }
    },
    500,
    [searchString],
  )

  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  const columns = useVerifiersListColumns()

  const breadcrumbs = [
    { title: 'Developers', link: RoutesManager.developers.root.getURL() },
    { title: 'Token Verifiers', link: RoutesManager.developers.verifiers.list.getURL() },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box flexDirection="column" display="flex" gap={3}>
        <ContentGroup
          isDivider
          title="Token Verifiers"
          actionRightBlockComponent={
            <TooltipButton
              disabled={!isPermittedToCreateVerifiers || isLoadingVerifiers}
              title={
                !isPermittedToCreateVerifiers || !isEntitledToCreateVerifiers
                  ? 'You do not have sufficient permissions to perform this action, please contact your admin.'
                  : undefined
              }
              onClick={() => setIsVerifierUpsertModalOpen(true)}
            >
              Create Token Verifier
            </TooltipButton>
          }
          actionBottomBlockComponent={
            <Box display="flex" alignItems="center">
              <SearchTextInput
                showClearButton
                onChange={nextSearchString => setSearchString(nextSearchString)}
                value={searchString}
              />
            </Box>
          }
        />

        {!isLoadingVerifiers && !verifierList?.length && !searchQueryValue ? (
          <EmptyState
            title="There are no token verifiers for this organization"
            subTitle={isPermittedToCreateVerifiers ? 'Create your first one to populate the table.' : undefined}
            primaryButtonTitle="Create Token Verifier"
            primaryButtonTooltipTitle={
              isPermittedToCreateVerifiers && isEntitledToCreateVerifiers
                ? undefined
                : 'You do not have sufficient permissions to perform this action, please contact your admin.'
            }
            primaryButtonProps={{
              size: 'large',
              disabled: !isPermittedToCreateVerifiers || !isEntitledToCreateVerifiers || isLoadingVerifiers,

              onClick: () => setIsVerifierUpsertModalOpen(true),
            }}
            iconName="OProfile"
          />
        ) : (
          <DataGrid
            sx={{
              ...(isLoadingVerifiers && {
                '& .MuiDataGrid-virtualScroller': {
                  height: '300px',
                },
              }),
            }}
            autosizeOnMount
            autosizeOptions={{
              expand: true,
              includeHeaders: true,
              includeOutliers: false,
            }}
            getRowHeight={() => 'auto'}
            columns={columns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnPinning
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            disableRowHoverStates
            getRowId={row => row.id}
            rows={verifierList || []}
            loading={isLoadingVerifiers}
            noRowsOverlayProps={{
              buttonTitle: '',
            }}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            pageSizeOptions={[rowsPerPage]}
            rowCount={pagination.totalResults}
            hideFooter={pagination.totalPages < 2}
          />
        )}
      </Box>
      {isVerifierUpsertModalOpen && (
        <VerifierUpsertModal
          onCancel={() => setIsVerifierUpsertModalOpen(false)}
          onSubmit={() => setIsVerifierUpsertModalOpen(false)}
        />
      )}
    </>
  )
}
