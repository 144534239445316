import {
  Avatar,
  AvatarSize,
  Chip,
  CustomIconName,
  Icon,
  LabelChipColor,
  LabelChipVariant,
  TableCell,
  theme,
} from '@ketch-com/deck'
import { AssetClassificationTypeDTO, ConfirmationStatusV3DTO, ListAssetItemDTO } from '@ketch-com/figurehead'
import { Box, Tooltip, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { CalssificationExpansionDropdown, ClassificationIdString } from '../components/ClassificationExpansionDropdown'
import { FilterContextProps } from '../context/AssetsTabFilterContext'
import { getResourceType } from './getResourceType'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { AssetsTabTableRowEditMenu } from '../components/AssetsTabTableRowEditMenu'
import { AssetsTabTableLabelChip } from '../components/AssetsTabTableLabelChip'
import { canonicalResourceTypeMap, columnFiltersByResourceType } from '../constants'
import { sortAlphabetically } from './sortAlphabetically'
import { FREQUENCY_OPTIONS } from 'pages/assetManager/databases/upsert/components/EditAutoDiscoveryFormSection/constants'
import { formatTo12HourTime } from './timeFormatter'
import { DataSystemsContextValue } from '../../context/DataSystemContext'

export interface DataSystemAssetsColumnsProps {
  resourceType?: string
  setAssetCategory: FilterContextProps['setAssetCategory']
  setCurrentResourceLevel: React.Dispatch<React.SetStateAction<string | undefined>>
  setParentResourceID: FilterContextProps['setParentResourceID']
  snapshotCurrentQueryDetails: FilterContextProps['snapshotCurrentQueryDetails']
  handleManualClassificationVerification: (
    classification: AssetClassificationTypeDTO,
    id: string,
    status: ConfirmationStatusV3DTO,
  ) => void
  setShowEditModal: FilterContextProps['setShowEditModal']
  allUserOptions: DataSystemsContextValue['allUserOptions']
  allTeamOptions: DataSystemsContextValue['allTeamOptions']
  setPage: (page: number) => void
}

export const dataSystemAssetsColumns = ({
  resourceType,
  setAssetCategory,
  setCurrentResourceLevel,
  setParentResourceID,
  snapshotCurrentQueryDetails,
  handleManualClassificationVerification,
  setShowEditModal,
  allUserOptions,
  allTeamOptions,
  setPage,
}: DataSystemAssetsColumnsProps): GridColDef<ListAssetItemDTO>[] => {
  const unfilteredColumns: GridColDef<ListAssetItemDTO>[] = [
    {
      align: 'left',
      field: 'assetName',
      minWidth: 160,
      maxWidth: 260,
      flex: 1,
      headerAlign: 'left',
      headerName: 'Asset Name',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<ListAssetItemDTO>) => {
        const resourceName = row.resource?.name?.toLocaleUpperCase() || ''
        const resourceTypeName = getResourceType(row.resource?.resourceType, true)
        const iconName = row.resource?.canonicalResourceType
          ? canonicalResourceTypeMap[row.resource.canonicalResourceType]
          : ''
        const hasChildren = row.children && row.children.length > 0
        const childResourceType = hasChildren ? getResourceType(row.children?.[0].resourceType) : ''
        const handleDrillDown = () => {
          if (hasChildren && row.children?.[0].resourceType) {
            snapshotCurrentQueryDetails(
              resourceName,
              resourceTypeName,
              iconName,
              row.children[0].count,
              childResourceType,
            )
            setAssetCategory(row.resource?.resourceType)
            setCurrentResourceLevel(row.children[0].resourceType)
            setParentResourceID(row.resource?.id)
            setPage(1)
          }
        }
        return (
          <TableCell
            onClick={handleDrillDown}
            sx={{
              maxWidth: '900px',
              wordBreak: 'normal',
              cursor: hasChildren ? 'pointer' : 'auto',
            }}
          >
            <Tooltip
              placement="bottom"
              title={
                <Box display="flex" flexDirection="column" sx={{ backgroundColor: theme.palette.Black.o100 }} gap={2}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="label" color={theme.palette.Text.PrimaryLight}>
                      {resourceName}
                    </Typography>
                    <Typography variant="smallBody" color={theme.palette.Text.SecondaryLight}>
                      Asset Name
                    </Typography>
                  </Box>
                  <Box>
                    <Box display="flex" gap={1} alignItems="center">
                      <Icon name="OConnectionTrue" iconColor={theme.palette.Success.PrimaryDark} />
                      <Typography variant="smallLabel" color={theme.palette.Text.PrimaryLight}>
                        {row.resource?.connection?.connectionCode}
                      </Typography>
                    </Box>
                    <Typography variant="smallBody" color={theme.palette.Text.SecondaryLight}>
                      Connection
                    </Typography>
                  </Box>
                  {row.parentResources && (
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Typography variant="label" color={theme.palette.Text.PrimaryLight}>
                        Lineage:
                      </Typography>
                      <Box display="flex" flexDirection="column" gap={2}>
                        {row.parentResources?.map((parentResource, index) => (
                          <Box key={parentResource.id} display="flex" flexDirection="column">
                            <Icon
                              sx={{ marginBottom: '4px' }}
                              name={canonicalResourceTypeMap[parentResource.canonicalResourceType!] as CustomIconName}
                              iconColor={theme.palette.Common.AppBackground}
                            />
                            <Typography variant="smallLabel" color={theme.palette.Text.PrimaryLight}>
                              {parentResource.name?.toLocaleUpperCase()}
                            </Typography>
                            <Typography variant="smallBody" color={theme.palette.Text.SecondaryLight}>
                              {getResourceType(parentResource.resourceType)}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              }
            >
              <Box display="flex" alignItems="center" gap={1} width="100%">
                <Avatar size={AvatarSize.medium} icon={iconName as CustomIconName} variant="circular" />
                <Box display="flex" flexDirection="column" width={`calc(100% - 28px)`} flexShrink={1}>
                  <Typography
                    component="div"
                    variant="label"
                    sx={{
                      overflow: 'hidden',
                      'white-space': 'nowrap',
                      'text-overflow': 'ellipsis',
                      width: '100%',
                    }}
                  >
                    {resourceName}
                  </Typography>
                  <Box>
                    <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
                      {resourceTypeName}
                    </Typography>
                    {row.children?.[0].resourceType && row.children?.[0].count !== undefined && (
                      <Typography variant="footnote">{` | ${row.children?.[0].count} ${childResourceType}`}</Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Tooltip>
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'dataSensitivity',
      minWidth: 180,
      maxWidth: 180,
      headerAlign: 'left',
      headerName: 'Data Sensitivity',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<ListAssetItemDTO>) => {
        const handleDataSensitivityVerification = (id: string, status: ConfirmationStatusV3DTO) =>
          handleManualClassificationVerification(
            AssetClassificationTypeDTO.AssetClassificationTypeDataSensitivityAssetClassificationType,
            id,
            status,
          )

        return (
          <TableCell
            sx={{
              wordBreak: 'normal',
            }}
          >
            {row.dataSensitivities && row.dataSensitivities.length ? (
              sortAlphabetically(row.dataSensitivities, 'code')
                .filter(
                  sensitivity =>
                    sensitivity.confirmationStatus !== ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
                )
                .map(dataSensitivity => (
                  <AssetsTabTableLabelChip
                    PopperProps={{
                      disablePortal: false,
                    }}
                    key={dataSensitivity.id}
                    title={dataSensitivity.name!}
                    variant={LabelChipVariant.sensitivity}
                    color={LabelChipColor.confident}
                    hideConfidence
                    isDone={
                      dataSensitivity.confirmationStatus === ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3
                    }
                    onConfirm={() =>
                      handleDataSensitivityVerification(
                        dataSensitivity.assetDataSensitivityId,
                        ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
                      )
                    }
                    onDismiss={() =>
                      handleDataSensitivityVerification(
                        dataSensitivity.assetDataSensitivityId,
                        ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
                      )
                    }
                    viewInsightsProps={{
                      classificationName: dataSensitivity.name!,
                      classificationCode: dataSensitivity.code!,
                      classificationId: dataSensitivity.assetDataSensitivityId,
                      classificationDescription: dataSensitivity.description,
                      classificationFilterKey: 'dataSensitivityCodes',
                    }}
                  />
                ))
            ) : (
              <EmptyValueRenderer />
            )}
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'dataCategories',
      flex: 2,
      minWidth: 250,
      maxWidth: 500,
      headerAlign: 'left',
      headerName: 'Data Categories',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<ListAssetItemDTO>) => {
        let displayItems
        let dropDownListItems
        if (row.dataCategories) {
          const sortedDisplayItems = sortAlphabetically(row.dataCategories, 'name').filter(
            category => category.confirmationStatus !== ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
          )
          if (row.dataCategories.length <= 3) {
            displayItems = sortedDisplayItems
          } else {
            displayItems = sortedDisplayItems.slice(0, 3)
            dropDownListItems = sortedDisplayItems.slice(3)
          }
        }
        const handleDataCategoryVerification = (id: string, status: ConfirmationStatusV3DTO) =>
          handleManualClassificationVerification(
            AssetClassificationTypeDTO.AssetClassificationTypeDataCategoryAssetClassificationType,
            id,
            status,
          )
        return (
          <TableCell display="flex" flexWrap="wrap" gap={1}>
            {displayItems ? (
              <>
                {displayItems.map(category => (
                  <AssetsTabTableLabelChip
                    PopperProps={{
                      disablePortal: false,
                    }}
                    key={category.id}
                    title={category.name!}
                    variant={LabelChipVariant.category}
                    color={LabelChipColor.confident}
                    hideConfidence
                    isDone={category.confirmationStatus === ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3}
                    onConfirm={() =>
                      handleDataCategoryVerification(
                        category.assetDataCategoryId,
                        ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
                      )
                    }
                    onDismiss={() =>
                      handleDataCategoryVerification(
                        category.assetDataCategoryId,
                        ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
                      )
                    }
                    viewInsightsProps={{
                      classificationName: category.name!,
                      classificationCode: category.code!,
                      classificationId: category.assetDataCategoryId,
                      classificationDescription: category.description,
                      classificationFilterKey: 'dataCategoryCodes',
                    }}
                  />
                ))}
                {dropDownListItems && (
                  <CalssificationExpansionDropdown
                    label={`+${dropDownListItems.length} more`}
                    items={dropDownListItems}
                    labelChipVariant={LabelChipVariant.category}
                    handleManualClassificationVerification={handleDataCategoryVerification}
                    idKeyValue={ClassificationIdString.assetDataCategoryId}
                    viewInsightsProps={{
                      classificationFilterKey: 'dataCategoryCodes',
                    }}
                  />
                )}
              </>
            ) : (
              <EmptyValueRenderer />
            )}
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'dataType',
      flex: 2,
      minWidth: 150,
      maxWidth: 400,
      headerAlign: 'left',
      headerName: 'Data Type',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<ListAssetItemDTO>) => {
        const canConfirmDataTypes =
          row.resource?.canonicalResourceType === 'CANONICAL_RESOURCE_TYPE_FILE' ||
          row.resource?.canonicalResourceType === 'CANONICAL_RESOURCE_TYPE_ATTRIBUTE'
        let displayItems
        let dropDownListItems
        if (row.dataTypes) {
          const sortedDisplayItems = sortAlphabetically(row.dataTypes, 'name').filter(
            type => type.confirmationStatus !== ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
          )
          if (row.dataTypes.length <= 3) {
            displayItems = sortedDisplayItems
          } else {
            displayItems = sortedDisplayItems.slice(0, 3)
            dropDownListItems = sortedDisplayItems.slice(3)
          }
        }
        const handleDataTypeVerification = (id: string, status: ConfirmationStatusV3DTO) =>
          handleManualClassificationVerification(
            AssetClassificationTypeDTO.AssetClassificationTypeDataTypeAssetClassificationType,
            id,
            status,
          )

        return (
          <TableCell display="flex" flexWrap="wrap" gap={1}>
            {displayItems ? (
              <>
                {displayItems.map(type => (
                  <AssetsTabTableLabelChip
                    PopperProps={{
                      disablePortal: false,
                    }}
                    key={type.id}
                    title={type.name!}
                    hideConfidence
                    customTooltipActions={
                      canConfirmDataTypes ? undefined : (
                        <Box>
                          <Typography variant="body" color={theme.palette.Text.PrimaryLight}>
                            The presence of this data type is determined at the column level
                          </Typography>
                        </Box>
                      )
                    }
                    variant={LabelChipVariant.classification}
                    color={LabelChipColor.confident}
                    isDone={type.confirmationStatus === ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3}
                    onConfirm={() =>
                      handleDataTypeVerification(
                        type.assetDataTypeId,
                        ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
                      )
                    }
                    onDismiss={() =>
                      handleDataTypeVerification(
                        type.assetDataTypeId,
                        ConfirmationStatusV3DTO.DismissedConfirmationStatusV3,
                      )
                    }
                    viewInsightsProps={{
                      classificationName: type.name!,
                      classificationCode: type.code!,
                      classificationId: type.assetDataTypeId,
                      classificationDescription: type.description,
                      classificationFilterKey: 'dataTypeCodes',
                    }}
                  />
                ))}
                {dropDownListItems && (
                  <CalssificationExpansionDropdown
                    label={`+${dropDownListItems.length} more`}
                    items={dropDownListItems}
                    labelChipVariant={LabelChipVariant.classification}
                    handleManualClassificationVerification={handleDataTypeVerification}
                    idKeyValue={ClassificationIdString.assetDataTypeId}
                    viewInsightsProps={{
                      classificationFilterKey: 'dataTypeCodes',
                    }}
                  />
                )}
              </>
            ) : (
              <EmptyValueRenderer />
            )}
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'dataOwner',
      flex: 1,
      minWidth: 150,
      headerAlign: 'left',
      headerName: 'Owner',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<ListAssetItemDTO>) => {
        const ownerId = row.resource?.dataOwner
        const ownerName = ownerId?.startsWith('team|')
          ? allTeamOptions?.find(team => team.id === ownerId)?.name
          : allUserOptions.find(user => user.id === ownerId)?.name
        const avatarColors = ownerId
          ? ownerId.startsWith('team|')
            ? { background: theme.palette.Infographics.BlueC.Secondary, text: theme.palette.Infographics.BlueB.Primary }
            : { background: theme.palette.Infographics.PinkA.Secondary, text: theme.palette.Infographics.PinkA.Primary }
          : {}
        const ownerInitials = ownerName
          ?.trim()
          .split(' ')
          .map(name => name[0].toUpperCase())
          .join('')

        return (
          <TableCell>
            {ownerName ? (
              <Box display="flex" alignItems="center" gap={1}>
                <Avatar backgroundColor={avatarColors.background} variant="circular" size={AvatarSize.medium}>
                  <Typography variant="footnote" color={avatarColors.text}>
                    {ownerInitials}
                  </Typography>
                </Avatar>
                <Typography>{ownerName}</Typography>
              </Box>
            ) : (
              <EmptyValueRenderer />
            )}
          </TableCell>
        )
      },
    },
    {
      align: 'left',
      field: 'discoveredAt',
      minWidth: 200,
      maxWidth: 200,
      headerAlign: 'left',
      headerName: 'Discovered At',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<ListAssetItemDTO>) => {
        const updatedAt = formatDateTimeFromUnix(row.resource?.metadata?.updated?.timestamp)
        return <TableCell title={updatedAt} />
      },
    },
    {
      align: 'left',
      field: 'classificationSchedule',
      minWidth: 200,
      flex: 1,
      headerName: 'Classification Schedule',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<ListAssetItemDTO>) => {
        const chipLabel = `${FREQUENCY_OPTIONS.find(option => `${row.schedule?.frequency}` === option.id)?.name}, ${
          row.schedule?.time ? formatTo12HourTime(row.schedule?.time) : '12:00 am'
        }`
        return (
          <TableCell>
            {!row.schedule ? (
              <EmptyValueRenderer />
            ) : (
              <Box>
                <Chip label={chipLabel} />
              </Box>
            )}
          </TableCell>
        )
      },
    },
    {
      align: 'center',
      field: 'actions',
      minWidth: 100,
      maxWidth: 100,
      headerName: 'Actions',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<ListAssetItemDTO>) => {
        return (
          <TableCell>
            <AssetsTabTableRowEditMenu row={row} triggerModal={setShowEditModal} />
          </TableCell>
        )
      },
    },
  ]
  return !resourceType
    ? unfilteredColumns
    : unfilteredColumns.filter(column => !columnFiltersByResourceType[resourceType]?.includes(column.field))
}
