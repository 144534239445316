import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { Box, Typography } from '@mui/material'
import { RoutesManager } from 'utils/routing/routesManager'
import { AssetSummaryDTO, ScenarioDTO } from '@ketch-com/figurehead'
import { FormMode } from 'interfaces/formModes/formMode'
import { ButtonActions } from 'components/ui-kit/buttonActions/ButtonActions'
import { Button } from '@ketch-com/deck'
import { useSkipDatasetFromScenario } from 'api/assets/mutations/useSkipDatasetFromScenario'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { SQLPreviewModal } from 'pages/assetManager/scenario/components'
import { SelectedAsset } from 'pages/dataSystems/DataSystem/rightsOrchestrationTab/RightsOrchestrationTab'

type Props = {
  scenario: ScenarioDTO
  assetSummary: AssetSummaryDTO
}

export const ScenarioAssetConfiguration: React.FC<Props> = ({ assetSummary, scenario }) => {
  const [isSqlPreviewModalOpen, openSqlPreviewModal] = useState(false)
  const selectedAsset = React.useContext(SelectedAsset)
  const assetId = selectedAsset.resource?.id as string
  const resourceTypeCode = selectedAsset.resource?.resourceType!
  const queryClient = useQueryClient()
  const { mutateAsync: skipDataset } = useSkipDatasetFromScenario({
    onSuccess: async () => {
      await queryClient.invalidateQueries(ApiQueryKeys.scenarios)
      await queryClient.invalidateQueries([ApiQueryKeys.resource, { assetCode: assetId }])
      showToast({ content: 'Skipped dataset configuration', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Unable to skip dataset configuration', type: 'error' })
    },
  })
  const navigate = useNavigate()
  const scenarioId = scenario.id || ''
  const rightCode = scenario.canonicalRight?.code || ''
  const dsrConfig = assetSummary?.asset?.dsrConfiguration
  const selectedDsrConfig = dsrConfig?.[scenarioId!] || {}
  const { isConfigured, isSkipped } = selectedDsrConfig || {}
  let configuration = null
  if (selectedDsrConfig && !isConfigured && isSkipped) {
    configuration = (
      <>
        <Typography fontSize={14}>
          Action:{' '}
          <Typography component="span" fontSize={14} fontWeight={600}>
            Skip
          </Typography>
        </Typography>
        <Button
          color="secondary"
          onClick={() => {
            navigate(
              RoutesManager.systems.dsrInvocation.dsrConfig.getURL({
                assetId,
                formMode: FormMode.CREATE,
                scenarioId: '1',
              }),
            )
          }}
        >
          Configure
        </Button>
      </>
    )
  } else if (selectedDsrConfig && isConfigured && isSkipped) {
    configuration = (
      <>
        <Typography fontSize={14}>
          Action:{' '}
          <Typography component="span" fontSize={14} fontWeight={600}>
            Skip
          </Typography>
        </Typography>
        <Button
          color="secondary"
          onClick={() => {
            navigate(
              RoutesManager.systems.dsrInvocation.dsrConfig.getURL({
                assetId,
                formMode: FormMode.EDIT,
                scenarioId: '1',
              }),
            )
          }}
        >
          Edit
        </Button>
      </>
    )
  } else if (selectedDsrConfig && isConfigured && !isSkipped) {
    configuration = (
      <>
        <Typography fontSize={14}>
          Action:{' '}
          <Typography component="span" fontSize={14} fontWeight={600}>
            Customize Row
          </Typography>
        </Typography>

        <Button
          color="tertiary"
          onClick={() => {
            openSqlPreviewModal(true)
          }}
        >
          SQL
        </Button>

        <ButtonActions
          variant="secondary"
          items={[
            {
              content: 'Edit',
              onClick: () => {
                navigate(
                  RoutesManager.assetManager.assets.detail.dsrConfigUpsert.getURL({
                    scenarioId,
                    id: assetId,
                    resourceTypeCode,
                    formMode: FormMode.EDIT,
                    assetType: resourceTypeCode,
                  }),
                )
              },
            },
            {
              content: 'Skip',
              onClick: async () => {
                await skipDataset({
                  params: {
                    scenarioId,
                    assetId,
                    payload: {
                      isSkipped: true,
                    },
                  },
                })
              },
            },
          ]}
        >
          Edit
        </ButtonActions>
      </>
    )
  }

  if (!Object.keys(selectedDsrConfig).length) {
    configuration = (
      <>
        <Typography fontSize={14}>
          Action:{' '}
          <Typography component="span" fontSize={14} color="grey">
            None
          </Typography>
        </Typography>

        <ButtonActions
          variant="secondary"
          items={[
            {
              content: 'Customize Rows',
              onClick: () => {
                navigate(
                  RoutesManager.assetManager.assets.detail.dsrConfigUpsert.getURL({
                    scenarioId,
                    id: assetId,
                    resourceTypeCode,
                    formMode: FormMode.CREATE,
                    assetType: resourceTypeCode,
                  }),
                )
              },
            },
            {
              content: 'Skip',
              onClick: async () => {
                await skipDataset({
                  params: {
                    scenarioId,
                    assetId,
                    payload: {
                      isSkipped: true,
                    },
                  },
                })
              },
            },
          ]}
        >
          Configure
        </ButtonActions>
      </>
    )
  }
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width={300}>
      {configuration}
      {isSqlPreviewModalOpen ? (
        <SQLPreviewModal
          scenarioId={scenarioId!}
          assetSummary={assetSummary}
          rightCode={rightCode}
          onCancel={() => {
            openSqlPreviewModal(false)
          }}
        />
      ) : null}
    </Box>
  )
}
