import { Logger } from 'utils/Logger'

/**
 * Retry a function a specified number of times if it throws an error
 * @param fn - The function to retry
 * @param retries - The number of times to retry the function
 * @returns The result of the function
 */
export function retry<T>(fn: () => T, retries: number): T {
  let attempts = 0
  while (attempts < retries) {
    try {
      return fn()
    } catch (error) {
      attempts++
      if (attempts >= retries) {
        throw error
      }
      Logger.warn('Retrying due to error', error)
    }
  }
  throw new Error('Max retries reached')
}

/**
 * Retry an async function a specified number of times if it throws an error
 * @param fn - The async function to retry
 * @param retries - The number of times to retry the function
 * @returns The result of the function
 */
export async function retryAsync<T>(fn: () => Promise<T>, retries: number): Promise<T> {
  let attempts = 0
  while (attempts < retries) {
    try {
      return await fn()
    } catch (error) {
      attempts++
      if (attempts >= retries) {
        throw error
      }
      Logger.warn('Retrying due to error', error)
    }
  }
  throw new Error('Max retries reached')
}
