import { useEffect, useState } from 'react'

import { MaybeNull } from 'interfaces'
import * as CanvasConstants from '../utils/constants'
import { useWindowSize } from './useWindowSize'

interface IUseCanvasProps {
  initialX?: number
  initialY?: number
  initialScale?: number
  stageWrapper: MaybeNull<HTMLDivElement>
  isExpanded?: boolean
}

interface IUseCanvasState {
  positionX: number
  positionY: number
  scale: number
  canvasWidth: number
  canvasHeight: number
  canvasViewPortWidth: number
  canvasViewPortHeight: number
}

export interface IUseCanvas extends IUseCanvasState {
  updateCanvasSettings: (params: (params: IUseCanvasState) => IUseCanvasState) => void
}

export const useCanvas = (props: IUseCanvasProps): IUseCanvas => {
  const { initialX, initialY, initialScale, stageWrapper, isExpanded } = props
  const { windowWidth, windowHeight } = useWindowSize()

  // Helper function for determining canvas viewport width
  const calculateCanvasViewportWidth = (clientWidth?: number, isExpanded?: boolean): number => {
    if (!clientWidth) return 0

    const offset = isExpanded
      ? CanvasConstants.CANVAS_SIDEBAR_EXPANDED_LEFT_OFFSET
      : CanvasConstants.CANVAS_SIDEBAR_COLLAPSED_LEFT_OFFSET

    return clientWidth - offset
  }

  const canvasViewPortWidth = calculateCanvasViewportWidth(stageWrapper?.clientWidth, isExpanded)

  const [canvasSettings, updateCanvasSettings] = useState<IUseCanvasState>({
    positionX: initialX || 0,
    positionY: initialY || 0,
    scale: initialScale || 1,
    canvasWidth: CanvasConstants.GRID_SIZE_X * CanvasConstants.GRID_CELL_WIDTH,
    canvasHeight: CanvasConstants.GRID_SIZE_Y * CanvasConstants.GRID_CELL_HEIGHT,
    canvasViewPortWidth,
    canvasViewPortHeight: stageWrapper?.clientHeight || 0,
  })

  useEffect(() => {
    if (stageWrapper) {
      updateCanvasSettings(prevSettings => ({
        ...prevSettings,
        canvasViewPortWidth,
        canvasViewPortHeight: stageWrapper.clientHeight,
      }))
    }
  }, [windowWidth, windowHeight, stageWrapper, isExpanded, canvasViewPortWidth])

  return {
    ...canvasSettings,
    updateCanvasSettings,
  }
}
