import { FC } from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { Box, Grid, Tooltip } from '@mui/material'
import { Button, FormRow, Icon, theme } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { ApplicationEnvironment } from 'interfaces/applications/applicationEnvironment'
import { ApplicationFormValues } from 'interfaces/applications/ApplicationFormValues'
import { ApplicationPlatform } from 'interfaces/applications/applicationPlatform'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

interface Props {
  themes: ThemeV3DTO[]
}

const MAX_ENVIRONMENTS_COUNT = 3

export const ApplicationEnvironments: FC<Props> = ({ themes }) => {
  const { values } = useFormikContext<ApplicationFormValues>()
  const canAdd = values.environments.length < MAX_ENVIRONMENTS_COUNT

  return (
    <FormRow hasTopBorder title="Environments">
      <FieldArray name="environments">
        {({ push, remove }) => (
          <>
            {values.environments.map((environment, index: number) => {
              const isProd = environment.code === ApplicationEnvironment.PRODUCTION
              return (
                <Box
                  key={index}
                  pb={2}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.Common.Divider}`,
                    ...(index > 0 && {
                      marginTop: 1,
                    }),
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={2.8}>
                      <Tooltip
                        title={isProd ? 'The name of the production environment cannot be changed' : ''}
                        placement="bottom"
                      >
                        <span>
                          <FormInput
                            fullWidth
                            required
                            label="Name"
                            formPropertyName={`environments[${index}].code`}
                            placeholder="Name"
                            sx={isProd ? { opacity: 0.3, pointerEvents: 'none' } : {}}
                          />
                        </span>
                      </Tooltip>
                    </Grid>
                    {values.platform === ApplicationPlatform.CTV && (
                      <Grid item xs={2.8}>
                        <FormInput
                          fullWidth
                          required
                          label="Domain"
                          formPropertyName={`environments[${index}].domain`}
                          placeholder="Domain"
                        />
                      </Grid>
                    )}
                    {values.platform !== ApplicationPlatform.MOBILE && (
                      <Grid item xs={2.8}>
                        <FormInput
                          fullWidth
                          required
                          label="Pattern"
                          formPropertyName={`environments[${index}].pattern`}
                          placeholder="Pattern"
                        />
                      </Grid>
                    )}
                    {isRelease(Release.PropertyThemeDocuments) && (
                      <Grid item xs={2.8}>
                        <FormDroplistButton
                          fullWidth
                          required
                          disableClearable
                          label="Theme"
                          formPropertyName={`environments[${index}].themeID`}
                          placeholder="Select Theme"
                          items={themes}
                          valueKey="id"
                        />
                      </Grid>
                    )}
                    <Grid item xs={0.8} alignSelf="flex-start" mt={3.5}>
                      <Tooltip title={isProd ? 'The production environment cannot be deleted' : ''} placement="bottom">
                        <span>
                          <Button
                            variant="iconCustomRounded"
                            disabled={isProd}
                            color="tertiary"
                            onClick={() => remove(index)}
                          >
                            <Icon name="OBin" />
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              )
            })}

            {canAdd && (
              <Button
                color="secondary"
                startIcon={<Icon name="OPlus" />}
                onClick={() => {
                  push({ code: '', domain: '', pattern: '', themeID: '' })
                }}
                sx={{
                  marginTop: 2,
                }}
              >
                Add environment
              </Button>
            )}
          </>
        )}
      </FieldArray>
    </FormRow>
  )
}
