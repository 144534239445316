export const legalBasisItems = [
  { code: 'consent_optin', name: 'Consent - Opt In' },
  { code: 'consent_optout', name: 'Consent - Opt Out' },
  { code: 'disclosure', name: 'Disclosure' },
]

export const systems = {
  marketo: 'marketo',
  braze: 'braze',
  iterable: 'iterable',
  shopify: 'shopify',
  salesforceMarketingCloud: 'salesforce marketing cloud',
  hubspot: 'hubspot',
  rudderstack: 'rudderstack',
}

export const appParams = {
  fieldName: '', // marketo
  groupID: '', // braze
  subscriptionGroup: '', // iterable
  subscriptionGroupId: '', // iterable
}

export const iterableSubscriptionGroupItems = [
  { id: 'messageChannel', name: 'Message Channel' },
  { id: 'messageType', name: 'Message Type' },
  { id: 'emailList', name: 'Email List' },
]

export const SfmcMasterListSubscriptionGroup = 'masterList'

export const salesforceMarketingCloudSubscriptionGroupItems = [
  { id: 'publicationList', name: 'List' },
  { id: SfmcMasterListSubscriptionGroup, name: 'All Subscribers List' },
]

export const shopifySubscriptionGroupItems = [
  { id: 'email', name: 'Email' },
  { id: 'sms', name: 'SMS' },
]
