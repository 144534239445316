import { CircularProgress, theme } from '@ketch-com/deck'
import { Box, Typography, styled } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { ExperiencePreviewContext } from '../context/ExperiencePreviewContext'
import { ThemeType } from 'pages/consentAndRights/themes-v3/upsert/utils/enums'
import { ThemeTypeLabels } from 'pages/consentAndRights/themes-v3/upsert/utils/labels'
import { HeaderData } from 'pages/insights/auditLogs/hooks/useAuditLogConsentContextMockData'

const PreviewHeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '12px 16px',
  boxShadow: '0px 4px 10px 0px rgba(7, 26, 36, 0.10);',
  zIndex: 3,
})

const PreviewHeaderLeftBox = styled(Box)({
  display: 'flex',
  gap: '32px',
  alignItems: 'flex-end',
})

const PreviewHeaderRightBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
})

const HeaderDataBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

interface Props {
  isLoading: boolean
  headerData?: HeaderData
}

export const DirectConfigPreviewHeader: React.FC<Props> = ({ isLoading = false, headerData }) => {
  const { setThemeType } = useContext(ExperiencePreviewContext)

  useEffect(() => {
    if (headerData?.experienceType) {
      setThemeType(headerData.experienceType)
    }
  }, [headerData?.experienceType, setThemeType])

  return (
    <PreviewHeaderBox>
      <PreviewHeaderLeftBox>
        <HeaderDataBox>
          <Typography variant="smallLabel">Experience Type</Typography>
          <Typography variant="smallBody">
            {ThemeTypeLabels[headerData?.experienceType || ThemeType.Preference]}
          </Typography>
        </HeaderDataBox>

        <HeaderDataBox>
          <Typography variant="smallLabel">Language</Typography>
          <Typography variant="smallBody">{headerData?.language}</Typography>
        </HeaderDataBox>

        <HeaderDataBox>
          <Typography variant="smallLabel">Resolved Jurisdiction</Typography>
          <Typography variant="smallBody">
            {headerData?.jurisdiction}{' '}
            <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
              ({headerData?.region})
            </Typography>
          </Typography>
        </HeaderDataBox>

        <HeaderDataBox>
          <Typography variant="smallLabel">Privacy Policy Version</Typography>
          <Typography variant="smallBody">v{headerData?.privacyPolicyVersion}</Typography>
        </HeaderDataBox>
      </PreviewHeaderLeftBox>

      <PreviewHeaderRightBox>
        {isLoading && <CircularProgress label={<Typography variant={'label'}>Preview Loading</Typography>} />}
      </PreviewHeaderRightBox>
    </PreviewHeaderBox>
  )
}
