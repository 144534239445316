import React, { useContext, useEffect, useRef } from 'react'
import { ConsentExperienceType } from 'interfaces/experiences-v2/consentExperienceType'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { usePreviewConfig } from 'api/experiences-v2/queries/usePreviewConfig'
import { ConfigurationV2, PreviewMessage, PreviewMessageType } from '@ketch-sdk/ketch-types'
import { ThemeType } from 'pages/consentAndRights/themes-v3/upsert/utils/enums'
import {
  ExperiencePreviewVariant,
  SHOW_BANNER_PREVIEW_MESSAGE,
  SHOW_MODAL_PREVIEW_MESSAGE,
  SHOW_PREFERENCE_PREVIEW_MESSAGE,
} from 'pages/consentAndRights/experiences-v2/view/components/experiencePreview/constants'
import { ExperiencePreviewContext } from 'pages/consentAndRights/experiences-v2/view/components/experiencePreview/context/ExperiencePreviewContext'
import { ExperienceViewContext } from 'pages/consentAndRights/experiences-v2/view/context/ExperienceViewContext'
import {
  themeTypeMessageMap,
  themeTypeMessageTypeMap,
} from 'pages/consentAndRights/experiences-v2/view/components/experiencePreview/ExperiencePreview'
import { getPreviewMessage } from 'pages/consentAndRights/experiences-v2/view/components/experiencePreview/utils'
import { PreviewDisplayMode } from 'pages/consentAndRights/experiences-v2/view/utils/enums'

type ExperiencePreviewRendererProps = {
  variant: ExperiencePreviewVariant
  experienceID?: string
  themeID?: string
  deploymentID?: string
  languageCode: string
  jurisdictionCode: string
  directConfig?: ConfigurationV2
}

export const ExperiencePreviewRenderer: React.FC<ExperiencePreviewRendererProps> = ({
  variant,
  experienceID,
  themeID,
  deploymentID,
  languageCode,
  jurisdictionCode,
  directConfig,
}) => {
  const iRef = useRef<HTMLIFrameElement | null>(null)
  const { displayMode, consentExperienceType, isPreviewReady, setIsPreviewReady, themeType } =
    useContext(ExperiencePreviewContext)

  const { experience } = useContext(ExperienceViewContext)

  const { data: fetchedConfig } = usePreviewConfig({
    enabled: variant !== ExperiencePreviewVariant.DirectConfig,
    params: {
      experienceID: experienceID,
      themeID,
      languageCode: languageCode,
      jurisdictionCode: jurisdictionCode,
      deploymentID,
    },
    onSuccess: _ => {
      // Hack to force iframe reload on config change
      if (iRef.current && !isPreviewReady) {
        iRef.current.src += ''
      }
    },
  })

  const previewConfig = directConfig || fetchedConfig

  //
  // Effects for managing fetching and communication with iFrame
  //

  const isConsent = experience?.type === ExperienceType.Consent

  // Send consent experience type updates to lanyard
  useEffect(() => {
    if (isConsent && isPreviewReady) {
      const message =
        consentExperienceType === ConsentExperienceType.Banner
          ? SHOW_BANNER_PREVIEW_MESSAGE
          : SHOW_MODAL_PREVIEW_MESSAGE
      iRef.current?.contentWindow?.postMessage(message)
    }
  }, [consentExperienceType, isPreviewReady, isConsent])

  // Send theme type updates to lanyard
  useEffect(() => {
    if (isPreviewReady) {
      const message = themeTypeMessageMap[themeType]
      iRef.current?.contentWindow?.postMessage(message)
    }
  }, [themeType, isPreviewReady])

  // Send initial config
  useEffect(() => {
    if (isPreviewReady && previewConfig) {
      // Send initial view mode message for preference only
      if (variant === ExperiencePreviewVariant.PreferenceExperience) {
        iRef.current?.contentWindow?.postMessage(SHOW_PREFERENCE_PREVIEW_MESSAGE)
      }

      // Send inital config and display mode
      let initialMessage: PreviewMessage
      if (
        variant === ExperiencePreviewVariant.ConsentExperience ||
        variant === ExperiencePreviewVariant.PreferenceExperience
      ) {
        initialMessage = isConsent
          ? consentExperienceType === ConsentExperienceType.Banner
            ? getPreviewMessage(PreviewMessageType.ShowBanner, previewConfig)
            : getPreviewMessage(PreviewMessageType.ShowModal, previewConfig)
          : getPreviewMessage(PreviewMessageType.ShowPreference, previewConfig)
      } else {
        initialMessage = getPreviewMessage(themeTypeMessageTypeMap[themeType], previewConfig)
      }

      iRef.current?.contentWindow?.postMessage(initialMessage)
    }
  }, [isPreviewReady, isConsent, previewConfig, consentExperienceType, themeType, variant])

  // Listen for the ready signal from preview.html
  useEffect(() => {
    window.addEventListener('message', (e: MessageEvent) => {
      if (e.data.type === 'lanyardReady' && !isPreviewReady) {
        setIsPreviewReady(true)
      }
    })
  }, [isPreviewReady, setIsPreviewReady])

  //
  // Display variables
  //

  const isDesktop = displayMode === PreviewDisplayMode.Desktop

  const hasGreaterBackgroundHeight =
    themeType === ThemeType.Preference ||
    themeType === ThemeType.Modal ||
    variant === ExperiencePreviewVariant.PreferenceExperience ||
    (variant === ExperiencePreviewVariant.ConsentExperience && consentExperienceType === ConsentExperienceType.Modal)

  const iFrameStyle: React.CSSProperties = {
    ...(isDesktop
      ? { width: '100%', height: hasGreaterBackgroundHeight ? '769px' : '570px', border: 0 }
      : { width: '100%', height: '100%', border: 0 }),
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    background: 'transparent',
    borderRadius: '11px',
  }

  return (
    <iframe
      title="experience preview"
      ref={iRef}
      style={iFrameStyle}
      src={`${window.location.origin}/preview.html?is_preview=true`}
    />
  )
}
