import {
  Avatar,
  AvatarSize,
  Icon,
  LabelChip,
  LabelChipColor,
  LabelChipVariant,
  TabSegmented,
  TabSegmentedGroup,
  theme,
} from '@ketch-com/deck'
import { useDataSystemsContext } from '../../context/DataSystemContext'
import { Box, Tooltip, Typography } from '@mui/material'
import useFilteredAndDedupedDataSystemAssets from '../hooks/useFilteredAndDedupedDataSystemAssets'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { dataSystemAssetsColumns } from '../utils/DataSystemAssetsDataGridUtil'
import { useDataCategoriesRolloverContext } from '../../context/DataCategoriesRolloverContext'
import { ListAssetItemDTO } from '@ketch-com/figurehead'
import { DataSystemAssetsTable } from '../DataSystemAssetsTable'
import { getResourceType } from '../utils/getResourceType'
import { AssetsTabFilters } from './AssetsTabFilters'
import { DrillDownTracker } from './DrillDownTracker'
import { Drawer } from 'components/ui-kit/drawer/Drawer'
import { useManualClassificationVerification } from '../hooks/useManualClassificationVerification'
import { useAssetsTabFilterContext } from '../context/AssetsTabFilterContext'
import { useFetchDataTypes } from 'api/assets/queries/useFetchDataTypes'
import { useFetchDataSensitivities } from 'api/assets/queries/useFetchDataSensitivities'
import { fetchAssignedDataCategories } from 'api/dataSystemsCategories/fetchers/fetchAssignedDataCategories'
import { AssetsTableActionModal } from './AssetsTableActionModal'
import { useAssetTableControlledPagination } from '../utils/useAssetTableControlledPagination'
import { useBulkEdit } from '../hooks/useBulkEdit'
import { BulkEditModalTypeSelectors } from './bulkEditModals/components/BulkEditModalTypeSelectors'
import { AssetsTableBulkEditModal } from './bulkEditModals/AssetsTableBulkEditModal'
import { canonicalResourceTypeMap } from '../constants'
import { AssetsTabEmptyState } from './AssetsTabEmptyState'

export interface DataSystemsAssetInsightsRolloverProps {
  open?: boolean
}

export const DataSystemsAssetInsightsRollover: React.FC<DataSystemsAssetInsightsRolloverProps> = ({ open }) => {
  const {
    appDescriptor,
    dataClassificationsRolloverProps,
    setdataClassificationsRolloverProps,
    allUserOptions,
    allTeamOptions,
    showAssetsTab,
  } = useDataSystemsContext()
  const {
    assetCategory,
    assetQueryPayload,
    setAssetCategory,
    drillDownLineage,
    setParentResourceID,
    paginationModel,
    setPaginationModel,
    assetsDataSystems,
    isLoadingAssetsDataSystems,
    snapshotCurrentQueryDetails,
    handleClearDrilldown,
    searchQuery,
    handleSearchInputChange,
    filterData,
    setFilterData,
    sections,
    setCounts,
    filterButtonText,
    resetFilters,
    traverseLineage,
    setShowEditModal,
    showEditModal,
  } = useDataCategoriesRolloverContext()

  const {
    dedupedDataSystemAssets,
    isLoadingInstalledDatasystemAssets,
    installedDatasystemPagination,
    refetchInstalledDatasystemAssets,
    isErrorInstalledDatasystemAssets,
  } = useFilteredAndDedupedDataSystemAssets(assetQueryPayload, showAssetsTab)

  const { refetchInstalledDatasystemAssets: refetchAssetsTabTableData } = useAssetsTabFilterContext()

  const refetchOnEdit = useCallback(() => {
    refetchInstalledDatasystemAssets()
    refetchAssetsTabTableData()
  }, [refetchInstalledDatasystemAssets, refetchAssetsTabTableData])

  const { handleManualClassificationVerification } = useManualClassificationVerification(
    appDescriptor?.id!,
    refetchInstalledDatasystemAssets,
  )

  const [currentResourceLevel, setCurrentResourceLevel] = useState<string | undefined>(undefined)

  const megaFilterProps = {
    filterData,
    isLoadingAssetsDataSystems,
    setFilterData,
    sections,
    setCounts,
    filterButtonText,
    resetFilters,
    desyncfromUrlParams: true,
  }

  useEffect(() => {
    if (!currentResourceLevel && assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code) {
      setAssetCategory(assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code)
      setCurrentResourceLevel(assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code)
    }
  }, [assetsDataSystems.dataSystems, currentResourceLevel, setAssetCategory])

  const [description, setDescription] = useState<string | undefined>(undefined)

  const { refetch: fetchDataTypes } = useFetchDataTypes({ enabled: false, params: { limit: 1000 } })
  const { refetch: fetchDataSensitivities } = useFetchDataSensitivities({ enabled: false, params: { limit: 1000 } })

  const assetsCount = useMemo(() => {
    return assetsDataSystems.dataSystems?.[0] ? assetsDataSystems.dataSystems[0].totalAssetCount : 0
  }, [assetsDataSystems.dataSystems])

  useEffect(() => {
    const queryForDescription = async () => {
      if (dataClassificationsRolloverProps?.dataClassification.type) {
        if (dataClassificationsRolloverProps.dataClassification.type === 'dataSensitivities') {
          const dataSensitivities = await fetchDataSensitivities()
          setDescription(
            dataSensitivities?.data?.data?.dataSensitivities?.find(
              sensitivity => sensitivity.code === dataClassificationsRolloverProps.dataClassification.code,
            )?.description || 'No description available',
          )
        }
        if (dataClassificationsRolloverProps.dataClassification.type === 'dataTypes') {
          const dataTypes = await fetchDataTypes()
          setDescription(
            dataTypes?.data?.data?.dataTypes?.find(
              type => type.code === dataClassificationsRolloverProps.dataClassification.code,
            )?.description || 'No description available',
          )
        }
        if (dataClassificationsRolloverProps.dataClassification.type === 'dataCategories') {
          const dataCategories = await fetchAssignedDataCategories({
            installedDataSystemId: appDescriptor?.id,
          })
          setDescription(
            dataCategories?.data?.assignedCategories?.find(
              category => category.category?.code === dataClassificationsRolloverProps.dataClassification.code,
            )?.category?.description || 'No description available',
          )
        }
      }
    }
    if (dataClassificationsRolloverProps?.dataClassification.type) {
      queryForDescription()
    }
  }, [
    appDescriptor?.id,
    dataClassificationsRolloverProps?.dataClassification.code,
    dataClassificationsRolloverProps?.dataClassification.type,
    fetchDataSensitivities,
    fetchDataTypes,
  ])

  const { page, setPage, handlePaginationChange } = useAssetTableControlledPagination(setPaginationModel)

  const columns = dataSystemAssetsColumns({
    resourceType: getResourceType(currentResourceLevel),
    setAssetCategory,
    setCurrentResourceLevel,
    setParentResourceID,
    snapshotCurrentQueryDetails,
    handleManualClassificationVerification,
    setShowEditModal,
    allUserOptions,
    allTeamOptions,
    setPage,
  })

  const modalProps = useMemo(() => {
    if (showEditModal) {
      return {
        ...showEditModal.modalProps,
        refetchInstalledDatasystemAssets: refetchOnEdit,
      }
    }
  }, [showEditModal, refetchOnEdit])

  const titleChipVariant =
    dataClassificationsRolloverProps?.dataClassification?.type === 'dataTypes'
      ? LabelChipVariant.classification
      : dataClassificationsRolloverProps?.dataClassification?.type === 'dataCategories'
      ? LabelChipVariant.category
      : LabelChipVariant.sensitivity

  const isLoading = isLoadingAssetsDataSystems || isLoadingInstalledDatasystemAssets

  const tableMaxHeight = useMemo(() => (drillDownLineage.length > 0 ? '340px' : '400px'), [drillDownLineage.length])

  const {
    rowSelectionModel,
    setRowSelectionModel,
    handleSelectionChange,
    showBulkEditModal,
    setShowBulkEditModal,
    resourceType,
  } = useBulkEdit()

  return (
    <Drawer
      open={open}
      onClose={() => {
        setdataClassificationsRolloverProps()
        handleClearDrilldown()
        setDescription(undefined)
        setCurrentResourceLevel(undefined)
      }}
      PaperProps={{
        sx: {
          // TODO make height of component height fully dynamic based on view size
          height: '722px',
          width: '100%',
          maxWidth: '1300px',
          margin: 'auto',
          padding: '32px',
          borderRadius: '37px 37px 0px 0px',
          overflow: 'hidden',
          '&  > .MuiBox-root': {
            overflow: 'hidden',
          },
        },
      }}
    >
      <Box height="100%" overflow="auto">
        <Box display="flex" flexDirection="column" gap={4} flexGrow={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <LabelChip
              isViewOnly
              PopperProps={{ disablePortal: true }}
              customTitleComponent={
                <Typography variant="h3">{dataClassificationsRolloverProps?.dataClassification.name!}</Typography>
              }
              variant={titleChipVariant}
              color={LabelChipColor.confident}
              isDone={false}
            />
            <Typography variant="h3" color={theme.palette.Text.Secondary}>
              in
            </Typography>
            <Avatar
              isLogo
              src={appDescriptor?.dataSystem?.logoUrl}
              alt={appDescriptor?.dataSystem?.logoUrl}
              variant="rounded"
              size={AvatarSize['medium']}
              backgroundColor={theme.palette.tertiary.main}
            />
            <Typography variant="h3">{appDescriptor?.dataSystem?.name}</Typography>
            {description && (
              <Tooltip
                placement="bottom"
                title={
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ backgroundColor: theme.palette.Black.o100 }}
                    gap={0.5}
                  >
                    <Typography variant="label" color={theme.palette.Text.PrimaryLight}>
                      {`About ${dataClassificationsRolloverProps?.dataClassification.name}: `}
                    </Typography>
                    <Typography variant="body" color={theme.palette.Text.PrimaryLight}>
                      {description}
                    </Typography>
                  </Box>
                }
              >
                <Box display="flex">
                  <Icon sx={{ width: '16px', height: '16px' }} name="FUnknown" iconColor={theme.palette.Black.o32} />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Box paddingY={1}>
          <Box width="100%">
            {assetsCount === 0 ? (
              <AssetsTabEmptyState />
            ) : (
              <>
                <Typography variant="body">{`Below are all the Data Assets within ${appDescriptor?.dataSystem?.name} that have ${dataClassificationsRolloverProps?.dataClassification.name} assigned.`}</Typography>
                {drillDownLineage.length > 0 && (
                  <Box paddingTop={1}>
                    <AssetsTabFilters
                      searchQuery={searchQuery}
                      handleSearchInputChange={handleSearchInputChange}
                      megaFilterProps={megaFilterProps}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    backgroundColor:
                      drillDownLineage.length > 0 ? theme.palette.Black.o8 : theme.palette.Common.Container,
                    paddingTop: drillDownLineage.length > 0 ? 1 : 0,
                    paddingBottom: 2,
                    paddingX: drillDownLineage.length > 0 ? 2 : 0,
                    borderRadius: '16px',
                    marginTop: drillDownLineage.length > 0 ? 1 : 0,
                  }}
                >
                  {drillDownLineage.length > 0 ? (
                    <DrillDownTracker
                      drillDownLineage={drillDownLineage}
                      traverseLineage={traverseLineage}
                      handleClearDrilldown={handleClearDrilldown}
                      setCurrentResourceLevel={setCurrentResourceLevel}
                      onClose={() => {
                        setCurrentResourceLevel(assetsDataSystems.dataSystems?.[0]?.assetCategories?.[0].code)
                      }}
                    />
                  ) : (
                    <Box display="flex" justifyContent="space-between" paddingY={1}>
                      <AssetsTabFilters
                        searchQuery={searchQuery}
                        handleSearchInputChange={handleSearchInputChange}
                        megaFilterProps={megaFilterProps}
                      />
                      {assetCategory && (
                        <Box>
                          <TabSegmentedGroup
                            value={currentResourceLevel}
                            exclusive
                            disabled={isLoading}
                            onChange={(e, nextVal) => {
                              setCurrentResourceLevel(nextVal)
                            }}
                          >
                            {assetsDataSystems.dataSystems?.[0]?.assetCategories?.map(category => {
                              return (
                                <TabSegmented
                                  key={category.code}
                                  value={category.code!}
                                  disabled={isLoading}
                                  size="small"
                                  onClick={e => {
                                    setAssetCategory(category.code)
                                    setParentResourceID(undefined)
                                    setPaginationModel({
                                      page: 0,
                                      pageSize: 10,
                                    })
                                    setPage(1)
                                    setRowSelectionModel([])
                                    handleSearchInputChange('')
                                  }}
                                >
                                  <Box display="flex" gap={0.5}>
                                    <Typography variant="label">{getResourceType(category.code)}</Typography>
                                    {/* We currently do not support count of specific classifications */}
                                    {/* <Typography variant="fadedLabel">{category.count}</Typography> */}
                                  </Box>
                                </TabSegmented>
                              )
                            })}
                          </TabSegmentedGroup>
                        </Box>
                      )}
                    </Box>
                  )}
                  {rowSelectionModel.length > 0 &&
                    (dedupedDataSystemAssets as ListAssetItemDTO[])?.[0]?.resource?.canonicalResourceType && (
                      <BulkEditModalTypeSelectors
                        rowSelectionModelLength={rowSelectionModel.length}
                        setShowBulkEditModal={setShowBulkEditModal}
                        currentCanonicalResourceLevel={
                          canonicalResourceTypeMap[
                            (dedupedDataSystemAssets as ListAssetItemDTO[])[0].resource!.canonicalResourceType!
                          ]
                        }
                      />
                    )}
                  <DataSystemAssetsTable
                    columns={columns}
                    rows={dedupedDataSystemAssets as ListAssetItemDTO[]}
                    rowCount={installedDatasystemPagination?.totalCount}
                    isLoading={isLoading}
                    isError={isErrorInstalledDatasystemAssets}
                    paginationModel={paginationModel}
                    handlePaginationChange={handlePaginationChange}
                    page={page}
                    width="100%"
                    rowSelectionModel={rowSelectionModel}
                    handleSelectionChange={handleSelectionChange}
                    pageSize={paginationModel.pageSize}
                    maxHeight={tableMaxHeight}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>

      {showBulkEditModal && rowSelectionModel.length > 0 && resourceType && (
        <AssetsTableBulkEditModal
          modalType={showBulkEditModal}
          modalProps={{
            assetIds: rowSelectionModel,
            resourceType: resourceType,
            refetchInstalledDatasystemAssets: refetchInstalledDatasystemAssets,
            onClose: () => {
              setShowBulkEditModal(undefined)
              setRowSelectionModel([])
            },
          }}
        />
      )}
      {showEditModal && modalProps && (
        <AssetsTableActionModal modalType={showEditModal.editModalType} modalProps={modalProps} />
      )}
    </Drawer>
  )
}
