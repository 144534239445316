import Konva from 'konva'
import { clamp, debounce, round } from 'lodash'

import { ICoordinates, IZoomCoordinates } from '../interfaces'
import * as CanvasConstants from '../utils/constants'
import { Vector2d } from 'konva/cmj/types'
import { getCanvasBoundaries } from '../utils/positioning/canvasBoundaries'
import { getAbsoluteCoordinatesV2 } from '../utils/positioning/absoluteCoordinates'
import type { IUseCanvas } from 'pages/orchestration/workflows/edit/hooks/useCanvas'

interface ICanvasNavigationProps {
  stageRef: any
  positionX: number
  positionY: number
  scale: number
  initialScale: number
  canvasWidth: number
  canvasHeight: number
  canvasViewPortWidth: number
  canvasViewPortHeight: number
  onChangeZoom: (params: IZoomCoordinates) => void
  onChangePosition: (params: ICoordinates) => void
  updateCanvasSettings: IUseCanvas['updateCanvasSettings']
}

interface IUseCanvasNavigation {
  initialScale: number
  isAtMaxZoom: boolean
  isAtMinZoom: boolean
  isAtInitialZoom: boolean
  isAtInitialPosition: boolean
  handleZoomIn: () => void
  handleZoomOut: () => void
  handleWheel: (e: Konva.KonvaEventObject<WheelEvent>) => void
  handleZoom: (e: Konva.KonvaEventObject<WheelEvent>) => void
  handleSetInitialZoom: () => void
  handleDragPanning: (position: ICoordinates) => ICoordinates
  handleSetInitialPosition: () => void
}

export const useCanvasNavigation = (props: ICanvasNavigationProps): IUseCanvasNavigation => {
  const {
    stageRef,
    scale,
    initialScale,
    canvasWidth,
    canvasHeight,
    canvasViewPortWidth,
    canvasViewPortHeight,
    onChangeZoom,
    onChangePosition,
    updateCanvasSettings,
  } = props
  const isAtMaxZoom = scale <= CanvasConstants.SCALE_STEP_LIMIT_MAX
  const isAtMinZoom = scale >= CanvasConstants.SCALE_STEP_LIMIT_MIN

  const changePositionDebounced = debounce(onChangePosition, 100)
  const initialPosition = getAbsoluteCoordinatesV2(CanvasConstants.CANVAS_START_VIEW_POSITION)

  const isAtInitialZoom = scale === 1
  const isAtInitialPosition =
    stageRef && initialPosition && stageRef.x() === initialPosition.x && stageRef.y() === initialPosition.y

  // The center relative to top left corner of the screen, NOT absolute center
  const relativeCenter: Vector2d = {
    x: canvasViewPortWidth / 2,
    y: canvasViewPortHeight / 2,
  }

  /** Zooming Functions **/

  /**
   * Zoom in or out towards a certain point
   * @param nextScale New zoom level
   * @param point Point to center zoom on
   */
  const zoomToPoint = (nextScale: number, point: Vector2d): void => {
    // Ensure nextScale is within allowed range
    const computedScale = clamp(nextScale, CanvasConstants.SCALE_STEP_LIMIT_MAX, CanvasConstants.SCALE_STEP_LIMIT_MIN)

    // Get current scale and point info
    const currentScale = stageRef.attrs.scaleX
    const scaledPoint = {
      x: (point.x - stageRef.x()) / currentScale,
      y: (point.y - stageRef.y()) / currentScale,
    }

    // Calculate new position based on scale
    const newPosition = {
      x: point.x - scaledPoint.x * computedScale,
      y: point.y - scaledPoint.y * computedScale,
    }

    const boundedNewPosition = getCanvasBoundaries({
      x: newPosition.x,
      y: newPosition.y,
      scale: computedScale,
      canvasWidth,
      canvasHeight,
      canvasViewPortWidth,
      canvasViewPortHeight,
    })

    onChangeZoom({
      x: boundedNewPosition.x,
      y: boundedNewPosition.y,
      scale: computedScale,
    })
  }

  /**
   * The wheel event occurs in both pan and zoom situations.
   */
  const handleWheel = (e: Konva.KonvaEventObject<WheelEvent>): void => {
    e.evt.stopPropagation()
    e.evt.preventDefault()

    // Determine what command this is
    if (e.evt.ctrlKey) {
      handleZoom(e)
    } else if (e.evt.shiftKey) {
      // Shift + mouse wheel/trackpad
      handleScrollPanning(e)
    } else if (e.evt.metaKey) {
      // Cmd + mouse wheel/trackpad
      handleZoom(e)
    } else {
      // This handles both the case where a pan is initiated by the mouse wheel, and by a trackpad
      handleScrollPanning(e)
    }
  }

  /**
   * Zoom in or out about the mouse pointer
   */
  const handleZoom = (e: Konva.KonvaEventObject<WheelEvent>): void => {
    e.evt.stopPropagation()
    e.evt.preventDefault()

    const scaleStep = CanvasConstants.MOUSE_ZOOM_SCALE_STEP

    // Get current scale and pointer info
    const prevScale = stageRef.attrs.scaleX
    const pointer = stageRef.getPointerPosition()

    // Zoom in or out
    const isScaleUp = e.evt.deltaY < 0

    // Calculate new scale
    const newScale = isScaleUp ? prevScale + scaleStep : prevScale - scaleStep

    // Don't zoom if at max/min level already
    if ((isAtMaxZoom && !isScaleUp) || (isAtMinZoom && isScaleUp)) {
      return
    }

    zoomToPoint(newScale, pointer)
  }

  /**
   * Zoom in to center when zoom in button is clicked
   */
  const handleZoomIn = () => {
    zoomToPoint(round(scale + CanvasConstants.SCALE_STEP, 2), relativeCenter)
  }

  /**
   * Zoom out from center when zoom out button is clicked
   */
  const handleZoomOut = () => {
    zoomToPoint(round(scale - CanvasConstants.SCALE_STEP, 2), relativeCenter)
  }

  const handleSetInitialZoom = () => {
    zoomToPoint(initialScale, relativeCenter)
  }

  /** Panning Functions **/

  const handleDragPanning = (position: ICoordinates) => {
    const positionToReturn = getCanvasBoundaries({
      x: position.x,
      y: position.y,
      scale,
      canvasWidth,
      canvasHeight,
      canvasViewPortWidth,
      canvasViewPortHeight,
    })

    changePositionDebounced(positionToReturn)

    return positionToReturn
  }

  const handleScrollPanning = (e: Konva.KonvaEventObject<WheelEvent>) => {
    const dx =
      Math.abs(e.evt.deltaX) < CanvasConstants.PAN_STEP_MAX
        ? e.evt.deltaX
        : Math.sign(e.evt.deltaX) * CanvasConstants.PAN_STEP_MAX
    const dy =
      Math.abs(e.evt.deltaY) < CanvasConstants.PAN_STEP_MAX
        ? e.evt.deltaY
        : Math.sign(e.evt.deltaY) * CanvasConstants.PAN_STEP_MAX

    updateCanvasSettings(prevSettings => ({
      ...prevSettings,
      positionX: stageRef.x() - dx,
      positionY: stageRef.y() - dy,
    }))
  }

  const handleSetInitialPosition = () => {
    changePositionDebounced(initialPosition)
  }

  return {
    initialScale,
    isAtMaxZoom,
    isAtMinZoom,
    isAtInitialZoom,
    isAtInitialPosition,
    handleZoomIn,
    handleZoomOut,
    handleWheel,
    handleZoom,
    handleSetInitialZoom,
    handleDragPanning,
    handleSetInitialPosition,
  }
}
