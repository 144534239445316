import { Button } from '@ketch-com/deck'
import React from 'react'

export interface BulkEditMidalFooteerActionsProps {
  handleClose: () => void
  confirmEdit: boolean
  setConfirmEdit: (value: boolean) => void
  selectedValue: string | any[] | undefined
  handleSave: () => void
  useApplyCopy?: boolean
}

export const BulkEditMidalFooteerActions: React.FC<BulkEditMidalFooteerActionsProps> = ({
  handleClose,
  confirmEdit,
  setConfirmEdit,
  selectedValue,
  handleSave,
  useApplyCopy,
}) => {
  return (
    <>
      <Button size="large" color="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button
        color="primary"
        size="large"
        onClick={confirmEdit ? handleSave : () => setConfirmEdit(true)}
        disabled={confirmEdit && (!selectedValue || !selectedValue.length)}
      >
        {confirmEdit ? (useApplyCopy ? 'Apply' : 'Save') : 'Edit'}
      </Button>
    </>
  )
}
