import { InfoRow, theme, Button, Icon, IconDictionary, EmptyState } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import { ReferenceLine, Bar, BarChart, Cell, XAxis, YAxis, Tooltip } from 'recharts'
import {
  OrgPlanIDs,
  OrgPlanMaxUniquesLimit,
  OrgPlanAndBillingBannerVariants,
} from 'components/planAndBilling/common/constants/planAndBilling'
import { HowWeCalculateUniquesModal } from 'components/planAndBilling/components/uniqueUsers/HowWeCalculateUniquesModal'
import { MonthlyUniques } from '@ketch-com/harbormaster/dist/organization/plans.schemas'
import { PlanAndBillingBanners } from 'components/planAndBilling/components/banners/PlanAndBillingBanners'
import pluralize from 'pluralize'
import { MaybeNull } from 'interfaces/common'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const UniqueUsers: React.FC = () => {
  const [activeFocusBar, setActiveFocusBar] = useState<MaybeNull<number>>(null)
  const [, setInActiveFocusBar] = useState(true)
  const [shouldDisplayUniquesModal, setShouldDisplayUniquesModal] = useState(false)
  const {
    orgUsage,
    orgPlan,
    isOrgOnFreePlan,
    isFreePlanInGrace,
    isFreePlanSuspended,
    isOrgOnStarterPlan,
    isStarterPlanInGrace,
    isStarterPlanSuspended,
    isOrgOnPlusPlan,
    isBillingInGrace,
    isBillingMissed,
  } = useContext(PlanAndBillingContext)

  const isPlanSuspended = isFreePlanSuspended || isStarterPlanSuspended
  const isPlanOrBillingSuspended = isPlanSuspended || isBillingMissed
  const isOrgOnPlgPlan = isOrgOnFreePlan || isOrgOnStarterPlan

  const monthlyUniques = orgUsage?.monthlyUniques || []
  let maxUsageData: MonthlyUniques = {}
  const usageData =
    monthlyUniques?.map(d => {
      const month = d?.month || 0
      const uniquesCount = d?.uniquesCount || 0
      if (!maxUsageData?.uniquesCount) maxUsageData = d
      if ((maxUsageData?.uniquesCount || 0) < uniquesCount) maxUsageData = d
      return {
        month: months?.[month - 1],
        uniques: d?.uniquesCount || 0,
        year: d?.year,
      }
    }) || []

  const oldUnique = monthlyUniques.length > 1 ? monthlyUniques[0] : {}
  const latestUnique = monthlyUniques.length ? monthlyUniques[monthlyUniques.length - 1] : {}
  const maxUnique = maxUsageData?.uniquesCount || 0

  let ticks = [0, Math.floor(maxUnique / 2), maxUnique]

  let referenceLine: MaybeNull<number> = null

  switch (orgPlan?.planProductID) {
    case OrgPlanIDs?.free:
      ticks = [0, OrgPlanMaxUniquesLimit.free, maxUnique > OrgPlanMaxUniquesLimit.free ? maxUnique : 6000]
      referenceLine = OrgPlanMaxUniquesLimit.free
      break
    case OrgPlanIDs?.starter:
      ticks = [0, OrgPlanMaxUniquesLimit.starter, maxUnique > OrgPlanMaxUniquesLimit.starter ? maxUnique : 40000]
      referenceLine = OrgPlanMaxUniquesLimit.starter
      break

    case OrgPlanIDs?.plus:
      ticks = [0, OrgPlanMaxUniquesLimit.plus, maxUnique > OrgPlanMaxUniquesLimit.plus ? maxUnique : 160000]
      referenceLine = OrgPlanMaxUniquesLimit.plus
      break

    // added here to extend chart support for plan_v5_plg_500k
    case 'plan_v5_plg_500k':
      ticks = [0, 500000, maxUnique > 500000 ? maxUnique : 600000]
      referenceLine = 500000
      break
  }

  return (
    <>
      <InfoRow title="Monthly Unique Users" titleAddendum="(Across All Properties)">
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex">
            {usageData.length && oldUnique?.month && oldUnique?.year ? (
              <Typography variant="label">
                {months[oldUnique.month - 1]} {oldUnique.year} -&nbsp;
              </Typography>
            ) : null}
            {usageData.length && latestUnique?.month && latestUnique?.year ? (
              <Typography variant="label">
                {months[latestUnique.month - 1]} {latestUnique.year}
              </Typography>
            ) : null}

            <Button
              sx={{ marginLeft: 'auto' }}
              variant="link"
              size="large"
              startIcon={<Icon name={IconDictionary.FUnknown} />}
              onClick={() => setShouldDisplayUniquesModal(true)}
            >
              How Do We Calculate Unique Users?
            </Button>
          </Box>
          {!usageData.length ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              border={`1px solid ${theme.palette.Black.o16}`}
              borderRadius="11px"
              height={300}
            >
              <EmptyState
                size="small"
                colorTheme={theme.palette.Text.Secondary}
                iconName={IconDictionary.OChart}
                title="No Data Yet"
                subTitle="You'll see the unique user count at the start of each month."
              />
            </Box>
          ) : null}

          {usageData.length ? (
            <Box position="relative">
              {isPlanOrBillingSuspended ? (
                <Box
                  position="absolute"
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border={`1px solid ${theme.palette.Black.o16}`}
                  borderRadius="11px"
                  bgcolor={theme.palette.white.main}
                  zIndex={1}
                  sx={{ opacity: 0.8 }}
                >
                  <Box display="flex" alignItems="center" gap={0.5} sx={{ opacity: 1 }}>
                    <Icon
                      name={IconDictionary.FImportant}
                      width={24}
                      height={24}
                      iconColor={theme.palette.Text.Secondary}
                    />
                    <Typography variant="label" color={theme.palette.Text.Secondary}>
                      Implementation Paused
                    </Typography>
                  </Box>
                </Box>
              ) : null}
              <BarChart
                style={{ opacity: isPlanOrBillingSuspended ? 0.2 : 1 }}
                width={700}
                height={300}
                data={usageData}
                onMouseMove={
                  !isPlanOrBillingSuspended
                    ? state => {
                        if (state.isTooltipActive && state.activeTooltipIndex !== undefined) {
                          setActiveFocusBar(state.activeTooltipIndex)
                          setInActiveFocusBar(false)
                        } else {
                          setActiveFocusBar(null)
                          setInActiveFocusBar(true)
                        }
                      }
                    : () => {}
                }
                onMouseLeave={() => {
                  setActiveFocusBar(null)
                  setInActiveFocusBar(true)
                }}
              >
                {!isPlanOrBillingSuspended ? (
                  <>
                    <XAxis dataKey="month" tickLine={false} stroke={theme.palette.Black.o48} />
                    <YAxis
                      dataKey="uniques"
                      axisLine={false}
                      tickLine={false}
                      ticks={ticks}
                      tickFormatter={number => {
                        if (number >= 1e12) return (number / 1e12).toFixed(number % 1e12 === 0 ? 0 : 1) + 't'
                        if (number >= 1e9) return (number / 1e9).toFixed(number % 1e9 === 0 ? 0 : 1) + 'b'
                        if (number >= 1e6) return (number / 1e6).toFixed(number % 1e6 === 0 ? 0 : 1) + 'm'
                        if (number >= 1e3) return (number / 1e3).toFixed(number % 1e3 === 0 ? 0 : 1) + 'k'
                        return number.toString()
                      }}
                      tickCount={3}
                      domain={[0, 'dataMax']}
                    />

                    <Tooltip
                      cursor={false}
                      wrapperStyle={{ outline: 'none' }}
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          return (
                            <Box
                              sx={{
                                padding: 1,
                                borderRadius: 1.5,
                                background: theme.palette.Black.o100,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 0.5,
                              }}
                            >
                              <Typography variant="label" color={theme.palette.white.main}>
                                {`${payload[0].value || 0} Unique  ${pluralize('User', Number(payload[0].value))}`}
                              </Typography>
                              <Typography
                                variant="body"
                                color={theme.palette.white.main}
                              >{`${payload?.[0]?.payload?.month} ${payload?.[0]?.payload?.year}`}</Typography>
                            </Box>
                          )
                        }

                        return null
                      }}
                    />
                  </>
                ) : null}

                <Bar dataKey="uniques" radius={10} barSize={10} minPointSize={8}>
                  {usageData.map((d, i) => {
                    const { uniques = 0 } = d
                    let fillColor = theme.palette.Black.o48
                    let planMaxUniques = 0

                    if (isOrgOnFreePlan) planMaxUniques = OrgPlanMaxUniquesLimit.free
                    if (isOrgOnStarterPlan) planMaxUniques = OrgPlanMaxUniquesLimit.starter
                    if (isOrgOnPlusPlan) planMaxUniques = OrgPlanMaxUniquesLimit.plus
                    if (orgPlan?.planProductID === 'plan_v5_plg_500k') planMaxUniques = 500000 // added here to support chart for 'plan_v5_plg_500k'

                    if (uniques > 0) {
                      if (activeFocusBar === i) {
                        fillColor = theme.palette.Black.o100
                      } else {
                        fillColor = theme.palette.primary.main
                      }
                    }

                    if (planMaxUniques && uniques > planMaxUniques) {
                      if (activeFocusBar === i) {
                        fillColor = theme.palette.Black.o100
                      } else {
                        fillColor = theme.palette.error.dark
                      }
                    }
                    return <Cell key={d?.month} fill={fillColor} />
                  })}
                </Bar>

                {referenceLine && !isPlanOrBillingSuspended ? (
                  <ReferenceLine y={referenceLine} stroke={theme.palette.Black.o100} />
                ) : null}
              </BarChart>
            </Box>
          ) : null}
        </Box>

        {isOrgOnPlgPlan ? (
          <>
            {isFreePlanInGrace && !isBillingMissed ? (
              <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.freePlanInGrace} />
            ) : null}
            {isFreePlanSuspended ? (
              <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.freePlanSuspended} />
            ) : null}
            {isStarterPlanInGrace && !isBillingMissed ? (
              <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.starterPlanInGrace} />
            ) : null}
            {isStarterPlanSuspended ? (
              <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.starterPlanSuspended} />
            ) : null}
            {isBillingInGrace && !isPlanSuspended ? (
              <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.billingInGrace} />
            ) : null}
            {isBillingMissed ? <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.billingMissed} /> : null}
          </>
        ) : null}
      </InfoRow>

      <HowWeCalculateUniquesModal
        isOpen={shouldDisplayUniquesModal}
        onClose={() => setShouldDisplayUniquesModal(false)}
      />
    </>
  )
}
