import { ContextVariableDTO } from '@ketch-com/figurehead'
import { ContextVariableCategoryEnum } from 'interfaces/workflows/ContextVariableCategoryEnum'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { GatewayTransitionDTO } from 'pages/orchestration/workflows/edit/interfaces/ICanvasStep'

interface IGetSplitGatewayInitialValuesParams {
  step: ICanvasStep
}

export interface IGetCommonGatewayInitialValues {
  description: string
  code: string
  transitions: Array<{
    ID?: string | number
    name: string
    variable: string
    operator: string
    operand: string
    activity: string
  }>
}

type IsSingleDecisionOptions = 'true' | 'false' // Prevent issues with radio group rendering booleans

export interface IGetDecisionGatewayInitialValues {
  code: string
  name: string
  description: string
  descriptionLabel: string
  pathSelector: string
  assignee: string
  transitions: Array<GatewayTransitionDTO>
  isSingleDecision: IsSingleDecisionOptions
  timeToCompleteDays?: number
}

export const getDecisionGatewayInitialValues = (
  params: IGetSplitGatewayInitialValuesParams,
  contextVariables: ContextVariableDTO[],
): IGetDecisionGatewayInitialValues => {
  const { step } = params
  const description = step.description || ''
  const code = step.code || ''
  const name = step.name || ''
  const descriptionLabel = step.gateway?.params?.descriptionLabel || ''
  const assignee = step.gateway?.params?.assignee || ''
  const pathSelector = step.gateway?.params?.pathSelector || 'auto'
  const transitions = step.gateway?.transitions || []
  const isSingleDecision: IsSingleDecisionOptions = step.gateway?.mode === 3 ? 'true' : 'false'
  const timeToCompleteDays = step.gateway?.params?.timeToCompleteDays || undefined

  const payload = {
    description,
    code,
    name,
    descriptionLabel,
    pathSelector,
    assignee,
    isSingleDecision,
    timeToCompleteDays,
    transitions: transitions.map(transition => {
      // String and Enum context variables need quotes appended
      // This is a backend limitation that it would be great to remove in the future
      // For now, we send a quoted string and trim the quotes in the initial form values
      // Number context variables should be stringified for similar reasons
      const relevantContextVariable = (contextVariables as any).find((cv: any) => cv.code === transition.variable)
      const operandString = transition?.operand || ''
      const operand =
        relevantContextVariable?.type === 1 ||
        relevantContextVariable?.type === 4 ||
        relevantContextVariable?.type === 'text' ||
        relevantContextVariable?.type === 'dropdown'
          ? operandString.substring(1, operandString.length - 1)
          : relevantContextVariable?.type === 2
          ? Number(operandString)
          : operandString

      return {
        ID: transition?.ID,
        name: transition?.name || '',
        description: transition?.description || '',
        variable: transition?.variable || '',
        operator: transition?.operator || '',
        operand,
        outcomeVariableStepID: transition?.outcomeVariableStepID || '',
        variableCategory: String(transition?.variableCategory || ContextVariableCategoryEnum.FORM_FIELD_VARIABLE),
        formFieldID: transition?.formFieldID,
        formFieldCode: transition?.formFieldCode,
        formFieldCategory: transition?.formFieldCategory,
      }
    }),
  }

  return payload
}
