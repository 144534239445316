import { Link } from 'react-router-dom'
import { Box, Chip } from '@mui/material'
import { ReactComponent as DatabaseIcon } from 'assets/icons/ass_list_item_database_icon_small.svg'
import { getAssetCloudImgUrl, getAssetType } from 'pages/assetManager/utils'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ReactComponent as TableIcon } from 'assets/icons/table_small.svg'
import pluralize from 'pluralize'
import { DatasetPersonalDataType } from 'pages/assetManager/dsrConfig/upsert/components/DatasetConfiguration/components'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import Typography from '@mui/material/Typography'

type Props = {
  technology: string
  database: {
    name: string
    id: string
    url?: string
  }
  dataset?: {
    name: string
    id: string
    assetsSummary: AssetSummaryDTO[]
    attributeCount: number
  }
}

export const AssetInfoNav: React.FC<Props> = ({ technology, database, dataset }) => {
  const { name: datasetName, id: datasetId, assetsSummary = [], attributeCount = 0 } = dataset || {}
  const providerCode = assetsSummary[0]?.asset?.connection?.providerCode || ''
  const attributeType = getAssetType(providerCode, 3)
  return (
    <Box display="flex" gap={1} alignItems="center">
      <Link to={database.url || ''}>
        <Box
          bgcolor="white.main"
          borderRadius={100}
          display="flex"
          justifyContent="left"
          alignItems="center"
          gap={1}
          p={2.5}
          width="fit-content"
          sx={{ cursor: 'pointer' }}
        >
          <img src={getAssetCloudImgUrl(technology)} alt={technology} width={15} height={15} />
          <DatabaseIcon />
          <Typography variant="label">{database.name}</Typography>
        </Box>
      </Link>
      {dataset ? (
        <>
          <ChevronRightIcon sx={{ fontSize: 18 }} />
          <Box
            bgcolor="white.main"
            borderRadius={100}
            display="flex"
            justifyContent="left"
            alignItems="center"
            gap={1}
            p={2}
            width="fit-content"
          >
            <img src={getAssetCloudImgUrl(technology)} alt={technology} width={15} height={15} />
            <TableIcon />
            <Typography variant="label">{datasetName}</Typography>
            <DatasetPersonalDataType datasetId={datasetId} assetsSummary={assetsSummary} />
            <Chip
              size="small"
              label={`${attributeCount} ${pluralize(attributeType, attributeCount || 0)}`}
              clickable={false}
              sx={{
                color: ({ palette }) => palette.persianBlue.main,
                backgroundColor: ({ palette }) => palette.marine.main,
              }}
            />
          </Box>
        </>
      ) : null}
    </Box>
  )
}
