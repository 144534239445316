import { useMemo } from 'react'
import { Box } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { Group } from 'components/ui-layouts/group/Group'
import { Button, theme } from '@ketch-com/deck'
import {
  AssetSummaryDTO,
  CreatePatchDSRConfigsRequestBodyDTO,
  GetDSRConfigsResponseBodyDTO,
  GetScenarioResponseBodyDTO,
  ListDsrAttributeActionsSummaryBodyDTO,
} from '@ketch-com/figurehead'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { RoutesManager } from 'utils/routing/routesManager'
import {
  getDsrConfigFormInitialValues,
  getDsrConfigFormValidationSchema,
  mapApplicationValuesToPayload,
} from 'pages/assetManager/dsrConfig/utils'
import {
  DatasetConfigurationContainer,
  RelatedDataSets,
  UserLookup,
} from 'pages/assetManager/dsrConfig/upsert/components'
import { UpsertLayout } from 'components/ui-layouts/upsertLayout/UpsertLayout'
import { UpsertLayoutFooter } from 'components/ui-layouts/upsertLayout/UpsertLayoutFooter'
import { RelatedPathsAndAssets } from 'pages/assetManager/dsrConfig/interfaces'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { FormMode } from 'interfaces/formModes/formMode'
import { DsrConfigParams } from 'utils/routing/routesV2/systems'
import { AssetInfoNav } from 'pages/dataSystems/AssetMigration/AssetInfoNav'
import { LookUpTab } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSQLTypes'

type Props = {
  isReady: boolean
  onSubmit: (obj: {
    createData: CreatePatchDSRConfigsRequestBodyDTO
    patchData: CreatePatchDSRConfigsRequestBodyDTO
  }) => void
  assetsHierarchies: ListDsrAttributeActionsSummaryBodyDTO[]
  assetsSummary: AssetSummaryDTO[]
  relatedPathsAndAssets: RelatedPathsAndAssets
  identitySpaces: IdentitySpaceDTO[]
  scenarioData: GetScenarioResponseBodyDTO
  assetsDsrConfig: GetDSRConfigsResponseBodyDTO[]
}

export const DSRConfigEdit: React.FC<Props> = ({
  isReady,
  assetsHierarchies,
  assetsSummary,
  relatedPathsAndAssets,
  identitySpaces,
  scenarioData,
  assetsDsrConfig,
  onSubmit,
}) => {
  const { scenarioId, assetId: primaryDatasetId, formMode } = useParams<DsrConfigParams>()
  const isEditMode = formMode === FormMode.EDIT
  const assetHierarchies = assetsHierarchies[0] // assetsHierarchies[0] is primary dataset
  const assetHierarchiesLength = assetHierarchies?.hierarchySummaries?.length || 0
  const resourceTypeCode = assetsSummary[0]?.asset?.resource?.resourceType?.code as string
  // @ts-ignore
  const systemId = assetsSummary[0]?.asset?.connection?.installedDataSystemID as string
  const assetAttributesSummary: AssetSummaryDTO[] = useMemo(
    () =>
      assetHierarchies?.hierarchySummaries?.filter(hierarchySummary => hierarchySummary.asset?.identitySpace?.code) ||
      [],
    [assetHierarchies?.hierarchySummaries],
  )

  assetAttributesSummary?.forEach?.(attribute => {
    const matchedIdentitySpace = identitySpaces?.find(
      identitySpace => attribute.asset?.identitySpace?.code === identitySpace.code,
    )
    const { asset = {} } = attribute
    const { identitySpace: assetIdentitySpace = {} } = asset
    if (matchedIdentitySpace) {
      assetIdentitySpace.name = matchedIdentitySpace.name
    }
  })

  // assetsSummary[0] is primary asset summary
  const { name: databaseName = '', id: databaseId = '' } =
    assetsSummary[0]?.asset?.resource?.hierarchies?.CANONICAL_RESOURCE_TYPE_DATABASE || {}
  const { name: datasetName = '', id: datasetId = '' } = assetsSummary[0]?.asset?.resource || {}

  /* If we support configuration at table level, then the cancel url should check whether to return to db asset summary or table */
  const dbUrl = RoutesManager.systems.dsrInvocation.scenarioDetails.getURL({
    assetId: databaseId,
    scenarioId: scenarioId!,
    resourceTypeCode,
    tab: LookUpTab.DIRECT,
  })
  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.id.overview.root.getURL({ id: systemId }) },
    {
      title: 'Rights Orchestration',
      link: RoutesManager.systems.id.rightsOrchestration.root.getURL({ id: systemId, assetId: datasetId }),
    },
    { title: databaseName, link: dbUrl },
    { title: `Configure: ${scenarioData?.scenario?.name}` },
  ]
  const connectionCode = assetsSummary?.[0]?.asset?.connection?.connectionCode // for now connectionCode is same for all assets
  const technology = assetsSummary?.[0]?.asset?.connection?.technology || ''

  const formInitialValue = useMemo(
    () =>
      getDsrConfigFormInitialValues({
        assetAttributesSummary,
        relatedPathsAndAssets,
        assetsDsrConfig,
        assetsHierarchies,
        isEditMode: true,
      }),
    [assetAttributesSummary, relatedPathsAndAssets, assetsDsrConfig, assetsHierarchies],
  )

  return (
    <>
      {isReady ? (
        <Box width={1280} margin="0 auto" mb={2.5} pb={2.5} borderBottom={`1px solid ${theme.palette.iron.main}`}>
          <AssetInfoNav
            database={{ name: databaseName, id: databaseId, url: dbUrl }}
            technology={technology}
            dataset={{
              name: datasetName,
              id: datasetId,
              assetsSummary: assetsSummary,
              attributeCount: assetHierarchiesLength,
            }}
          />
        </Box>
      ) : null}

      {formInitialValue ? (
        <UpsertLayout
          title=""
          breadcrumbs={breadcrumbs}
          isReady={isReady}
          initialValues={formInitialValue}
          validationSchema={getDsrConfigFormValidationSchema({
            primaryDatasetId: primaryDatasetId!,
            assetsHierarchies,
          })}
          onSubmit={values => {
            onSubmit(
              mapApplicationValuesToPayload({
                values,
                scenarioId: scenarioId!,
                relatedPathsAndAssets,
                primaryDatasetId: primaryDatasetId!,
                isEditMode,
                connectionCode,
                assetsDsrConfig,
                canonicalRightCode: scenarioData?.scenario?.canonicalRight?.code || '',
              }),
            )
          }}
        >
          {({ isSubmitting, submitForm, values, errors }) => {
            return (
              <>
                <div>
                  <UserLookup assetAttributesSummary={assetAttributesSummary} />

                  <RelatedDataSets relatedPathsAndAssets={relatedPathsAndAssets} assetsSummary={assetsSummary} />

                  <DatasetConfigurationContainer
                    assetsHierarchies={assetsHierarchies}
                    assetsSummary={assetsSummary}
                    identitySpaces={identitySpaces}
                  />
                </div>

                <UpsertLayoutFooter>
                  <Group>
                    <Link to={dbUrl}>
                      <Button color="secondary" size="large" pending={isSubmitting}>
                        Cancel
                      </Button>
                    </Link>
                    <Button size="large" onClick={submitForm} pending={isSubmitting}>
                      Save
                    </Button>
                  </Group>
                </UpsertLayoutFooter>
              </>
            )
          }}
        </UpsertLayout>
      ) : (
        <Spinner />
      )}
    </>
  )
}
