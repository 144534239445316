import { DropListButtonProps, Icon, ListItemText, TextInput, TextInputProps, theme } from '@ketch-com/deck'
import { CategoryV3DTO, DataTypeDTO } from '@ketch-com/figurehead'
import { Box } from '@mui/material'
import { useState } from 'react'
import { useFilteredOptions } from '../../../hooks/useFilteredOptions'

export interface BulkEditClassificationDropListProps extends DropListButtonProps<any, boolean, true, boolean> {
  assigning?: boolean
  handleClassificationAssignment: (category: CategoryV3DTO, assigning?: boolean) => void
  inputProps?: TextInputProps['inputProps']
  options: CategoryV3DTO[] | DataTypeDTO[]
}

export const BulkEditClassificationDropList: React.FC<BulkEditClassificationDropListProps> = ({
  assigning,
  handleClassificationAssignment,
  inputProps,
  options,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState<string | undefined | null>()

  const filteredOptions = useFilteredOptions(options, inputValue)

  return (
    <Box
      display="flex"
      flexDirection="column"
      border={`1px solid ${theme.palette.Common.Divider}`}
      borderRadius="5px"
      sx={{ width: '420px', overflow: 'hidden' }}
    >
      <Box
        width="100%"
        padding={2}
        borderRadius="5px 5px 0px 0px"
        sx={{ backgroundColor: theme.palette.Common.AppBackground }}
      >
        <TextInput
          value={inputValue}
          placeholder={placeholder}
          fullWidth
          startIcon={<Icon name="OMag" />}
          inputProps={{
            ...inputProps,
            onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              setInputValue(event.target.value),
            value: inputValue,
          }}
        />
      </Box>
      <Box paddingY={0.5} sx={{ width: '415px', height: '304px', overflow: 'auto' }}>
        {filteredOptions.map(option => (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            key={option.id}
            borderRadius="5px"
            paddingY={0.5}
            paddingX={1}
            marginX="1px"
            sx={{
              cursor: 'pointer',
              width: '416px',
              '&:hover': { backgroundColor: theme.palette.Common.AppBackground },
            }}
            onClick={() => {
              handleClassificationAssignment(option, assigning)
              setInputValue('')
            }}
          >
            <Icon name={assigning ? 'FPlusRound' : 'FMinusRound'} />
            <ListItemText sx={{ marginLeft: 1 }}>{option.name}</ListItemText>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
