import { createContext } from 'react'
import { CustomSqlUtils } from 'pages/assetManager/scenario/details/components/CustomSql/interfaces'
import { useCustomSqlUtils } from 'pages/assetManager/scenario/details/components/CustomSql/hooks'

/**
 * @deprecated
 */
export const CustomSqlContext = createContext({} as CustomSqlUtils)

type Props = {
  children?: React.ReactNode
}

/**
 * @deprecated
 * @param children
 * @constructor
 */
export const CustomSqlContextProvider: React.FC<Props> = ({ children }) => {
  const hookValues = useCustomSqlUtils()

  return (
    <CustomSqlContext.Provider
      value={{
        ...hookValues,
      }}
    >
      {children}
    </CustomSqlContext.Provider>
  )
}

/**
 * @deprecated
 * @param Component
 */
export const withCustomSqlContext = (Component: React.FC<any>) => {
  return (props: any) => {
    return (
      <CustomSqlContextProvider>
        <Component {...props} />
      </CustomSqlContextProvider>
    )
  }
}
