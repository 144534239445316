import { Avatar, AvatarSize, CustomIconName, Icon, theme } from '@ketch-com/deck'
import { Box, styled, Typography } from '@mui/material'
import { FilterContextProps } from '../context/AssetsTabFilterContext'

const CloseButtonBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 28,
  height: 42,
  backgroundColor: theme.palette.Black.o8,
  borderRadius: 5,
  cursor: 'pointer',
  marginRight: '4px',
})

const DrilldownTrackerItemContentContainer = styled(Box)({
  display: 'flex',
  height: 60,
  paddingY: 8,
  paddingX: 4,
  gap: 8,
  alignItems: 'center',
  maxWidth: '100%',
  overflow: 'hidden',
  cursor: 'pointer',
})

interface DrillDownTrackerProps {
  onClose?: () => void
  drillDownLineage: FilterContextProps['drillDownLineage']
  traverseLineage: FilterContextProps['traverseLineage']
  handleClearDrilldown: FilterContextProps['handleClearDrilldown']
  setCurrentResourceLevel: (resourceType: string) => void
}

export const DrillDownTracker: React.FC<DrillDownTrackerProps> = ({
  onClose,
  drillDownLineage,
  traverseLineage,
  handleClearDrilldown,
  setCurrentResourceLevel,
}) => {
  return (
    <Box display="flex" marginY={1} gap={1.5} alignItems="center">
      <CloseButtonBox
        onClick={() => {
          handleClearDrilldown()
          if (onClose) {
            onClose()
          }
        }}
      >
        <Icon name="OCross" />
      </CloseButtonBox>
      {drillDownLineage.map((item, idx) => (
        <Box
          key={item.displayDetails.resourceType}
          display="flex"
          maxWidth="100%"
          alignItems="center"
          gap={1}
          overflow="hidden"
        >
          <DrilldownTrackerItemContentContainer
            onClick={() => {
              if (idx === 0) {
                traverseLineage(idx)
                if (typeof drillDownLineage[idx].payload.params.filters?.resourceType === 'string') {
                  setCurrentResourceLevel(drillDownLineage[idx].payload.params.filters?.resourceType as string)
                }
              } else {
                traverseLineage(idx)
              }
            }}
          >
            <Avatar size={AvatarSize.medium} icon={item.displayDetails.iconName as CustomIconName} variant="circular" />
            <Box display="flex" flexDirection="column" maxWidth="calc(100% - 30px)" flex={1}>
              <Typography
                variant="label"
                component="div"
                noWrap
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                sx={{ maxWidth: '100%' }}
              >
                {item.displayDetails.name}
              </Typography>
              <Box>
                <Typography variant="smallLabel" color={theme.palette.Text.Secondary}>
                  {item.displayDetails.resourceType}
                </Typography>

                <Typography variant="footnote">{` | ${item.displayDetails.childResourceType} ${item.displayDetails.childResourceCount}`}</Typography>
              </Box>
            </Box>
          </DrilldownTrackerItemContentContainer>

          {idx !== drillDownLineage.length - 1 && <Icon name="OArrowCRight" iconColor={theme.palette.Common.Divider} />}
        </Box>
      ))}
    </Box>
  )
}
