import { Chip, Icon } from '@ketch-com/deck'
import { AvatarEntity, UserAvatar } from 'components/avatar/UserAvatar'
import { UserShortDTO } from 'interfaces/users/users'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'
import { useIsPermitted } from 'utils/hooks'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import React, { useCallback, useState } from 'react'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'

type TeamChipProps = {
  user: AvatarEntity | UserShortDTO
  secondaryLabel?: string
  clickable?: boolean
}

export const UserChip: React.FC<TeamChipProps> = ({ user, secondaryLabel, clickable }) => {
  const [avatarEntity, setAvatarEntity] = useState<AvatarEntity | null>()
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const isAdmin = isPermitted(PERMISSIONS.USER_ADMIN)

  React.useMemo(() => {
    const x =
      'name' in user
        ? (user as AvatarEntity)
        : {
            id: user.ID,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            name: getFullNameUserShortDTO(user as UserShortDTO),
          }
    setAvatarEntity(x)
  }, [user])

  const onClick = useCallback(() => {
    navigate(RoutesManager.settings.users.view.root.getURL({ id: avatarEntity?.id }))
  }, [avatarEntity, navigate])

  if (!avatarEntity) return null

  return (
    <Chip
      key={avatarEntity.id}
      icon={<UserAvatar sx={{ ml: 1 }} user={avatarEntity} />}
      label={avatarEntity.name}
      secondaryLabel={secondaryLabel}
      onClick={isAdmin ? onClick : undefined}
      onDelete={isAdmin ? onClick : undefined}
      deleteIcon={isAdmin ? <Icon name="OArrowCRight" /> : undefined}
      clickable={clickable}
    />
  )
}
