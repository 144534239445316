import { useContext } from 'react'
import { CustomSqlContext } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSqlContext'
import { SCRIPT_TYPE } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSQLTypes'

export const useCustomSqlInitialValues = () => {
  const { customSqls = [], customSqlModalData } = useContext(CustomSqlContext)

  const selectedCustomSql = customSqls.filter(sql => sql.id === customSqlModalData?.customSqlId)?.[0]

  return {
    identitySpaceCode: selectedCustomSql?.identitySpaceCode || '',
    // Type should resolve on API update
    // @ts-ignore
    identitySpaceCodes: selectedCustomSql?.identitySpaceCodes || [],
    name: selectedCustomSql?.name || '',
    sqlQuery: selectedCustomSql?.sqlQuery || '',
    id: selectedCustomSql?.id || '',
    sqlQueries: selectedCustomSql?.sqlQueries || [],
    scriptType: selectedCustomSql?.scriptType || SCRIPT_TYPE.SINGLE,
  }
}
