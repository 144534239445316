import { BulkEditModalBaseProps, BulkEditModalType } from '../../types'
import { BulkEditCategoriesModal } from './BulkEditCategoriesModal'
import { BulkEditDataOwnerModal } from './BulkEditDataOwnerModal'
import { BulkEditDataTypesModal } from './BulkEditDataTypesModal'
import { BulkEditSensitivityModal } from './BulkEditSensitivityModal'

interface AssetsTableBulkEditModalProps {
  modalType: BulkEditModalType
  modalProps: BulkEditModalBaseProps
}

export const AssetsTableBulkEditModal: React.FC<AssetsTableBulkEditModalProps> = ({ modalType, modalProps }) => {
  if (modalType === BulkEditModalType.dataSensitivity) return <BulkEditSensitivityModal {...modalProps} />
  if (modalType === BulkEditModalType.dataCategories) return <BulkEditCategoriesModal {...modalProps} />
  if (modalType === BulkEditModalType.dataTypes) return <BulkEditDataTypesModal {...modalProps} />
  if (modalType === BulkEditModalType.dataOwner) return <BulkEditDataOwnerModal {...modalProps} />
  return null
}
