import React, { useContext, useState } from 'react'
import {
  Button,
  Icon,
  MegaFilter,
  MegaFilterContext,
  MegaFilterOptionType,
  MegaFilterSelectedValuesType,
} from '@ketch-com/deck'
import { CheckboxSection } from './utils/CheckboxSection'
import { Box, Typography } from '@mui/material'
import { CountryWithCodeType } from './utils/utils'
import { ChipsSection } from './utils/ChipsSection'

const sections = [
  {
    value: 'chip_section',
    label: 'Chip Section',
  },
  {
    value: 'cc_section',
    label: 'Country codes Section',
  },
]

export const MegaFilterExample: React.FC<{}> = () => {
  const [selectedData, setSelectedData] = useState<MegaFilterSelectedValuesType>({
    [sections[1].value]: [],
  })

  const checkboxSectionKey = sections[1].value

  const handleUpdateSelectedCountryData = (option: CountryWithCodeType) => {
    const currentData = selectedData[checkboxSectionKey]
    setSelectedData({
      ...selectedData,
      [checkboxSectionKey]: [
        ...currentData,
        { value: option.value, category: checkboxSectionKey, secondaryLabel: option.code },
      ],
    })
  }

  const handleDeleteSelectedCountryData = (option: CountryWithCodeType) => {
    const currentData = selectedData[checkboxSectionKey]
    setSelectedData({
      ...selectedData,
      [checkboxSectionKey]: [...currentData.filter(data => data.value !== option.value)],
    })
  }

  const handleDeselectChip = (option: MegaFilterOptionType) => {
    const currentData = selectedData[option.category]
    setSelectedData({
      ...selectedData,
      [option.category]: [...currentData.filter(data => data.value !== option.value)],
    })
  }

  // 2 case where you can use the MegaFilter,
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {/* one where you let the component handle the data for you,
       by using the MegaFilter's Context */}
      <MegaFilter
        showSelectionCountSection
        totalFilteredItemsNumber={9}
        buttonTitle="Mega Filter"
        sections={sections}
        renderActionItems={<ButtonSection />}
        buttonProps={{
          endIcon: <Icon name="OFilter" sx={{ marginLeft: 0.5 }} />,
        }}
      >
        <ChipsSection sectionKey={sections[0].value} />
        <CheckboxSection sectionKey={sections[1].value} />
      </MegaFilter>
      {/* another one where you update the data outside of the component, and you pass that data using the 
      "value" prop */}
      <MegaFilter
        value={selectedData}
        buttonProps={{
          endIcon: <Icon name="OFilter" sx={{ marginLeft: 0.5 }} />,
        }}
        buttonTitle="Controlled Mega Filter"
        sections={sections}
        onDeselectChipHandler={handleDeselectChip}
      >
        <CheckboxSection
          sectionKey={sections[1].value}
          // added this prop so I don't have to create the same component twice,
          // with the only difference being how the update of data is handled
          isControlledComponent
          handleControlledDelete={handleDeleteSelectedCountryData}
          handleControlledUpdate={handleUpdateSelectedCountryData}
        />
      </MegaFilter>
      <Typography>Controlled data</Typography>
      {JSON.stringify(selectedData)}
    </Box>
  )
}

const ButtonSection = () => {
  const { handleClearAll } = useContext(MegaFilterContext)
  return (
    <>
      <Button color="tertiary" onClick={() => handleClearAll()}>
        Clear all
      </Button>
    </>
  )
}
