import { useMemo } from 'react'
import * as Yup from 'yup'

import { useIsAvailableHandler } from 'utils/helpers/validators/useIsAvailableHandler'
import { validationRegExp } from 'utils/constants/forms/validationRegExp'
import { useUserMe } from 'api/users/queries/useUserMe'

const orgsAllowingCodesStartingWithNumbers: string[] = ['mtb', 'mtb_sandbox']

export const useCodeValidationSchema = <Handler extends (value: string) => Promise<boolean>>({
  handler,
  wait,
}: {
  handler: Handler
  wait?: number
}) => {
  const handleCheckIsCodeAvailable = useIsAvailableHandler({ handler, wait })

  const user = useUserMe()

  const allowCodesStartingWithNumbers = useMemo(() => {
    return orgsAllowingCodesStartingWithNumbers.includes(user?.data?.organization?.code ?? '')
  }, [user?.data?.organization?.code])

  return useMemo(() => {
    const syncValidations = Yup.string()
      .min(2, 'Code must be at least 2 characters')
      .max(100, 'Code must be a maximum of 100 characters')
      .matches(
        allowCodesStartingWithNumbers ? validationRegExp.ANYTHING : validationRegExp.START_FROM_UPPER_OR_LOWER_CASE,
        'Code must start with an upper or lowercase letter',
      )
      .matches(
        validationRegExp.ONLY_LOWERCASE_CHARS_NUMBERS_AND_UNDERSCORE_AND_COLON,
        'Code must only use uppercase and lowercase characters, numbers, underscores and colons',
      )
      .required('Please input a code')

    return syncValidations.test('Is Unique', 'Oops, the code you are using is not unique', async (value?: string) => {
      try {
        // As Yup runs all validations at the same time
        // We run async validation only if sync validations passed
        await Yup.object().shape({ code: syncValidations }).validate({ code: value })
        return handleCheckIsCodeAvailable(value)
      } catch (e) {
        return true
      }
    })
  }, [handleCheckIsCodeAvailable, allowCodesStartingWithNumbers])
}
