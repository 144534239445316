import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { NoDirectIdentityLookup } from 'pages/assetManager/scenario/details/components'
import { useV2OrV3DatabaseDatasetSummariesInfiniteBasedOnFeatureFlag } from 'api/assets/queries/useV2OrV3DatabaseDatasetSummariesInfiniteBasedOnFeatureFlag'
import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { useSkipDatasetFromScenario } from 'api/assets/mutations/useSkipDatasetFromScenario'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { GetDSRConfigCountResponseBodyDTO } from '@ketch-com/figurehead'
import { UrlParams } from 'pages/assetManager/scenario/interfaces'
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag'
import { FEATURE_FLAGS, FEATURE_FLAGS_VALUES } from 'interfaces/featureFlags'

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
}

/**
 * @deprecated
 */
export const NoDirectIdentityLookupContainer: React.FC<Props> = ({ assetSummary, dsrConfigCount }) => {
  const { isFeatureFlagEnabled } = useFeatureFlag()
  const isV2FeatureFlagEnabled = isFeatureFlagEnabled(
    FEATURE_FLAGS.ASSET_MANAGER_VERSION,
    FEATURE_FLAGS_VALUES.ASSET_MANAGER_VERSION_2,
  )
  const { scenarioId } = useParams<UrlParams>()
  const queryClient = useQueryClient()
  const [searchQuery, setSearchQuery] = useState('')
  const {
    data: assets,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useV2OrV3DatabaseDatasetSummariesInfiniteBasedOnFeatureFlag({
    params: {
      assetId: assetSummary?.asset?.resource?.id,
      limit: 10,
      query: searchQuery,
      identityRelationType: 2,
      isPersonal: true,
      scenarioId,
    },
  })

  const invalidateDatabaseDatasetSummariesPaginated = isV2FeatureFlagEnabled
    ? ApiQueryKeys.databaseDatasetSummariesPaginatedV2
    : ApiQueryKeys.databaseDatasetSummariesPaginatedV3

  const { mutateAsync: skipDataset } = useSkipDatasetFromScenario({
    onSuccess: async () => {
      await queryClient.invalidateQueries([invalidateDatabaseDatasetSummariesPaginated])
      await queryClient.invalidateQueries([ApiQueryKeys.assetDsrConfigCount])
      showToast({ content: 'Skipped dataset configuration', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Unable to skip dataset configuration', type: 'error' })
    },
  })

  return (
    <NoDirectIdentityLookup
      assets={assets}
      isLoading={isLoading}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      setSearchQuery={setSearchQuery}
      skipDataset={skipDataset}
      dsrConfigCount={dsrConfigCount}
    />
  )
}
