import { Box, Typography } from '@mui/material'
import { SendbirdGroupChannel } from 'components/Sendbird/SendbirdGroupChannel/SendbirdGroupChannel'
import React, { useContext } from 'react'
import { RightsQueueViewContext } from '../../context/RightsQueueViewContext'
import { Button, Icon, theme } from '@ketch-com/deck'
import { useDownloadFile } from 'api/files/mutations/useDownloadFile'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { SubjectDetailsCard } from 'pages/orchestration/rightsQueue/viewV2/components/SubjectCommunicationTab/components/SubjectDetailsCard'

/**
 * Subject communication tab using Sendbird.
 *
 * Initializes our group channel component within a SendbirdContext. We do this here
 * instead of on the top level of the App so that we don't unnecessarily incur costs for creating new
 * Sendbird users.
 */
export const SendbirdSubjectCommunicationTab: React.FC = () => {
  const { rightInvocation, rightInvocationId, isReadyWithoutTwilioClient, isReadyToLoadChat } =
    useContext(RightsQueueViewContext)

  // Same logic from https://github.com/ketch-com/figurehead/pull/3663
  const isChatInputDisabled = rightInvocation?.status === 'fulfilled' || rightInvocation?.status === 'rejected'

  const { mutateAsync: handleDownloadFile, isLoading: isDownloadingFile } = useDownloadFile()

  if (!isReadyWithoutTwilioClient || !isReadyToLoadChat) return null

  if (!rightInvocation?.channelURL) {
    console.error('No channel URL on right invocation')
    return null
  }

  return (
    <>
      <Box display="flex" gap="16px" width="100%">
        <Box
          display="flex"
          width="100%"
          height="65vh"
          maxWidth="846px"
          sx={{
            // Hide the sendbird image preview modal which breaks our UI
            '.sendbird-modal-root': { display: 'none' },
          }}
        >
          <SendbirdGroupChannel
            channelUrl={rightInvocation.channelURL}
            // Hide the chat header
            groupChannelProps={{
              renderChannelHeader: () => <></>,
              renderMessageInput: isChatInputDisabled
                ? () => (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      borderTop={`1px solid ${theme.palette.Common.Divider}`}
                    >
                      <Typography variant="label" pt={3}>
                        Thread Closed
                      </Typography>

                      {isRelease(Release.SendbirdChat) && (
                        <Button
                          variant="contained"
                          color="tertiary"
                          pending={isDownloadingFile}
                          startIcon={<Icon name="OShare" />}
                          onClick={async () => {
                            const { data } = await handleDownloadFile({
                              params: {
                                fileUrl: `/api/v2/files?bucket=&folder=chat-archive&key=ChatArchive.zip&version=${rightInvocationId}&generatePresignedURL=true`,
                                responseType: 'json',
                              },
                            })
                            window.open(data?.object?.internalUrl, '_blank')
                          }}
                          sx={{ width: 'fit-content', mt: 1 }}
                        >
                          Export Conversation
                        </Button>
                      )}
                    </Box>
                  )
                : undefined,
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" maxWidth="358px">
          <SubjectDetailsCard rightInvocation={rightInvocation} />
        </Box>
      </Box>
    </>
  )
}
