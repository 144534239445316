/**
 * @deprecated
 */
export enum LookUpTab {
  DIRECT = 'direct_lookup',
  NO_DIRECT = 'no_direct_lookup',
  CUSTOM_SQL = 'custom_sql',
}

/**
 * @deprecated
 */
export const SCRIPT_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
} as const

/**
 * @deprecated
 */
export const SCRIPT_TYPE_VALUES = {
  single: { key: 'single', label: 'Single Script' },
  multiple: { key: 'multiple', label: 'Multiple Scripts' },
}
