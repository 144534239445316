import { useNavigate, useParams } from 'react-router-dom'
import { Box, Chip } from '@mui/material'
import { Text } from 'components/ui-kit/typography/Text'
import { ReactComponent as DatabaseIcon } from 'assets/icons/ass_list_item_database_icon_small.svg'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ReactComponent as TableIcon } from 'assets/icons/table_small.svg'
import pluralize from 'pluralize'
import { DatasetPersonalDataType } from 'pages/assetManager/dsrConfig/upsert/components/DatasetConfiguration/components'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { getAssetType } from 'pages/assetManager/utils'
import { useDsrConfigInitiator } from 'pages/assetManager/hooks'
import { RoutesManager } from 'utils/routing/routesManager'
import { getURLParams } from 'utils/routing/routes/utils'

type Props = {
  technology: string
  database: {
    name: string
    id: string
  }
  dataset?: {
    name: string
    id: string
    assetsSummary: AssetSummaryDTO[]
    attributeCount: number
  }
}

/**
 * @deprecated
 * @param technology
 * @param database
 * @param dataset
 * @constructor
 */
export const AssetInfoNav: React.FC<Props> = ({ technology, database, dataset }) => {
  const navigate = useNavigate()
  const { initiatorIsDatabase } = useDsrConfigInitiator()
  const { assetType } = useParams<getURLParams>()
  const { name: databaseName, id: databaseId } = database
  const { name: datasetName, id: datasetId, assetsSummary = [], attributeCount = 0 } = dataset || {}

  const providerCode = assetsSummary[0]?.asset?.connection?.providerCode || ''
  const attributeType = getAssetType(providerCode, 3)

  return (
    <Box display="flex" gap={1} alignItems="center">
      <Box
        bgcolor="white.main"
        borderRadius={100}
        display="flex"
        justifyContent="left"
        alignItems="center"
        gap={1}
        p={2.5}
        width="fit-content"
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          if (initiatorIsDatabase) {
            navigate(
              RoutesManager.assetManager.assets.detail.dsrConfiguration.getURL({
                code: databaseId,
                resourceTypeCode: assetType,
              }),
            )
          } else {
            navigate(
              RoutesManager.assetManager.assets.detail.dsrConfiguration.getURL({
                code: datasetId,
                resourceTypeCode: assetType,
              }),
            )
          }
        }}
      >
        <img src={getAssetCloudImgUrl(technology)} alt={technology} width={15} height={15} />
        <DatabaseIcon />
        <Text size={14} weight={600}>
          {databaseName}
        </Text>
      </Box>

      {dataset ? (
        <>
          <ChevronRightIcon sx={{ fontSize: 18 }} />

          <Box
            bgcolor="white.main"
            borderRadius={100}
            display="flex"
            justifyContent="left"
            alignItems="center"
            gap={1}
            p={2}
            width="fit-content"
          >
            <img src={getAssetCloudImgUrl(technology)} alt={technology} width={15} height={15} />
            <TableIcon />
            <Text size={14} weight={600}>
              {datasetName}
            </Text>
            <DatasetPersonalDataType datasetId={datasetId} assetsSummary={assetsSummary} />
            <Chip
              size="small"
              label={`${attributeCount} ${pluralize(attributeType, attributeCount || 0)}`}
              clickable={false}
              sx={{
                color: ({ palette }) => palette.persianBlue.main,
                backgroundColor: ({ palette }) => palette.marine.main,
              }}
            />
          </Box>
        </>
      ) : null}
    </Box>
  )
}
