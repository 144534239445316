import { Box, Typography, styled } from '@mui/material'
import {
  BadgeDovetail,
  BadgeDovetailVariant,
} from 'pages/dataSystems/Classification/components/BadgeDovetail/BadgeDovetail'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import { TooltipV2 } from 'components/ui-kit/tooltip/TooltipV2'

const Body = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '.25rem',
}))

type Props = {
  name?: string
  description?: string
  variant?: BadgeDovetailVariant
  handleClose?: () => void
}

export const ManualBadge: React.FC<Props> = ({ variant, name, description, handleClose }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleIsOpen = () => {
    setIsOpen(prevValue => !prevValue)
  }

  return (
    <TooltipV2
      title={<Box style={{ width: '250px' }}>{description}</Box>}
      arrow={false}
      open={isOpen}
      onClick={() => (description ? handleIsOpen() : null)}
      onMouseOver={() => (description ? setIsOpen(true) : null)}
      onMouseLeave={() => (description ? setIsOpen(false) : null)}
    >
      <Box>
        <BadgeDovetail variant={variant}>
          <Body>
            <Typography variant="subtitle2">{name}</Typography>
            {handleClose && <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />}
          </Body>
        </BadgeDovetail>
      </Box>
    </TooltipV2>
  )
}
