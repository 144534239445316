import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { ScenarioDetailsRoutes } from 'pages/assetManager/scenario/details/ScenarioDetailsRoutes'
import { TabsRoutesConfig } from 'components/ui-kit/tabs/TabsRoutes'
import { RoutesManager } from 'utils/routing/routesManager'
import { useParams } from 'react-router-dom'
import { LookUpTab } from 'pages/assetManager/scenario/details/constants'
import { ViewLayout } from 'components/ui-layouts/viewLayout/ViewLayout'
import { ViewLayoutContent } from 'components/ui-layouts/viewLayout/ViewLayoutContent'
import { AssetSummaryDTO, GetDSRConfigCountResponseBodyDTO } from '@ketch-com/figurehead'
import { UrlParams } from 'pages/assetManager/scenario/interfaces'
import { Text } from 'components/ui-kit/typography/Text'
import { Button } from '@ketch-com/deck'
import { ConfigurationProgress } from 'pages/assetManager/scenario/components'
import { AssetInfoNav } from 'pages/assetManager/dsrConfig/upsert/components'
import { getAssetType } from 'pages/assetManager/utils'
import pluralize from 'pluralize'
import { useState } from 'react'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ImportConfigurationModal } from './components/ImportConfigurationModal'
import { useIsEntitled } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

type Props = {
  assetSummary: AssetSummaryDTO
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
}

/**
 * @deprecated
 * @param assetSummary
 * @param dsrConfigCount
 * @constructor
 */
export const ScenarioDetails: React.FC<Props> = ({ assetSummary, dsrConfigCount }) => {
  const navigate = useNavigate()
  const { scenarioId, id, resourceTypeCode = '' } = useParams<UrlParams>()
  const [showModal, setShowModal] = useState(false)

  const { isEntitled } = useIsEntitled()
  const isDemo = isEntitled(ENTITLEMENTS.DEMO)

  const providerCode = assetSummary?.asset?.connection?.providerCode || ''
  const datasetType = getAssetType(providerCode, 2)
  const technology = assetSummary.asset?.connection?.technology || ''
  const { name: databaseName = '', id: databaseId = '' } = assetSummary.asset?.resource || {}
  const { personalAssets = 0, dsrScenario = {}, dsrConfigurationCount = {} } = dsrConfigCount
  const { totalActions = 0 } = dsrConfigurationCount[scenarioId!] || {}
  const currentScenario = dsrScenario[scenarioId!] || {}

  const databaseTabs: TabsRoutesConfig[] = [
    {
      title: 'Direct Lookup',
      shouldShowCount: true,
      link: {
        pathname: `${RoutesManager.assetManager.assets.detail.scenarioDetailsTab.getURL({
          id,
          scenarioId,
          tab: LookUpTab.DIRECT,
          resourceTypeCode,
        })}`,
      },
    },
    {
      title: 'No Direct Lookup',
      shouldShowCount: true,
      link: {
        pathname: `${RoutesManager.assetManager.assets.detail.scenarioDetailsTab.getURL({
          id,
          scenarioId,
          tab: LookUpTab.NO_DIRECT,
          resourceTypeCode,
        })}`,
      },
    },
    {
      title: 'Custom SQL',
      shouldShowCount: true,
      link: {
        pathname: `${RoutesManager.assetManager.assets.detail.scenarioDetailsTab.getURL({
          id,
          scenarioId,
          tab: LookUpTab.CUSTOM_SQL,
          resourceTypeCode,
        })}`,
      },
    },
  ]

  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.assetManager.assets.root.getURL() },
    { title: 'Assets', link: RoutesManager.assetManager.assets.list.getURL() },
    { title: `Configure: ${currentScenario.name}` },
  ]

  return (
    <>
      <Box mt={-3} bgcolor="white.main" width="100%">
        <Box width={1280} margin="0 auto" display="flex" justifyContent="space-between" alignItems="center" py={1.5}>
          <Text size={20} weight={700}>
            {currentScenario.name}
          </Text>
          <Box display="flex" justifyContent="right" alignItems="center">
            <ConfigurationProgress progress={Math.round((totalActions / personalAssets) * 100)} small />
            <Text size={16} weight={700} ml={1} mr={3}>
              {totalActions}/{personalAssets} {pluralize(datasetType, personalAssets)} Configured
            </Text>
            <Button
              color="primary"
              onClick={() => {
                navigate(
                  RoutesManager.assetManager.assets.detail.dsrConfiguration.getURL({ code: id, resourceTypeCode }),
                )
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Box>
      <Box width={1280} margin="0 auto" mt={2} mb={2}>
        <AssetInfoNav database={{ name: databaseName, id: databaseId }} technology={technology} />
      </Box>
      <ViewLayout breadcrumbs={breadcrumbs} breadCrumbsType="light">
        <Box mt={2} mb={4} ml={2.5} mr={2.5} display="flex" justifyContent="space-between">
          <Box>
            <Box>
              <Text size={20} weight={700} mr={1}>
                Configure {pluralize(datasetType, personalAssets)}
              </Text>
            </Box>
            <Text size={14} color="grey">
              Browse and select the {datasetType} you want to configure for a "{currentScenario.name}" event.
            </Text>
          </Box>
          {isDemo ? (
            <Box display="flex" gap={2} mt={-1.5} mr={-2}>
              <Button
                color="tertiary"
                onClick={() => showToast({ type: 'info', content: 'Downloading configuration...' })}
              >
                Export Configuration
              </Button>
              <Button color="tertiary" onClick={() => setShowModal(true)}>
                Import Configuration
              </Button>
            </Box>
          ) : null}
        </Box>
        <ViewLayoutContent tabs={databaseTabs}>
          <ScenarioDetailsRoutes dsrConfigCount={dsrConfigCount} />
        </ViewLayoutContent>
      </ViewLayout>
      {showModal ? <ImportConfigurationModal onCancel={() => setShowModal(false)} /> : null}
    </>
  )
}
