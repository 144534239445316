import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { ListAssetItemDTO } from '@ketch-com/figurehead'
import { useCallback, useState } from 'react'
import { FilterContextProps } from '../context/AssetsTabFilterContext'
import { AssetsTableActionModalType, ClassificationList } from '../../types'

export interface AssetsTabTableRowEditMenuProps {
  row: ListAssetItemDTO
  triggerModal: FilterContextProps['setShowEditModal']
}

interface MenuOption {
  label: string
  value: string
}

const dataSensitivity: MenuOption = { label: 'Edit Data Sensitivity', value: 'dataSensitivities' }
const dataCategory: MenuOption = { label: 'Edit Data Categories', value: 'dataCategories' }
const dataType: MenuOption = { label: 'Edit Data Types', value: 'dataTypes' }
const dataOwner: MenuOption = { label: 'Edit Data Owner', value: 'dataOwner' }
const schedule: MenuOption = { label: 'Edit Schedule', value: 'classificationSchedule' }

const menuOptionsByCanonnicalType: Record<string, MenuOption[]> = {
  CANONICAL_RESOURCE_TYPE_DATABASE: [dataOwner, dataSensitivity, dataCategory, schedule],
  CANONICAL_RESOURCE_TYPE_SCHEMA: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_DATASET: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_OBJECT: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_FOLDER: [dataSensitivity, dataCategory],
  CANONICAL_RESOURCE_TYPE_FILE: [dataSensitivity, dataCategory, dataType],
  CANONICAL_RESOURCE_TYPE_ATTRIBUTE: [dataSensitivity, dataCategory, dataType],
}

export const AssetsTabTableRowEditMenu: React.FC<AssetsTabTableRowEditMenuProps> = ({ row, triggerModal }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuItems = menuOptionsByCanonnicalType[row.resource!.canonicalResourceType!]

  const handleSelectTriggerType = useCallback(
    (item: MenuOption) => {
      if (item.value === 'classificationSchedule') {
        triggerModal({
          editModalType: AssetsTableActionModalType.EditSampling,
          modalProps: {
            assetCode: row.resource!.id!,
            resourceTypeCode: row.resource!.resourceType!,
            schedulingData: row.schedule,
            connectionId: row.resource?.connection?.id,
            onClose: () => {
              triggerModal(undefined)
            },
          },
        })
      } else if (item.value === 'dataOwner') {
        triggerModal({
          editModalType: AssetsTableActionModalType.editDataOwner,
          modalProps: {
            assetCode: row.resource?.id!,
            resourceTypeCode: row.resource?.resourceType!,
            dataOwner: row.resource?.dataOwner,
            onClose: () => {
              triggerModal(undefined)
            },
          },
        })
      } else if (item.value === 'dataSensitivities') {
        triggerModal({
          editModalType: AssetsTableActionModalType.editSensitivity,
          modalProps: {
            canonicalResourceType: row.resource!.canonicalResourceType!,
            resourceName: row.resource!.name || '',
            sensitivityClassification: row.dataSensitivities,
            resourceType: row.resource?.resourceType,
            assetIds: [row.resource?.id],
            onClose: () => {
              triggerModal(undefined)
            },
          },
        })
      } else {
        const keyVal = item.value as keyof ListAssetItemDTO
        if (row[keyVal]) {
          triggerModal({
            editModalType:
              keyVal === 'dataCategories'
                ? AssetsTableActionModalType.editDataCategory
                : AssetsTableActionModalType.editDataType,
            modalProps: {
              canonicalResourceType: row.resource!.canonicalResourceType!,
              resourceName: row.resource!.name || '',
              classificationName: item.label,
              classificationType: keyVal,
              classificationList: row[keyVal] as ClassificationList,
              resourceType: row.resource?.resourceType,
              assetIds: [row.resource?.id],
              onClose: () => {
                triggerModal(undefined)
              },
            },
          })
        }
      }
      handleClose()
    },
    [row, triggerModal],
  )

  return (
    <>
      <Button variant="icon" color="ghost" onClick={handleButtonClick}>
        <Icon name="OMore" />{' '}
      </Button>
      <ActionSheetWrapper anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {menuItems.map(item => (
          <ActionSheetItem
            key={item.value}
            onClick={() => {
              handleSelectTriggerType(item)
            }}
          >
            {item.label}
          </ActionSheetItem>
        ))}
      </ActionSheetWrapper>
    </>
  )
}
