import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Card, ContentGroup, DataGrid, EmptyState, Spinner } from '@ketch-com/deck'
import { useAuditLogConsent } from '../hooks'
import { auditLogsPermitsColumns } from './auditLogsDataGridUtils'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { getLastCollectedDate } from './utils'
import { useUserMe } from 'api/users/queries/useUserMe'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { ConsentContextPicker } from 'pages/insights/auditLogs/components/ConsentContextPicker'
import { Drawer } from 'components/ui-kit/drawer/Drawer'
import { ConsentContextDrawerContent } from 'pages/insights/auditLogs/components/ConsentContextDrawerContent'
import {
  ConsentContextData,
  useAuditLogConsentContextMockData,
} from 'pages/insights/auditLogs/hooks/useAuditLogConsentContextMockData'
import { GetConsentStatusResponseBodyPurposesItemDTO } from '@ketch-com/figurehead'
import { useQueryParams } from 'utils/hooks'

export const AuditLogsPermits: React.FC = () => {
  const [showConsentContextDrawer, setShowConsentContextDrawer] = useState(false)
  const [currentMockConsentContext, setCurrentMockConsentContext] = useState<ConsentContextData>()
  const { queries } = useQueryParams<{ permitDateTime?: string }>()

  const consent = useAuditLogConsent()
  const [consentData, setConsentData] = useState<GetConsentStatusResponseBodyPurposesItemDTO[]>()
  const consentContextMockData = useAuditLogConsentContextMockData(consent.data)

  const userMe = useUserMe()
  const showConsentContextDemo =
    isRelease(Release.ConsentContextDemo) ||
    userMe?.data?.organization.code === 'demo_e_commerce' ||
    userMe?.data?.organization.code === 'ketch_virgin'

  const handlePermitDateTimeChange = useCallback(
    (permitDateTime: string) => {
      const newMockConsentContext = consentContextMockData.find(mockData => mockData.permitTime === permitDateTime)
      if (newMockConsentContext) {
        setCurrentMockConsentContext(newMockConsentContext)
        setConsentData(permitDateTime === 'latest' ? consent.data : newMockConsentContext.consents)
      }
    },
    [consent.data, consentContextMockData],
  )

  // Set consent data initially
  useEffect(() => {
    if (consent.data) {
      setConsentData(consent.data)
    }
  }, [consent.data])

  // Set permit date time initially
  useEffect(() => {
    if (queries.permitDateTime && !currentMockConsentContext) {
      handlePermitDateTimeChange(queries.permitDateTime)
    }
  }, [queries.permitDateTime, handlePermitDateTimeChange, currentMockConsentContext])

  return (
    <>
      <Card
        sx={{
          maxWidth: '50%',
          minWidth: '50%',
          marginRight: theme => theme.spacing(2),
          padding: '32px',
        }}
        customContentCard={
          <Box
            sx={{
              height: 440,
              width: '100%',
            }}
          >
            {consent.isLoading ? (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spinner size="32px" thickness={2.5} />
              </Box>
            ) : (
              <>
                <ContentGroup
                  title="Permits"
                  subTitle="What’s the current state of permits for this identifier?"
                  variant="inner-page-header"
                  titleVariant="h3"
                  actionRightBlockComponent={
                    <Box display="flex" flexDirection="column" alignItems="end">
                      <Typography color="darkGrey.main" variant="smallLabel">
                        Last Updated
                      </Typography>
                      <Typography variant="smallBody">
                        {formatDateTimeFromUnix(getLastCollectedDate(consentData || []))}
                      </Typography>
                    </Box>
                  }
                />

                {!consentData?.length ? (
                  <Box height="100%">
                    <EmptyState
                      title="No permits yet"
                      subTitle="Permits will be displayed here when they have been created"
                      iconName="OLock"
                    />
                  </Box>
                ) : (
                  <>
                    {showConsentContextDemo && (
                      <ConsentContextPicker
                        permitDateTimes={consentContextMockData.map(mockData => mockData.permitTime)}
                        onViewExperienceClick={() => {
                          setShowConsentContextDrawer(true)
                        }}
                        onPermitDateTimeChange={handlePermitDateTimeChange}
                      />
                    )}
                    <DataGrid
                      sx={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        height: 'auto',
                      }}
                      autosizeOnMount
                      columns={auditLogsPermitsColumns}
                      disableChildrenSorting
                      disableColumnMenu
                      disableColumnPinning
                      disableColumnResize
                      disableColumnReorder
                      getRowId={row => row.code}
                      loading={!!consent.isLoading}
                      rows={consentData}
                      pagination
                      paginationMode="client"
                      pageSizeOptions={[5]}
                    />
                  </>
                )}
              </>
            )}
          </Box>
        }
      />
      <Drawer
        open={showConsentContextDrawer}
        onClose={() => setShowConsentContextDrawer(false)}
        PaperProps={{
          sx: {
            height: '90%',
            width: '100%',
            maxWidth: '1300px',
            margin: 'auto', // Centered
            padding: '32px',
            borderRadius: '37px 37px 0px 0px',
            overflow: 'hidden',
          },
        }}
      >
        <ConsentContextDrawerContent
          consentTime={currentMockConsentContext?.permitTime || ''}
          mockData={currentMockConsentContext}
        />
      </Drawer>
    </>
  )
}
