import { Box, Typography } from '@mui/material'
import { BulkEditModalType } from '../../../types'
import { Button } from '@ketch-com/deck'
import pluralize from 'pluralize'
import { BulkEditOptionMenu } from '../../BulkEditOptionMenu'

interface BulkEditModalTypeSelectorsProps {
  currentCanonicalResourceLevel: string
  rowSelectionModelLength: number
  setShowBulkEditModal: (modalType: BulkEditModalType) => void
}

export const BulkEditModalTypeSelectors: React.FC<BulkEditModalTypeSelectorsProps> = ({
  currentCanonicalResourceLevel,
  rowSelectionModelLength,
  setShowBulkEditModal,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" marginBottom={1} marginTop={3}>
      <Box display="flex" gap={0.5} alignItems="center">
        <Typography variant="label">{rowSelectionModelLength}</Typography>
        <Typography>{pluralize('Asset', rowSelectionModelLength)} selected</Typography>
      </Box>
      <Box display="flex" gap={0.5} alignItems="center">
        <Button
          color="tertiary"
          onClick={() => {
            setShowBulkEditModal(BulkEditModalType.dataSensitivity)
          }}
        >
          Data Sensitivity
        </Button>
        <Button
          color="tertiary"
          onClick={() => {
            setShowBulkEditModal(BulkEditModalType.dataCategories)
          }}
        >
          Data Categories
        </Button>
        <BulkEditOptionMenu
          currentCanonicalResourceLevel={currentCanonicalResourceLevel}
          triggerModal={setShowBulkEditModal}
        />
      </Box>
    </Box>
  )
}
