import { EmptyState } from '@ketch-com/deck'
import { ReactComponent as EmptyStateAssetTab } from 'assets/icons/emptyState/EmptyStateAssetTab.svg'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export const AssetsTabEmptyState: React.FC = () => {
  const navigate = useNavigate()

  return (
    <EmptyState
      title="Discover System's Data Assets"
      subTitle="Connect this system to discover data assets within its infrastructure that are related to your account so that you can classify them and get more context for your privacy program."
      customIcon={<EmptyStateAssetTab />}
      primaryButtonTitle="Connect Now"
      primaryButtonProps={{
        onClick: () => navigate(RoutesManager.systems.create.root.getURL()),
      }}
    />
  )
}
