import { PreviewMessage, PreviewMessageType } from '@ketch-sdk/ketch-types'

export enum ExperiencePreviewVariant {
  ConsentExperience = 'consentExperience',
  PreferenceExperience = 'preferenceExperience',
  Theme = 'theme',
  DeploymentPlan = 'deploymentPlan',
  // The following is used when a config is directly provided to the preview, instead of the component fetching it
  DirectConfig = 'directConfig',
}

export const SHOW_BANNER_PREVIEW_MESSAGE: PreviewMessage = {
  type: PreviewMessageType.ShowBanner,
}

export const SHOW_MODAL_PREVIEW_MESSAGE: PreviewMessage = {
  type: PreviewMessageType.ShowModal,
}

export const SHOW_PREFERENCE_PREVIEW_MESSAGE: PreviewMessage = {
  type: PreviewMessageType.ShowPreference,
}
