import React, { useContext, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { MaybeNull } from 'interfaces'
import { AssetSummaryDTO, GetDSRConfigCountResponseBodyDTO } from '@ketch-com/figurehead'
import { Spinner, Icon, Button, IconDictionary, theme, Chip } from '@ketch-com/deck'
import { styled } from '@mui/material/styles'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { Waypoint } from 'react-waypoint'
import pluralize from 'pluralize'
import {
  CustomSqlContext,
  withCustomSqlContext,
} from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSqlContext'
import { CustomSqlUpsertModal } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSqlUpsertModal'
import { CustomSqlDeleteModal } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSqlDeleteModal'
import { CustomSqlModalType, SCRIPT_TYPE_VALUES } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSQLTypes'

const StyledEmptyState = styled(EmptyState)(({ theme: { spacing } }) => ({
  padding: spacing(13.25, 0),
}))

const CustomSqlItem = styled(Box)(({ theme: { palette, spacing } }) => ({
  padding: spacing(1.5, 2),
  border: `1px solid ${palette.fadedDarkGrey.main}`,
  borderRadius: '11px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: spacing(3),
}))

type CustomSqlWithContextProps = {
  assetSummary: MaybeNull<AssetSummaryDTO>
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
}

interface identityNamingSpace {
  name: string
  code: string
}

const CustomSqlWithContext: React.FC<CustomSqlWithContextProps> = ({ assetSummary }) => {
  const {
    isReady = false,
    customSqls = [],
    customSqlModalData,
    setCustomSqlModalData,
    isFetchingNextCustomSql,
    hasNextCustomSql,
    fetchNextCustomSql,
    identitySpacesList,
  } = useContext(CustomSqlContext)
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteAsset = getIsPermitted([PERMISSIONS.ASSET_WRITE])

  const identityNamingSpaces = useMemo(
    () =>
      customSqls
        .filter(customSql => customSql !== undefined)
        .reduce(
          (acc, customSql) => {
            if (customSql?.id) {
              if (customSql.identitySpaceCodes) {
                acc[customSql?.id] = customSql.identitySpaceCodes.map(code => ({
                  name: identitySpacesList.find(listItem => listItem.code === code)?.name || code,
                  code,
                }))
              } else if (customSql.identitySpaceCode) {
                acc[customSql?.id] = [
                  {
                    name:
                      identitySpacesList.find(listItem => listItem.code === customSql.identitySpaceCode)?.name ||
                      customSql.identitySpaceCode,
                    code: customSql.identitySpaceCode,
                  },
                ]
              }
            }
            return acc
          },
          {} as Record<string, identityNamingSpace[]>,
        ),
    [customSqls, identitySpacesList],
  )

  if (!isReady)
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Spinner size={35} thickness={3} />
      </Box>
    )

  let component = (
    <StyledEmptyState
      icon={<Icon name={IconDictionary.OFocusCopy} />}
      variant="list"
      bgVariant="grey"
      title="Add Custom Script"
      description="Add your custom SQL script that will execute in parallel with tables configurations. Click on a button below to begin."
      actions={[
        {
          title: 'Add Custom SQL',
          variant: 'secondary',
          size: 'big',
          disabled: !isPermittedToWriteAsset,
          onClick: () => {
            setCustomSqlModalData({
              mode: CustomSqlModalType.CREATE,
            })
          },
        },
      ]}
    />
  )

  const numCustomSqls = customSqls?.length
  if (numCustomSqls)
    component = (
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="label" color="darkDuskFaded.main">{`${numCustomSqls} custom ${pluralize(
            'script',
            numCustomSqls,
          )} added.`}</Typography>
          <Button
            color="secondary"
            startIcon={<Icon name={IconDictionary.OPlus} />}
            disabled={!isPermittedToWriteAsset}
            onClick={() => {
              setCustomSqlModalData({
                mode: CustomSqlModalType.CREATE,
              })
            }}
          >
            <Typography variant="label" color="sphere.main">
              Add Custom SQL
            </Typography>
          </Button>
        </Box>
        <Box>
          {customSqls.map(sql => {
            const identitySpaceNameText = sql.id
              ? identityNamingSpaces[sql.id].map(space => `${space.name} (code: ${space.code}))`).join(', ')
              : ''

            return (
              <CustomSqlItem key={sql.id}>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography variant="label">{sql?.name}</Typography>
                  <Typography
                    variant="body"
                    color="darkDuskFaded.main"
                  >{`Identifiers: ${identitySpaceNameText}`}</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  {sql.scriptType ? (
                    <Chip
                      size="small"
                      label={SCRIPT_TYPE_VALUES[sql.scriptType] ? SCRIPT_TYPE_VALUES[sql.scriptType].label : ''}
                    />
                  ) : null}
                  <Button
                    variant="icon"
                    color="white"
                    disabled={!isPermittedToWriteAsset}
                    onClick={() => {
                      setCustomSqlModalData({
                        mode: CustomSqlModalType.DELETE,
                        customSqlId: sql?.id || '',
                      })
                    }}
                  >
                    <Icon name={IconDictionary.FBin} />
                  </Button>

                  <Button
                    variant="icon"
                    color="white"
                    disabled={!isPermittedToWriteAsset}
                    onClick={() => {
                      setCustomSqlModalData({
                        mode: CustomSqlModalType.UPDATE,
                        customSqlId: sql?.id || '',
                      })
                    }}
                  >
                    <Icon name={IconDictionary.FEdit} iconColor={theme.palette.sphere.main} />
                  </Button>
                </Box>
              </CustomSqlItem>
            )
          })}

          {customSqls?.length > 19 ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Waypoint
                onEnter={() => {
                  if (!isFetchingNextCustomSql && hasNextCustomSql) fetchNextCustomSql()
                }}
              />
              {isFetchingNextCustomSql ? <Spinner size={35} thickness={3} /> : null}
            </Box>
          ) : null}
        </Box>
      </>
    )
  return (
    <Box>
      {component}
      {customSqlModalData?.mode === CustomSqlModalType.CREATE ||
      customSqlModalData?.mode === CustomSqlModalType.UPDATE ? (
        <CustomSqlUpsertModal assetSummary={assetSummary} />
      ) : null}
      {customSqlModalData?.mode === CustomSqlModalType.DELETE ? <CustomSqlDeleteModal /> : null}
    </Box>
  )
}

export const CustomSql = withCustomSqlContext(CustomSqlWithContext)
