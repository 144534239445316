import React, { useState, useMemo, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { FaqModal } from './components/FaqModal'
import { ListLayout } from '@ketch-com/deck'

export const Classification: React.FC = () => {
  const [isOpenFaq, setIsOpenFaq] = useState(false)

  const { search, pathname } = useLocation()
  const navigate = useNavigate()

  const tabs = useMemo(
    () => [
      {
        label: 'Data Categories',
        value: 'data_categories',
        link: {
          pathname: RoutesManager.systems.classification.dataCategories.getURL(),
          search,
        },
      },
      {
        label: 'Data Types',
        value: 'data_types',
        link: {
          pathname: RoutesManager.systems.classification.dataTypes.getURL(),
          search,
        },
      },
    ],
    [search],
  )

  const currentTab = useMemo(() => {
    const currTab = tabs.find(tab => pathname === tab.link.pathname) || tabs[0]

    return currTab.value
  }, [pathname, tabs])

  const onHandleTabChange = (event: React.SyntheticEvent, value: string) => {
    const activeTab = tabs.find(tabElem => tabElem.value === value)

    if (activeTab) {
      navigate(activeTab.link.pathname)
    }
  }

  /* Redirect if base route is navigated to  */
  useEffect(() => {
    if (pathname === RoutesManager.systems.classification.root.getURL()) {
      navigate(RoutesManager.systems.classification.dataTypes.getURL())
    }
  }, [pathname, navigate])

  return (
    <ListLayout
      headerTitle="Classification"
      headerRightActionComponentProps={{
        headerRightActionInfoButtonProps: {
          onClick: () => {
            setIsOpenFaq(prevValue => !prevValue)
          },
        },
      }}
      tabsComponentProps={{ value: currentTab, onChange: onHandleTabChange, tabItems: tabs }}
    >
      <Outlet />

      <FaqModal isOpen={isOpenFaq} onClose={() => setIsOpenFaq(false)} />
    </ListLayout>
  )
}
