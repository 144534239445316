import { PopUp, theme } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { ConfirmationStatusV3DTO } from '@ketch-com/figurehead'
import { useUpdateInstalledDataSystemAssets } from 'api/dataSystems/mutations/useUpdateInstalledDataSystemAssets'
import { showToast } from 'components/modals/AlertComponent'
import { useDataSystemsContext } from 'pages/dataSystems/DataSystem/context/DataSystemContext'
import { Logger } from 'utils/Logger'
import { RadioGroup } from 'components/RadioGroup/RadioGroup'
import { BulkEditMidalFooteerActions } from './components/BulkEditModalFooterActions'
import { BulkEditModalWarningCopy } from './components/BulkEditModalWarningCopy'
import pluralize from 'pluralize'
import { BulkEditModalBaseProps } from '../../types'

export const BulkEditSensitivityModal: React.FC<BulkEditModalBaseProps> = ({
  refetchInstalledDatasystemAssets,
  assetIds,
  resourceType,
  onClose,
}) => {
  const { appDescriptor, allDataSensitivities, dataSensitivityOptions } = useDataSystemsContext()
  const [selectedValue, setSelectedValue] = useState<string | undefined>()
  const [confirmEdit, setConfirmEdit] = useState<boolean>(false)

  const { mutate: updateInstalledDataSystemAssets } = useUpdateInstalledDataSystemAssets({
    onSuccess: () => {
      refetchInstalledDatasystemAssets()
      showToast({
        content: 'Successfully updated sensitivity',
        type: 'success',
      })
      onClose()
    },
    onError: error => {
      Logger.error('Failed to update sensitivity', error)
      showToast({
        content: 'Failed to update sensitivity',
        type: 'error',
      })
    },
  })

  const handleSave = useCallback(() => {
    if (selectedValue) {
      const newSensitivity = allDataSensitivities?.find(sensitivity => sensitivity.id === selectedValue)
      updateInstalledDataSystemAssets({
        params: {
          installedDataSystemId: appDescriptor?.id!,
          body: {
            assetIDs: assetIds as string[],
            dataSensitivity: {
              ...newSensitivity,
              confirmationStatus: ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
            },
            resourceType,
          },
        },
      })
    }
  }, [allDataSensitivities, appDescriptor?.id, assetIds, resourceType, selectedValue, updateInstalledDataSystemAssets])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const customPopupTitle = confirmEdit ? (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="h3">Edit Data Sensitivity</Typography>
      <Typography variant="h3" color={theme.palette.Text.Secondary}>
        in {assetIds.length}
      </Typography>
      <Typography variant="h3">{pluralize('System', assetIds.length)}</Typography>
    </Box>
  ) : (
    <Typography variant="h3">Edit Data Sensitivity</Typography>
  )

  return (
    <PopUp
      variant="modal"
      popUpWidth="560px"
      onClose={onClose}
      sx={{
        '& .MuiDialogContent-root': {
          marginLeft: '15px',
          paddingTop: '0px',
        },
      }}
      popUpCustomTitle={customPopupTitle}
      popUpActionChildren={
        <BulkEditMidalFooteerActions
          handleClose={handleClose}
          confirmEdit={confirmEdit}
          setConfirmEdit={setConfirmEdit}
          selectedValue={selectedValue}
          handleSave={handleSave}
        />
      }
    >
      {' '}
      {confirmEdit ? (
        <Box paddingY={1} paddingBottom={1}>
          <Typography paddingLeft="9px" component={'div'} marginBottom={1.5} variant="label">
            Data Sensitivity
          </Typography>
          <RadioGroup
            name="Data Sensitivity"
            onChange={e => {
              setSelectedValue(e.target.value)
            }}
            options={dataSensitivityOptions}
            value={selectedValue}
            sx={{
              gap: 1,
              '& .MuiTypography-root': {
                fontWeight: 400,
              },
            }}
          />
        </Box>
      ) : (
        <BulkEditModalWarningCopy editablePropertyName="data sensitivity" />
      )}
    </PopUp>
  )
}
