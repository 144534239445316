import React, { useEffect, useMemo, useState } from 'react'
import '@sendbird/uikit-react/dist/index.css'
import GroupChannel, { GroupChannelProps } from '@sendbird/uikit-react/GroupChannel'
import { GroupChannelModule, GroupChannel as GroupChannelType, SendbirdGroupChat } from '@sendbird/chat/groupChannel'
import { Box, styled } from '@mui/material'
import SendbirdChat, { User } from '@sendbird/chat'
import { useAuth0 } from '@auth0/auth0-react'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'
import { getGroupChannel } from './utils/getGroupChannel'
import { SENDBIRD_CHANNEL_OWNER_ID } from './utils/enums'
import { useAuth } from 'utils/hooks'
import { KetchSendbirdProvider } from '../KetchSendbirdProvider/KetchSendbirdProvider'
import { generateSHA256 } from 'utils/helpers/getSha256Hash'
import { retry, retryAsync } from 'components/Sendbird/SendbirdGroupChannel/utils/retry'

/**
 * Wrapper of Sendbirds GroupChannel component which displays a group channel based
 * on the channelUrl passed in.
 *
 * @param channelUrl - Sendbird channel URL
 * @param groupChannelProps - Properties to pass to Sendbirds GroupChannel component
 */

export type SendbirdGroupChannelProps = {
  channelUrl: string
  groupChannelProps?: Omit<GroupChannelProps, 'channelUrl'>
}

const GroupChannelWrapper = styled(Box)({
  display: 'flex',
  width: '100%',

  // Set font to Inter
  '& *': {
    fontFamily: 'Inter, sans-serif',
  },

  // Adjust corner radius
  '.sendbird-conversation': {
    borderRadius: '11px',
  },

  // This hides the conversation participants button which does not work
  '.sendbird-openchannel-conversation-header__right': {
    display: 'none',
  },

  // Hides the horizontal scroll bar
  '.sendbird-openchannel-conversation-scroll__container__item-container': {
    overflowX: 'hidden !important',
  },

  // Gives users a bit more room for large messages
  'div.sendbird-message-input--textarea': {
    maxHeight: '150px',
  },

  // Hide profile picture
  '.sendbird-message-content__left': {
    display: 'none !important',
  },

  // Hide message options button
  '.sendbird-message-content__right': {
    display: 'none !important',
  },
})

export const SendbirdGroupChannelWithoutContext: React.FC<SendbirdGroupChannelProps> = ({
  channelUrl,
  groupChannelProps,
}) => {
  const [groupChannel, setGroupChannel] = useState<GroupChannelType | null>(null)

  // Get Sendbird application ID environment variable
  const sendbirdAppId = (window as any)?.figureheadConfig?.REACT_APP_SENDBIRD_APPLICATION_ID

  // Get the ID for the currently logged in user
  const { userData } = useAuth()
  const { user } = useAuth0()
  const userId = useMemo(() => {
    return user?.sub?.includes('support@ketchsupport.com') ? SENDBIRD_CHANNEL_OWNER_ID : user?.sub || ''
  }, [user])

  useEffect(() => {
    const initGroupChannel = async () => {
      // Initialize SendbirdChat SDK
      const sb = retry(
        () =>
          SendbirdChat.init({
            appId: sendbirdAppId,
            modules: [new GroupChannelModule()],
          }) as SendbirdGroupChat,
        5,
      )
      logObjectDetails(sb, 'sb')

      // Get sendbird ID
      const sendbirdId = await generateSHA256(userId)

      // Connect user
      let sbUser: User = await retryAsync(() => sb.connect(sendbirdId), 5)

      if (
        sbUser.userId !== SENDBIRD_CHANNEL_OWNER_ID &&
        (!sbUser.nickname || !(sbUser.metaData as any).orgCode || !(sbUser.metaData as any).isDataSubject)
      ) {
        // Update user nickname if not present in Senbird and we have a name value
        sbUser = await retryAsync(async () => sb.updateCurrentUserInfo({ nickname: user?.name || '' }), 5)
        await retryAsync(async () => sb.currentUser?.deleteAllMetaData(), 5)
        await retryAsync(
          async () =>
            sb.currentUser?.createMetaData({
              orgCode: userData.organizationCode,
              isDataSubject: 'false',
              auth0Id: user?.sub || '',
            }),
          5,
        )
      }
      logObjectDetails(sbUser, 'sbUser')

      // Get channel
      const channel = await retryAsync(async () => getGroupChannel(sb, channelUrl), 5)
      logObjectDetails(channel || {}, 'channel')

      // Join channel if not already a member
      if (channel && channel.isPublic && !channel.members.some(member => member.userId === sendbirdId)) {
        await retryAsync(async () => channel.join(), 5)
      }

      // Update channel state
      setGroupChannel(channel)
    }

    if (userData && user) {
      initGroupChannel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, user])

  if (!groupChannel) return null

  return retry(
    () => (
      <GroupChannelWrapper>
        <GroupChannel
          channelUrl={channelUrl}
          {...groupChannelProps}
          isReactionEnabled={false}
          isMultipleFilesMessageEnabled
        />
      </GroupChannelWrapper>
    ),
    5,
  )
}

export const SendbirdGroupChannel: React.FC<SendbirdGroupChannelProps> = props => {
  return (
    <KetchSendbirdProvider>
      <SendbirdGroupChannelWithoutContext {...props} />
    </KetchSendbirdProvider>
  )
}
