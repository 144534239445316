import { useParams } from 'react-router-dom'
import { LookUpTab } from 'pages/assetManager/scenario/details/constants'
import { useAssetV2 } from 'api/assets/queries/useAssetV2'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { GetDSRConfigCountResponseBodyDTO } from '@ketch-com/figurehead'
import { ScenarioDetailsParams } from 'utils/routing/routesV2/systems'
import { CustomSql } from 'pages/dataSystems/AssetMigration/ScenarioDetails/CustomSql'
import { DirectIdentityLookupContainer } from 'pages/dataSystems/AssetMigration/ScenarioDetails/DirectIdentityLookupContainer'
import { NoDirectIdentityLookupContainer } from 'pages/dataSystems/AssetMigration/ScenarioDetails/NoDirectIdentityLookupContainer'

type Props = {
  dsrConfigCount: GetDSRConfigCountResponseBodyDTO
}

export const LookUpTypes: React.FC<Props> = ({ dsrConfigCount }) => {
  // Only database assets will have scenario details/lookups
  const { assetId: databaseId, tab, resourceTypeCode = '' } = useParams<ScenarioDetailsParams>()
  const { data: assetSummary, isLoading: isAssetSummaryLoading } = useAssetV2({
    params: {
      assetCode: databaseId!,
      resourceTypeCode,
    },
  })
  const isReady = !isAssetSummaryLoading

  if (isReady) {
    if (tab === LookUpTab.DIRECT)
      return <DirectIdentityLookupContainer assetSummary={assetSummary} dsrConfigCount={dsrConfigCount} />
    if (tab === LookUpTab.NO_DIRECT)
      return <NoDirectIdentityLookupContainer assetSummary={assetSummary} dsrConfigCount={dsrConfigCount} />
    if (tab === LookUpTab.CUSTOM_SQL) return <CustomSql assetSummary={assetSummary} dsrConfigCount={dsrConfigCount} />
  }

  return <Spinner />
}
