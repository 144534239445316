import { ActionSheetItem, Button, DropListButton, ListItemText } from '@ketch-com/deck'
import { Box, styled } from '@mui/material'
import React, { useMemo } from 'react'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { useQueryParams } from 'utils/hooks/useQueryParams'

type ConsentContextPickerProps = {
  permitDateTimes: string[]
  onViewExperienceClick: () => void
  onPermitDateTimeChange: (permitDateTime: string) => void
}

const ConsentContextBox = styled(Box)({
  display: 'flex',
  margin: '16px 0px',
  gap: '16px',
})

/**
 * Component for selecting a permit date time for the consent context drawer. Used only in the virgin Australia demo.
 * @param permitDateTimes - Array of permit date times
 * @param onViewExperienceClick - Function to handle view experience click
 * @param onPermitDateTimeChange - Function to handle permit date time change
 * @returns Consent context picker component
 */
export const ConsentContextPicker: React.FC<ConsentContextPickerProps> = ({
  permitDateTimes,
  onViewExperienceClick,
  onPermitDateTimeChange,
}) => {
  const { queries, setQueryParam } = useQueryParams<{ permitDateTime?: string }>()

  const dateTimeOptions = useMemo(
    () =>
      permitDateTimes.map(permitTime => ({
        code: permitTime,
        content:
          permitTime === 'latest'
            ? 'Latest'
            : formatDateTimeFromUnix(new Date(permitTime).getTime() / 1000, 'MMM DD yyyy, h:mm A'),
      })),
    [permitDateTimes],
  )

  const dropListButtonCurrentValue = {
    code: queries.permitDateTime || '',
    content:
      queries.permitDateTime === 'latest'
        ? 'Latest'
        : formatDateTimeFromUnix(new Date(queries.permitDateTime || '').getTime() / 1000, 'MMM DD yyyy, h:mm A') || '',
  }

  return (
    <ConsentContextBox>
      <DropListButton
        size="small"
        width="205px"
        options={dateTimeOptions}
        placeholder="Select Historical Permit"
        getOptionLabel={option => option.content}
        isOptionEqualToValue={(option, value) => {
          if (option.code === 'latest' || value.code === 'latest') return option.code === value.code
          return (
            Math.floor(new Date(option.code).getTime() / 1000) === Math.floor(new Date(value.code).getTime() / 1000)
          )
        }}
        onChange={(_, newValue) => {
          if (newValue) {
            setQueryParam('permitDateTime', newValue.code)
            onPermitDateTimeChange(newValue.code)
          }
        }}
        disableClearable
        value={dropListButtonCurrentValue}
        renderOption={(props, option) => {
          const isSelected =
            option.code === 'latest' || dropListButtonCurrentValue?.code === 'latest'
              ? option.code === dropListButtonCurrentValue?.code
              : Math.floor(new Date(option.code).getTime() / 1000) ===
                Math.floor(new Date(dropListButtonCurrentValue?.code).getTime() / 1000)

          return (
            <ActionSheetItem key={option.code} selected={isSelected} {...props}>
              <ListItemText selected={isSelected}>{option.content}</ListItemText>
            </ActionSheetItem>
          )
        }}
      />
      <Button onClick={onViewExperienceClick}>View Experience</Button>
    </ConsentContextBox>
  )
}
