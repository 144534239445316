import { Avatar, AvatarSize, Button, CustomIconName, PopUp, theme } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useDataSystemsContext } from '../../context/DataSystemContext'
import { RadioGroup } from 'components/RadioGroup/RadioGroup'
import { ConfirmationStatusV3DTO, DataSensitivityDTO } from '@ketch-com/figurehead'
import { useUpdateInstalledDataSystemAssets } from 'api/dataSystems/mutations/useUpdateInstalledDataSystemAssets'
import { Logger } from 'utils/Logger'
import { showToast } from 'components/modals/AlertComponent'
import { canonicalResourceTypeMap } from '../constants'

export interface AssetsTabEditSensitivityModalProps {
  canonicalResourceType: string
  resourceName: string
  sensitivityClassification: DataSensitivityDTO[]
  assetIds: string[]
  resourceType: string
  refetchInstalledDatasystemAssets: (option?: any) => Promise<any>
  onClose: () => void
}

export const AssetsTabEditSensitivityModal: React.FC<AssetsTabEditSensitivityModalProps> = ({
  canonicalResourceType,
  resourceName,
  sensitivityClassification,
  refetchInstalledDatasystemAssets,
  assetIds,
  resourceType,
  onClose,
}) => {
  const { appDescriptor, allDataSensitivities, dataSensitivityOptions } = useDataSystemsContext()
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    sensitivityClassification?.[0] &&
      sensitivityClassification[0].id &&
      sensitivityClassification[0].confirmationStatus !== ConfirmationStatusV3DTO.DismissedConfirmationStatusV3 &&
      allDataSensitivities
      ? dataSensitivityOptions.find(sensitivity => sensitivity.value === sensitivityClassification?.[0].id)?.value
      : undefined,
  )

  const { mutate: updateInstalledDataSystemAssets } = useUpdateInstalledDataSystemAssets({
    onSuccess: () => {
      refetchInstalledDatasystemAssets()
      showToast({
        content: 'Successfully updated sensitivity',
        type: 'success',
      })
      onClose()
    },
    onError: error => {
      Logger.error('Failed to update sensitivity', error)
      showToast({
        content: 'Failed to update sensitivity',
        type: 'error',
      })
    },
  })

  const handleSave = useCallback(() => {
    if (selectedValue) {
      if (sensitivityClassification?.[0] && sensitivityClassification[0].id === selectedValue) {
        updateInstalledDataSystemAssets({
          params: {
            installedDataSystemId: appDescriptor?.id!,
            body: {
              assetIDs: assetIds,
              dataSensitivity: {
                ...sensitivityClassification[0],
                confirmationStatus: ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
              },
              resourceType,
            },
          },
        })
      } else {
        const newSensitivity = allDataSensitivities?.find(sensitivity => sensitivity.id === selectedValue)
        updateInstalledDataSystemAssets({
          params: {
            installedDataSystemId: appDescriptor?.id!,
            body: {
              assetIDs: assetIds,
              dataSensitivity: {
                ...newSensitivity,
                confirmationStatus: ConfirmationStatusV3DTO.ConfirmedConfirmationStatusV3,
              },
              resourceType,
            },
          },
        })
      }
    }
  }, [
    allDataSensitivities,
    appDescriptor?.id,
    assetIds,
    resourceType,
    selectedValue,
    sensitivityClassification,
    updateInstalledDataSystemAssets,
  ])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <PopUp
      variant="modal"
      popUpWidth="560px"
      onClose={onClose}
      sx={{
        '& .MuiDialogContent-root': {
          marginLeft: '15px',
        },
      }}
      popUpCustomTitle={
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h3">Edit Data Sensitivity</Typography>
          <Typography variant="h3" color={theme.palette.Text.Secondary}>
            in
          </Typography>
          <Avatar
            isLogo
            icon={canonicalResourceTypeMap[canonicalResourceType] as CustomIconName}
            variant="rounded"
            size={AvatarSize['medium']}
            backgroundColor={theme.palette.tertiary.main}
          />
          <Typography variant="h3">{resourceName}</Typography>
        </Box>
      }
      popUpActionChildren={
        <>
          <Button size="large" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" size="large" onClick={handleSave} disabled={!selectedValue}>
            Save
          </Button>
        </>
      }
    >
      <Box paddingY={1} paddingBottom={1}>
        <Typography paddingLeft="9px" variant="label">
          Data Sensitivity
        </Typography>
        <RadioGroup
          name="Data Sensitivity"
          onChange={e => {
            setSelectedValue(e.target.value)
          }}
          options={dataSensitivityOptions}
          value={selectedValue}
          sx={{
            '& .MuiTypography-root': {
              fontWeight: 400,
            },
          }}
        />
      </Box>
    </PopUp>
  )
}
