import { useMemo } from 'react'
import { Button, Icon, MegaFilter, MegaFilterOptionType } from '@ketch-com/deck'

import { useFiltersV3 } from 'utils/hooks'
import {
  StatusFilter,
  SubjectTypeFilter,
  JurisdictionFilter,
  RightFilter,
  AssigneeFilter,
  StepStatusFilter,
} from './components'
import { RightsQueueMegaFilterButtonPanelIds, statusFilters, stepStatusFilters } from './constants'
import { useRights } from 'api/rights/queries/useRights'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useDataSubjectTypes } from 'api/dataSubjectTypes/queries/useDataSubjectTypes'
import { RightsQueueListTableUtils } from '../RightsQueueListTable/hooks/useRightsQueueListTableUtils'
import { useAppDispatch } from 'store/hooks'
import { RIGHTS_QUEUE_PAGINATION_REDUX_KEY, setPage } from 'store/paginationPreservationSlice'
import { useUsers } from 'api/users/queries/useUsers'
import { useTeams } from 'api/teams/queries/useTeams'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'

type Props = {
  rightsQueueListTableUtils: RightsQueueListTableUtils
  handlePaginationReset: () => void
}

export const RightsQueueMegaFilterButton = ({ rightsQueueListTableUtils, handlePaginationReset }: Props) => {
  const { filterCount, clearAllSearchParams, removeQueryParamFromList, getPanelParamValuesArrayByPanelId } =
    useFiltersV3()

  const {
    isFetching: isFetchingRightInvocations,
    paginationHelpers: { isNextPending, isPrevPending, totalResults },
  } = rightsQueueListTableUtils

  const { data: rights, isLoading: isRightsLoading } = useRights({
    enabled: true,
    params: {
      onlyOrgJurisdictionRights: true,
    },
  })

  const { data: jurisdictions, isLoading: isJurisdictionsLoading } = usePolicyScopes({
    enabled: true,
  })

  const {
    data: subjectTypes /* , TODO:BAC - isLoading, not sure how hydration is going to shake out, holding until BE is ready */,
  } = useDataSubjectTypes({
    enabled: true,
    params: {
      includeDeleted: false, // TODO:BAC - waiting on product to say if we should include deleted or not
    },
  })

  const { data: userList, isLoading: isUsersLoading } = useUsers({ params: { active: true } })
  const { data: teams, isLoading: isTeamsLoading } = useTeams({})

  const megaFilterSections = useMemo(
    () => [
      { label: 'Status', value: RightsQueueMegaFilterButtonPanelIds.status },
      { label: 'Right', value: RightsQueueMegaFilterButtonPanelIds.rights },
      { label: 'Subject Type', value: RightsQueueMegaFilterButtonPanelIds.subjectTypes },
      { label: 'Jurisdiction', value: RightsQueueMegaFilterButtonPanelIds.jurisdictions },
      { label: 'Assignee', value: RightsQueueMegaFilterButtonPanelIds.assignees },
      { label: 'Workflow Activity Status', value: RightsQueueMegaFilterButtonPanelIds.stepStatus },
    ],
    [],
  )

  const megaFilterSelectedValues = useMemo(() => {
    return megaFilterSections.reduce((accumulator, section) => {
      const panelParamValuesArray = getPanelParamValuesArrayByPanelId(section.value)
      let result: MegaFilterOptionType[] = []
      if (section.value === RightsQueueMegaFilterButtonPanelIds.status) {
        result = panelParamValuesArray.map(paramValue => {
          const res = statusFilters.find(({ value }) => value === paramValue)
          if (res) {
            return { ...res, category: section.value }
          }
          return {} as MegaFilterOptionType
        })
      } else if (section.value === RightsQueueMegaFilterButtonPanelIds.rights) {
        result = panelParamValuesArray.map(paramValue => {
          const res = rights.find(right => right.code === paramValue)
          if (res) {
            return { value: res.code, label: res.name, category: section.value }
          }
          return {} as MegaFilterOptionType
        })
      } else if (section.value === RightsQueueMegaFilterButtonPanelIds.jurisdictions) {
        result = panelParamValuesArray.map(paramValue => {
          const res = jurisdictions.find(jurisdiction => jurisdiction.code === paramValue)
          if (res) {
            return { value: res.code, label: res.name, category: section.value }
          }
          return {} as MegaFilterOptionType
        })
      } else if (section.value === RightsQueueMegaFilterButtonPanelIds.subjectTypes) {
        result = panelParamValuesArray.map(paramValue => {
          const res = subjectTypes.find(subjectType => subjectType.code === paramValue)
          if (res) {
            return { value: res.code || '', label: res.name, category: section.value, secondaryLabel: res.code }
          }
          return {} as MegaFilterOptionType
        })
      } else if (section.value === RightsQueueMegaFilterButtonPanelIds.assignees) {
        result = panelParamValuesArray.map(paramValue => {
          const userRes = userList.find(user => user.ID === paramValue)
          if (userRes) {
            return { value: userRes.ID, label: getFullNameUserShortDTO(userRes), category: section.value }
          }
          const teamList = teams?.teams || []
          const teamRes = teamList.find(team => team.id === paramValue)
          if (teamRes) {
            return { value: teamRes.id || '', label: teamRes.name || '', category: section.value }
          }
          return {} as MegaFilterOptionType
        })
      } else if (section.value === RightsQueueMegaFilterButtonPanelIds.stepStatus) {
        result = panelParamValuesArray.map(paramValue => {
          const res = stepStatusFilters.find(({ value }) => value === paramValue)
          if (res) {
            return { ...res, category: section.value }
          }
          return {} as MegaFilterOptionType
        })
      }
      return { ...accumulator, [section.value]: result }
    }, {})
  }, [rights, jurisdictions, subjectTypes, userList, teams, megaFilterSections, getPanelParamValuesArrayByPanelId])

  const filterButtonText = `Filter ${filterCount ? `(${filterCount})` : ''}`

  const selectedStatuses = getPanelParamValuesArrayByPanelId(RightsQueueMegaFilterButtonPanelIds.status)
  const hideStepStatusFilter =
    selectedStatuses.length && (selectedStatuses.includes('fulfilled') || selectedStatuses.includes('rejected'))

  return (
    <MegaFilter
      sx={{
        '& .MuiButtonBase-root': {
          alignSelf: 'flex-start',
        },
        '& .MegaFilter-selectedOptions': {
          '& .MuiButtonBase-root': {
            alignSelf: 'center',
          },
        },
      }}
      buttonTitle={filterButtonText}
      sections={megaFilterSections}
      value={megaFilterSelectedValues}
      totalFilteredItemsNumber={totalResults}
      buttonProps={{
        size: 'medium',
        color: 'secondary',
        endIcon: <Icon name="OFilter" sx={{ marginLeft: 0.5 }} />,
        pending: isFetchingRightInvocations && !isNextPending && !isPrevPending,
        sx: {
          '& .DeckBtnWrapChild': {
            whiteSpace: 'nowrap',
          },
        },
      }}
      onDeselectChipHandler={(option: MegaFilterOptionType) => {
        removeQueryParamFromList({ key: option.category, value: option.value })
        handlePaginationReset()
      }}
      renderActionItems={<RightsQueueActionButton clearAllSearchParams={clearAllSearchParams} />}
      selectedOptionsModalWidth={900}
    >
      <StatusFilter handlePaginationReset={handlePaginationReset} />
      <RightFilter rights={rights} isLoading={isRightsLoading} handlePaginationReset={handlePaginationReset} />
      <SubjectTypeFilter subjectTypes={subjectTypes} handlePaginationReset={handlePaginationReset} />
      <JurisdictionFilter
        jurisdictions={jurisdictions}
        isLoading={isJurisdictionsLoading}
        handlePaginationReset={handlePaginationReset}
      />
      <AssigneeFilter
        userList={userList}
        teamList={teams?.teams || []}
        isLoading={isUsersLoading || isTeamsLoading}
        handlePaginationReset={handlePaginationReset}
      />
      {!hideStepStatusFilter ? <StepStatusFilter handlePaginationReset={handlePaginationReset} /> : null}
    </MegaFilter>
  )
}

const RightsQueueActionButton = ({ clearAllSearchParams }: { clearAllSearchParams: () => void }) => {
  const dispatch = useAppDispatch()

  return (
    <Button
      color="tertiary"
      size="medium"
      onClick={() => {
        clearAllSearchParams()
        dispatch(
          setPage({
            key: RIGHTS_QUEUE_PAGINATION_REDUX_KEY,
            value: 0,
          }),
        )
      }}
    >
      Clear all
    </Button>
  )
}
