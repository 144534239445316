// layout utils
import { ProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { relativePath } from 'utils/routing/relativePath'
import { RoutesManager } from 'utils/routing/routesManager'

import { SystemSelector, AddConnectionForm } from 'pages/dataSystems/ConnectionNew'
import { DataSystemsInstall } from 'pages/dataSystems/DataSystemsInstall'
import { lazyLoadRoute } from 'utils/routing/lazyLoadRoute'
import { Classification } from './Classification/Classification'
import { DataMapOverview } from './overview/DatamapOverview'
import { SystemsSection } from './systemsSection/SystemsSection'
import { Overview } from './Home/components/Overview/Overview'
import { Connections } from './components/Connections/Connections'
import { DataSystemContainer } from './DataSystem/DataSystemContainer'
import { DataSystemsSettings } from './Settings/DataSystemsSettings'
import { DSRConfigUpsertContainer } from 'pages/dataSystems/AssetMigration/ScenarioDetails/DSRConfigUpsertContainer'
import { ScenarioDetailsContainer } from 'pages/dataSystems/AssetMigration/ScenarioDetailsContainer'
import { DSRIdentityLookupContainer } from 'pages/dataSystems/AssetMigration/ScenarioDetails/DSRIdentityLookupContainer'

export const DataMapRoutesChildren = [
  /**
   * Datamap > Systems
   */

  // Systems home
  {
    element: (
      <ProtectedRoute component={SystemsSection} permissions={RoutesManager.systems.systemsHome.root.permissions} />
    ),
    path: relativePath(RoutesManager.systems.systemsHome.root.pattern),
    children: [
      // Data systems list
      {
        element: <ProtectedRoute component={Overview} permissions={RoutesManager.systems.systems.root.permissions} />,
        path: relativePath(RoutesManager.systems.systems.root.pattern),
      },
      // Connections list
      {
        element: (
          <ProtectedRoute component={Connections} permissions={RoutesManager.systems.connections.root.permissions} />
        ),
        path: relativePath(RoutesManager.systems.connections.root.pattern),
      },
      // Scenarios & DSR
      {
        element: (
          <ProtectedRoute
            component={DSRConfigUpsertContainer}
            permissions={RoutesManager.systems.dsrInvocation.dsrConfig.permissions}
          />
        ),
        path: relativePath(RoutesManager.systems.dsrInvocation.dsrConfig.pattern),
      },
      {
        element: (
          <ProtectedRoute
            component={ScenarioDetailsContainer}
            permissions={RoutesManager.systems.dsrInvocation.scenarioDetails.permissions}
          />
        ),
        path: relativePath(RoutesManager.systems.dsrInvocation.scenarioDetails.pattern),
      },
      {
        element: (
          <ProtectedRoute
            component={DSRIdentityLookupContainer}
            permissions={RoutesManager.systems.dsrInvocation.configureIdentity.permissions}
          />
        ),
        path: relativePath(RoutesManager.systems.dsrInvocation.configureIdentity.pattern),
      },
    ],
  },
  // Settings
  {
    element: (
      <ProtectedRoute
        component={DataSystemsSettings}
        permissions={RoutesManager.systems.systems.settings.root.permissions}
      />
    ),
    path: relativePath(RoutesManager.systems.systems.settings.root.pattern),
  },

  // HOME
  {
    index: true,
    element: <ProtectedRoute component={DataMapOverview} permissions={[]} />,
  },
  {
    element: <ProtectedRoute component={DataMapOverview} permissions={[]} />,
    path: '',
  },
  {
    element: <ProtectedRoute component={DataMapOverview} permissions={RoutesManager.systems.root.permissions} />,
    path: relativePath(RoutesManager.systems.root.pattern),
  },

  // DATA SYSTEM
  {
    element: <ProtectedRoute component={DataSystemContainer} permissions={RoutesManager.systems.id.root.permissions} />,
    path: `${relativePath(RoutesManager.systems.id.root.pattern)}/*`,
  },

  // NEW CONNECTION
  {
    element: (
      <ProtectedRoute component={SystemSelector} permissions={RoutesManager.systems.connections.create.permissions} />
    ),
    path: relativePath(RoutesManager.systems.connections.create.pattern),
  },
  // Add connection page
  {
    element: (
      <ProtectedRoute
        component={AddConnectionForm}
        permissions={RoutesManager.systems.connections.upsert.root.permissions}
      />
    ),
    path: relativePath(RoutesManager.systems.connections.upsert.root.pattern),
  },
  // Edit Connection page, same component, has split logic inside
  {
    element: (
      <ProtectedRoute
        component={AddConnectionForm}
        permissions={RoutesManager.systems.connections.upsert.edit.permissions}
      />
    ),
    path: relativePath(RoutesManager.systems.connections.upsert.edit.pattern),
  },

  // Install Data Systems
  {
    element: (
      <ProtectedRoute component={DataSystemsInstall} permissions={RoutesManager.systems.create.root.permissions} />
    ),
    path: relativePath(RoutesManager.systems.create.root.pattern),
  },

  {
    element: (
      <ProtectedRoute component={Classification} permissions={RoutesManager.systems.classification.root.permissions} />
    ),
    path: `${RoutesManager.systems.classification.root.pattern}`,
    children: [
      {
        path: RoutesManager.systems.classification.root.pattern,
        lazy: async () => {
          const { DataCategories } = await import('./Classification/DataCategories')
          return lazyLoadRoute(DataCategories, RoutesManager.systems.classification.root.permissions)
        },
      },
      {
        path: RoutesManager.systems.classification.dataTypes.pattern,
        lazy: async () => {
          const { DataTypes } = await import('./Classification/DataTypes')
          return lazyLoadRoute(DataTypes, RoutesManager.systems.classification.dataTypes.permissions)
        },
      },
      {
        path: RoutesManager.systems.classification.dataCategories.pattern,
        lazy: async () => {
          const { DataCategories } = await import('./Classification/DataCategories')
          return lazyLoadRoute(DataCategories, RoutesManager.systems.classification.dataCategories.permissions)
        },
      },
    ],
  },
]
